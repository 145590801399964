<app-dashboard>
    <app-page-header-title mainTitle="Subscription Renew" subTitle="Subscriptions" backURL="/settings/subscription-list"></app-page-header-title>
    <div class="area_for_tab" style="margin-left: 70px;">
        <div class="col-md-12 row">
            <div class="col-md-5">
                <button (click)="setFilterData(1)" class="bt">
                    <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===1}">1</span>
                    <span class="bb" [ngClass]="{'active-tab': activeStatusId===1}">Existing Package</span>
                </button>
                <span class="middleLine col-md-7" style="margin-left: 185px;"></span>
            </div>
            <div class="col-md-5">
                <button (click)="setFilterData(2)" class="bt" [ngClass]="{'disabledClass':contentFirst===true}">
                    <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===2}">2</span>
                    <span class="bb" [ngClass]="{'active-tab': activeStatusId===2}">Select billing account</span>
                </button>
                <span class="middleLine2 col-md-6" style="margin-left:215px"></span>
            </div>
            <div class="col-md-2">
                <button (click)="setFilterData(3)" class="bt" [ngClass]="{'disabledClass':contentScnd===true}">
                    <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===3}">3</span>
                    <span class="bb" [ngClass]="{'active-tab': activeStatusId===3}">Review & pay</span>
                </button>
            </div>
        </div>
    </div>
    <div class="renewSubscriptionContainer" *ngIf="activeStatusId===1">
        <div class="leftSection">
            <p *ngIf="currentActiveSubscriptionDetails?.BusinessTypeId == 1">
                <span class="bt-headText mb-2">Service </span> (Startup,Non-profit,It,Creative,Legal,BPO)
            </p>
            <p *ngIf="currentActiveSubscriptionDetails?.BusinessTypeId == 2">
                <span class="bt-headText mb-2">Retail/Trading </span> (Tourism, E-Com,Small business)
            </p>
            <p *ngIf="currentActiveSubscriptionDetails?.BusinessTypeId == 3">
                <span class="bt-headText mb-2">Manufacture </span> (Construction, Real-estate,Apparel, Woods CraftsMan, Glass Fabricator, Paper Producer )
            </p>
            <div class="subsciption_details">
                <div class="subsciption_details_row_1">
                    <p><span class="bold_light_blue">Plan: </span> {{currentActiveSubscriptionDetails?.PackageTypeName}}</p>
                    <p><span class="bold_light_blue">Number Of Users: </span>{{currentActiveSubscriptionDetails?.AdditionalUser}}</p>
                </div>
                <div class="subsciption_details_row_2">
                    <p><span class="bold_light_blue">Active Time: </span> {{currentActiveSubscriptionDetails?.ActiveTime}}</p>
                    <p *ngIf="currentActiveSubscriptionDetails?.IsActive == true"><span class="bold_light_blue">Expires in days: </span> {{ currentActiveSubscriptionDetails?.RemainingDays }}</p>
                    <p *ngIf="currentActiveSubscriptionDetails?.IsActive == false"><span class="bold_light_blue">Expires in days: </span> -</p>
                </div>
            </div>
            <div class="buyNextPackage_details">
                <div class="afterUpdateCart_Title pt-2">
                    <p>Buy Next Package</p>
                </div>
                <div class="plan">
                    <div>
                        <p class="bold">Plan</p>
                    </div>
                    <div>
                        <form>
                            <div>
                                <input class="form-control" [checked]="currentActiveSubscriptionDetails?.PackageTypeId==1" id="MonthlyRadioButton" [(ngModel)]="currentSelectedPackagePrice" (change)="setNewPackageType($event, 'Monthly')" type="radio" name="packageTypes" [value]="currentBusinessTypeDetailsList[0]?.PackagePrice" class="mr-2" />
                                <label for="MonthlyRadioButton">Monthly</label>
                            </div>
                            <div>
                                <input class="form-control" [checked]="currentActiveSubscriptionDetails?.PackageTypeId==2" id="QuarterlyRadioButton" [(ngModel)]="currentSelectedPackagePrice" (change)="setNewPackageType($event, 'Quarterly')" type="radio" name="packageTypes" [value]="currentBusinessTypeDetailsList[1]?.PackagePrice" class="mr-2" />
                                <label for="QuarterlyRadioButton">Quarterly</label>
                            </div>
                            <div>
                                <input class="form-control" [checked]="currentActiveSubscriptionDetails?.PackageTypeId==3" id="YearlyRadioButton" [(ngModel)]="currentSelectedPackagePrice" (change)="setNewPackageType($event, 'Yearly')" type="radio" name="packageTypes" [value]="currentBusinessTypeDetailsList[2]?.PackagePrice" class="mr-2" />
                                <label for="YearlyRadioButton">Yearly</label>
                            </div>
                            <div>
                                <label for="DateInput" class="display_block"><span class="bold">Select Date</span></label>
                                <label class="form-control display_inline_block" id="btest2" readonly>{{ packageStartDate }}</label>
                                <span class="bold gray ml-2 mr-2">To</span>
                                <label class="form-control display_inline_block" id="btest2" readonly>{{ packageExpiryDate }}</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="afterUpdateCart">
                <div class="afterUpdateCart_Title pt-2">
                    <p>Add Additional User</p>
                </div>
                <div class="afterUpdateCart_Content">
                    <div class="afterUpdateCart_Content_1">
                        <div class="afterUpdateCart_Content_1_Inner_Content_1">
                            <div class="afterUpdateTotalUserCount">
                                <span class="skyblue">Total User : </span>
                                <span class="previousUsersNo">{{currentActiveSubscriptionDetails?.AdditionalUser}} +</span>
                                <input type="number" id="additionalUserInput" class="form-control ml-2 text-center" [(ngModel)]="AddedTotalUser" min="0" appDecimaNumericValidator (change)="onAdditionalUserInputChange(AddedTotalUser)">
                            </div>
                            <div class="text-center">
                                <input type="text" readonly id="inputBottom2" min="0" [(ngModel)]="AddedTotalUser + currentAdditionalUser" class="form-control ml-2" appDecimaNumericValidator readonly>
                            </div>
                        </div>
                        <div class="afterUpdateCart_Content_1_Inner_Content_2">
                            <div class="afterUpdateTotalPrice">
                                <span class="skyblue">Total Amount : </span>
                                <span class="previousPrice" [(ngModel)]="currentCompanyPrice">{{currentCompanyPrice}} +</span>
                                <input type="number" id="additionalUserInput" min="0" class="form-control ml-2 text-center" appDecimaNumericValidator readonly [value]="AddedTotalUser*currentSelectedPackagePrice">
                            </div>
                            <div class="text-center">
                                <span class="tk">৳</span>
                                <input type="text" readonly id="inputBottom2" [(ngModel)]="AddedTotalAmount+currentCompanyPrice" class="form-control ml-2" appDecimaNumericValidator readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightSection">
            <div class="planSummary_details">
                <div class="text-left">
                    <p class="bold large_font_size mb-4">Plan Summary</p>
                </div>
                <div class="costDetails">
                    <div class="costDetails_content medium_font_size mb-4 gray">
                        <div>{{currentActiveSubscriptionDetails?.CompanyName}}</div>
                        <div class="text-right">{{currentCompanyPrice}}</div>
                    </div>
                    <div class="costDetails_content medium_font_size mb-4 gray">
                        <div>Additional User Cost</div>
                        <div class="text-right">{{AddedTotalUser*currentSelectedPackagePrice}}</div>
                    </div>
                    <div class="costDetails_content medium_font_size mb-4 gray">
                        <div>Subtotal</div>
                        <div class="text-right">{{AddedTotalUser*currentSelectedPackagePrice + currentCompanyPrice}}</div>
                    </div>
                    <div class="costDetails_content medium_font_size mb-4">
                        <div class="bold" class>{{current_Selected_Package_Type}} Total</div>
                        <div class="bold text-right">BDT {{AddedTotalUser*currentSelectedPackagePrice + currentCompanyPrice}}</div>
                    </div>
                    <div class="mt-4 text-center full-width">
                        <button type="button" class="continueToSelectBillingButton eCount-def-btn bold small_font_size" (click)="selectedValue(filterType)">Continue To Select Billing Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeStatusId===2">
        <div class="card cad mt-4">
            <div class="card-body text-center ml-2"> You're buying a <span class="font-weight-bold">{{packageName}} package </span> for <span class="font-weight-bold">{{companyName}}</span>
                <span class="float-right text-blue cursor-pointer" (click)="backToPlan()">Change</span>
            </div>
        </div>
        <p class="ToHeading text-center mt-4">Create a new billing account</p>
        <div class="bor mb-4">
            <div class="col-sm-6 mt-3">
                <span class="mls">Person To Bill</span>
                <input type="text" [(ngModel)]="BillDetails.BillToName" class="form-control mal">
            </div>
            <div class="col-sm-6 mt-4">
                <span class="mlo">Email</span>
                <input type="text" [(ngModel)]="BillDetails.BillingEmail" class="form-control mal">
            </div>
            <div class="col-sm-6 mt-4">
                <span class="mlo">Phone</span>
                <input type="text" appDecimaNumericValidator [(ngModel)]="BillDetails.BillingContact" class="form-control mal">
            </div>
        </div>
        <div class="text-align-right mt-4">
            <button type="button" class="eCount-def-btn mb-2 mrr" (click)="showPaymentDetails(BillDetails)">Continue to review & pay</button>
        </div>
    </div>
    <div *ngIf="activeStatusId===3">
        <p class="ToHeading text-center">Payment Details</p>
        <div class="col-sm-12 row">
            <div class="col-sm-6">
                <div class="col-sm-12">
                    <div class="card">
                        <h6 class="clr">{{packageName}} Package</h6>
                        <div class="card-body">
                            <p class="card-text fss-14px">You can complete the billing within {{convertDateIntoDisplay(startDate_Formatted)}}.Your Subscription will be valid on {{convertDateIntoDisplay(startDate_Formatted)}} to {{convertDateIntoDisplay(expiryDate_Formatted)}}.</p>
                            <hr />
                            <div>
                                <span class="fss-14px">{{companyName}}</span>
                                <span class="fss-14px right">BDT {{AddedTotalUser*currentSelectedPackagePrice + currentCompanyPrice}}</span>
                                <p class="mb"></p>
                                <span class="fss-14px">Add Coupon Code:</span>
                                <div [formGroup]="couponForm" class="fss-14px right makeInline">
                                    <input formControlName="Name" />
                                    <button *ngIf="canAddCoupon" class="" (click)="applyCoupon()">Add</button>
                                    <button *ngIf="canRemoveCoupon" class="" (click)="removeCoupon()">Remove</button>
                                </div>
                                <p class="mb"></p>
                                <span class="fss-14px">Organization {{packageName}} Total</span>
                                <span class="fss-14px right">BDT {{ AddedTotalUser * currentSelectedPackagePrice + currentCompanyPrice }}</span>
                                <p *ngIf="couponData" class="mb"></p>
                                <span *ngIf="couponData" class="fss-14px">Coupon Code: {{couponData.Name}}</span>
                                <span *ngIf="couponData" class="fss-14px right">{{couponData.DiscountAmount}}% Discount</span>
                                <p *ngIf="couponData" class="mb"></p>
                                <span *ngIf="couponData" class="fss-14px">Total</span>
                                <span *ngIf="couponData" class="fss-14px right">BDT {{ ((AddedTotalUser * currentSelectedPackagePrice + currentCompanyPrice)*(100 - couponData.DiscountAmount))/100 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="card mt">
                        <h6 class="clr">Bill To</h6>
                        <div class="card-body">
                            <p class="ToHeading">{{BillDetails.BillToName}}</p>
                            <p class="fss-14px">{{BillDetails.BillingEmail}}</p>
                            <p class="fss-14px">{{BillDetails.BillingContact}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="3" (change)="onShowTransferMoneyDetails($event.target.value)" [checked]="true">
                            <label class="mt-2" for="2">Transfer Money</label>
                        </div>
                        <div class="row">
                            <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="1">
                            <label class="mt-2" for="1">Bkash</label>
                        </div>
                        <div class="row">
                            <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="2">
                            <label class="mt-2" for="2">Card</label>
                        </div>
                        <div [hidden]="!ShowTransferDetails" class="mla">
                            <p class="card-text fss-14px mt-2 mb-1">(Make your payment directly into our bank account. Please use given account number for transfer money.)</p>
                            <div class="row ml-1">
                                <span class="col-sm-6">Account Holder Name</span>
                                <span class="col-sm-6 txtColor">{{BankInfo.AccountHolderName}}</span>
                            </div>
                            <div class="row ml-1">
                                <span class="col-sm-6">Transfer Account</span>
                                <span class="col-sm-6 txtColor">{{BankInfo.AccountNo}}</span>
                            </div>
                            <div class="row ml-1">
                                <span class="col-sm-6">Bank Name</span>
                                <span class="col-sm-6 txtColor">{{BankInfo.BankName}}</span>
                            </div>
                            <div class="row ml-1">
                                <span class="col-sm-6">Branch Name</span>
                                <span class="col-sm-6 txtColor">{{BankInfo.BranchName}}</span>
                            </div>
                            <div class="row ml-1">
                                <span class="col-sm-6">Swift Code</span>
                                <span class="col-sm-6 txtColor">{{BankInfo.SwiftCode}}</span>
                            </div>
                        </div>
                        <div class="mt-4">
                            <span class="fss-14px red mla">After depositing the money, kindly attach the receipt document here</span>
                            <form [formGroup]="myForm">
                                <div class="form-group">
                                    <div class="das mla">
                                        <label for="file" class="file"></label>
                                        <input formControlName="file" id="file" type="file" class="form-control" (change)="onFileChange($event)" class="hidden" style="outline: none;margin-left: 50px;">
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="full-width text-center">
                            <button type="button" class="btns ml-4 mt-4 col-md-6" (click)="confirmPayment()">Confirm Purchase</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="removeable-item fixed-bottom footNote" #item>
        <span class="close crossSign" (click)="removeItem(item)">&times;</span>
        <p style="font-size: 15px;">Service, Retail/Trading, Manufacture package prices plan to suit your business. Find out more <a href="https://iquidi.com/pricing/" target="_blank" style="font-size: 15px; text-decoration: underline;">here </a></p>
    </div>
</app-dashboard>