import { Component, OnInit, ViewChild } from '@angular/core';
import { MainServiceService } from '../../service/Main/main-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { PendingListViewComponent } from '../pending-list-view/pending-list-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectDocumentModalComponent } from '../Document/reject-document-modal/reject-document-modal.component';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { CellClickedEvent } from 'ag-grid-community';
import { DocumentInfoModalComponent } from '../Document/document-info-modal/document-info-modal.component';
import { environment } from 'src/environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';

import { faCheckSquare, faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OperationType } from 'src/app/enum/operation-type';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { RejectPopupModalComponent } from '../Reject-popup-modal/reject-popup-modal/reject-popup-modal.component';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { OwnerLoanType } from 'src/app/enum/ownerLoanType';
import { PendingViewModel } from 'src/app/models-viewModels/pendingViewModel';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
@Component({
  selector: 'app-pending-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.css']
})
export class PendingListComponent implements OnInit {
  approvalUserData: any;
  finalUserLevel: any;
  hideData: any = true;
  PageType: any = {
    Invoice: 1,
    Bill: 2,
    PurchaseOrder: 3,
    Document: 4,
    GrantLoan: 5
  };
  InvoiceData: any = [];
  BillData: any = [];
  faCheckSquare = faCheckSquare;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  OperationType: OperationType;
  fileName: string;
  constructor(
    private mainService: MainServiceService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private documentService: DocumentServiceService,
    private ngxSmartModalService: NgxSmartModalService,
    private approvalHistoryModalService: ApprovalHistoryModalService,
    private incomeService: IncomeServiceService,
    private billService: BillsServiceService,
    public authService: AuthService) {
  }
  domLayout = 'autoHeight';
  rowHeight = 275;
  gridApi;
  canApprove = true;
  data: any[];
  searchText: string;
  status: string;
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  isPdf: boolean;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  zoom: number = 1;
  url: string = "";
  invoiceCount: number = 0;
  billCount: number = 0;
  purchaseOrderCount: number = 0;
  documentCount: number = 0;
  loanCount: number = 0;
  pageType: number;

  columnDefs = [
    {
      headerName: 'Number', field: 'Number',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRendererFramework: PendingListViewComponent
      // cellRendererFramework: RouterLinkRendereComponent,
      // cellRendererParams: {
      //     inRouterLink: '/expense/bill/',
      //   }},
    },
    { headerName: 'Ref.', field: 'Reference' },
    { headerName: 'Date', field: 'Date', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Amount', field: 'DueAmount' },
    {
      headerName: 'History', onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ];
  columnDefs2 = [
    {
      headerName: 'Document Name', field: 'DocumentName', cellClicked: true, cellRenderer: undefined, onCellClicked: (event: CellClickedEvent) => this.view(event),
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true, cellStyle: { 'color': 'blue', 'text-decoration': 'underline' }
    },
    { headerName: 'Uploaded By', field: 'UploadedBy' },
    { headerName: 'Uploaded At', field: 'UploadedAt', valueFormatter: this.convertGridDateIntoDisplay },
    // { headerName: 'Tag', field: 'DocumentType' },
    {
      headerName: 'History', cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      },
      hide: this.hideData
    }
  ];

  columnDefs3 = [

    {
      headerName: 'Document Name', field: 'DocumentName', onCellClicked: (event: CellClickedEvent) => this.view(event),
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true, cellStyle: { 'color': 'blue', 'text-decoration': 'underline' }
    },
    { headerName: 'Uploaded By', field: 'UploadedBy' },
    { headerName: 'Uploaded At', field: 'UploadedAt', valueFormatter: this.convertGridDateIntoDisplay },
    // { headerName: 'Tag', field: 'DocumentType' },
    {
      headerName: 'History', field: 'information',
      maxWidth: 90, onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      },
    },
  ];

  columnDefs4 = [
    {
      headerName: 'Number', field: 'Number',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRendererFramework: PendingListViewComponent
    },
    { headerName: 'Ref.', field: 'Reference' },
    { headerName: 'Date', field: 'Date', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Amount', field: 'DueAmount' }
  ];
  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1
  };
  rowSelection = 'multiple';
  rowSelection2 = 'single';
  async ngOnInit(): Promise<void> {
    this.status = this.activeRouter.snapshot.paramMap.get("id");
    await this.getPendingCount();
    this.getPendingCountByApproval();
    this.url = location.origin + "/#/";
  }
  async getPendingCount() {

    this.spinner.show();
    this.mainService.getPendingApprovalCount().subscribe((x: any) => {
      this.reLoadRowData(this.status);
      this.spinner.hide();

      // this.billCount = x.pendingBillCount;
      // console.log("Bill" + this.billCount);
      // this.invoiceCount = x.pendingInvoiceCount;
      // console.log("Invoice" + this.invoiceCount);
      // this.purchaseOrderCount = x.pendingPurchaseOrderCount;
      // this.documentCount = x.pendingDocumentCount;

      // this.getPendingBills();
      // this.getPendingInvoices();
      // this.getPendingPurchaseOrders();
      // this.getPendingDocument();
      //this.reLoadRowData(this.status); //by default invoice tab is selected.

    })
  }
  reLoadRowData = (status: string) => {
    localStorage.setItem('pendingListStatus', status);
    this.status = status;
    switch (status) {
      case 'invoices':
        this.OperationType = OperationType.INVOICE;
        this.pageType = this.PageType.Invoice;
        this.fileName = 'Invoice';
        this.getPendingInvoices();
        break;
      case 'bill':
        this.OperationType = OperationType.BILL;
        this.pageType = this.PageType.Bill;
        this.fileName = 'Bill';
        this.getPendingBills();
        break;
      case 'purchase-order':
        this.pageType = this.PageType.PurchaseOrder;
        this.getPendingPurchaseOrders();
        break;
      case 'document':
        this.OperationType = OperationType.DOCUMENT;
        this.fileName = 'Document';
        this.pageType = this.PageType.Document;
        this.getPendingDocument();
        break;
      case 'grantLoan':
        this.OperationType = OperationType.GRANTLOAN;
        this.pageType = this.PageType.GrantLoan;
        this.getPendingGrantLoans();
        break;
    }
  }
  convertGridDateIntoDisplay(date) {
    if (date.value == null)
      return '';
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  onCellClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      console.log(e);

    }
  }
  onGridReady(event) {
    this.gridApi = event.api;
  }

  getPendingInvoices() {
    this.status = 'invoices';
    this.spinner.show();
    this.mainService.GetPendingInvoices().subscribe((x) => {
      console.log("this is invoice data", x);
      this.data = x.data;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].Number = 'INV-' + this.data[i].Number;
      }
      this.canApprove = x.canApprove;
      this.invoiceCount = x.data.length;
      if (this.invoiceCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }
  getPendingBills() {
    this.status = 'bill';
    this.spinner.show();
    this.mainService.GetPendingBills().subscribe((x) => {
      this.data = x.data;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].Number = 'Bill-' + this.data[i].Number;
      }
      this.canApprove = x.canApprove;
      this.billCount = x.data.length;
      if (this.billCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }
  getPendingPurchaseOrders() {
    this.status = 'purchase-order';
    this.spinner.show();
    this.mainService.GetPendingPurchaseOrders().subscribe((x) => {
      this.data = x;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].Number = 'PO-' + this.data[i].Number;
      }
      this.purchaseOrderCount = x.length;
      //current there is no approval flow for purchase order, also the api is not returning canApprove data.
      if (this.purchaseOrderCount == 0) {
        this.canApprove = false;
      } else {
        this.canApprove = true;
      }
      this.spinner.hide();
    });
  }

  //get pending document
  getPendingDocument() {
    this.status = 'document';
    this.spinner.show();
    this.mainService.getPendingDocument().subscribe((x) => {
      console.log(x);
      this.data = x.data;
      this.documentCount = x.data.length;
      this.canApprove = x.canApprove;
      this.approvalUserData = x.approvalUser;
      this.finalUserLevel = x.finalLevel;
      //if(this.canApprove==true) this.hideData = x.approvalUser.LevelId==1?true:false;
      if (this.canApprove == true) this.hideData = false;
      console.log(this.hideData)
      if (this.documentCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }

  getPendingGrantLoans() {
    this.status = 'grantLoan';
    this.spinner.show();
    this.mainService.GetPendingLoanList(OwnerLoanType.GrantLoan).subscribe((x) => {
      this.data = x;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].Number = 'Loan-' + this.data[i].Number;
      }
      //this.canApprove = x.canApprove;
      this.loanCount = x.length;
      //if (this.loanCount == 0) {
      //  this.canApprove = false;
      //}
      this.spinner.hide();
    });
  }

  getPendingCountByApproval() {
    this.mainService.getPendingDocument().subscribe((x) => {
      this.documentCount = x.data.length;

      this.mainService.GetPendingBills().subscribe((x) => {
        this.billCount = x.data.length;

        this.mainService.GetPendingInvoices().subscribe((x) => {
          this.invoiceCount = x.data.length;

          this.mainService.GetPendingPurchaseOrders().subscribe((x) => {
            this.purchaseOrderCount = x?.data?.length;

            this.mainService.GetPendingLoanList(OwnerLoanType.GrantLoan).subscribe((x) => {
              this.loanCount = x?.length;
            });

          });

        });

      });

    });
  }

  getSelectedRowData() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    switch (this.status) {
      case 'invoices':
        this.approveInvoiceData(selectedData);
        break;
      case 'bill':
        this.approveBillData(selectedData);
        break;
      case 'purchase-order':
        this.approvePurchaseOrderData(selectedData);
        break;
      case 'document':
        console.log(selectedData, '\n', this.approvalUserData);
        this.approveDocumentData(selectedData);
        break;
      case 'grantLoan':
        this.approveOrRejectGrantLoan(iQuidiActionType.Approve);
        break;
    }
  } 


  getSelectedRowDataForReject() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    switch (this.status) {
      case 'invoices':
        this.rejectInvoice(selectedData);
        break;
      case 'bill':
        this.rejectBill(selectedData);
        break;
      //Reject Not required in purchase order
      //case 'purchase-order': 
      //  this.approvePurchaseOrderData(selectedData);
      //  break;
      case 'document':
        //console.log(selectedData,'\n', this.approvalUserData);
        this.rejectDocumentData();
        break;
      case 'grantLoan':
        this.approveOrRejectGrantLoan(iQuidiActionType.Reject);
        break;
    }
  }


  approveInvoiceData(selectedData) {
    this.spinner.show();
    this.mainService.ApproveInvoice(selectedData).subscribe((x) => {
      this.toastr.success('Invoice successfully approved.');
      this.getPendingInvoices();
      this.spinner.hide();
    });
  }
  approveBillData(selectedData) {
    this.spinner.show();
    this.mainService.ApproveBill(selectedData).subscribe((x) => {
      this.toastr.success('Bill successfully approved.');
      this.getPendingBills();
      this.spinner.hide();
    });
  }
  approvePurchaseOrderData(selectedData) {
    this.spinner.show();
    this.mainService.ApprovePurchaseData(selectedData).subscribe((x) => {
      this.toastr.success('Purchase Order successfully approved.');
      this.getPendingPurchaseOrders();
      this.spinner.hide();
    });
  }
  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }
  convetToPDF = () => {
    var data = document.getElementById('pl');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Pending list.pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }

  approveDocumentData(passingData: any) {
    var data = {
      dataType: 'approve',
      selectedData: passingData,
      approvalData: this.approvalUserData,
      finalUserLevel: this.finalUserLevel,
    }
    this.documentService.saveRejectDocumentModalData(data);
    const modalRef = this.modalService.open(RejectDocumentModalComponent,
      { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = 1;
    modalRef.result.then((result) => {
      console.log(result);
      if (result == 'Success') {
        location.reload();
      }
    },
      (reason) => {
        console.log('Dismissed action: ' + reason);
      });
  }

  approveOrRejectGrantLoan(actionType){
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    selectedData.forEach(x => {
      x.iQuidiActionType = actionType;
    });
    this.spinner.show();
    this.mainService.UpdateStatusGrantLoanList(selectedData).subscribe((x) => {
      if(x.Success){
        this.toastr.success("Success");
        location.reload();
      }else{
        this.toastr.error("Something went wrong!");
      }
      this.spinner.hide();
    });
  }

  rejectDocumentData() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    var data = {
      dataType: 'reject',
      selectedData: selectedData,
      approvalData: this.approvalUserData,
      finalUserLevel: this.finalUserLevel,
    }
    this.documentService.saveRejectDocumentModalData(data);
    const modalRef = this.modalService.open(RejectDocumentModalComponent,
      { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = 1;
    modalRef.result.then((result) => {
      console.log(result);
      if (result == 'Success') {
        location.reload();
      }
    },
      (reason) => {
        console.log('Dismissed action: ' + reason);
      });
  }



  modalMethod(event: any) {
    this.spinner.show();
    console.log('coming here', event.data);
    this.documentService.saveModalInfoData(event.data);
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,
      { size: 'xl', backdrop: 'static', keyboard: false, centered: true });
  }
  selected: any;
  src: string;
  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }
  view(event: any) {
    let file = event.data;
    console.log(file);
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.DocumentBlobName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.DocumentBlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }
  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }


  print(file: any, id: any, pdf: boolean) {
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

 /*rejectBill(selectedData) {
    this.spinner.show();
    this.mainService.RejectBill(selectedData).subscribe((x) => {
      this.toastr.success('Bill successfully rejected.');
      this.getPendingBills();
      this.spinner.hide();
    });
  }*/

  rejectBill(selectedData) {
    var checkSelect = this.checkSelection(selectedData)
    if(checkSelect != true){
      this.getBillData(selectedData[0].Id);
    }
  }

  getBillData = (id: any) => {
    this.spinner.show();
    this.billService.getSingleBillDetails(id).subscribe((x) => {
      if (x) {
        this.spinner.hide();
        this.BillData = x;
        const modalRef = this.modalService.open(RejectPopupModalComponent, {
          size: "md",
          backdrop: "static",
          keyboard: false,
          centered: true,
        });
        modalRef.componentInstance.data = this.BillData;
        modalRef.componentInstance.TypeId = 1;
        modalRef.componentInstance.isProcessDone.subscribe( ($event) => {
          if( $event == 'Success' ){
            console.log('MODAL RESULT: SUCCESS!');
            this.getPendingBills();
          }
        });
      }
      
    })
  }

  /*rejectInvoice(selectedData) {
    this.spinner.show();
    this.mainService.RejectInvoice(selectedData).subscribe((x) => {
      this.toastr.success('Invoice successfully rejected.');
      this.getPendingInvoices();
      this.spinner.hide();
    });
  }*/

  rejectInvoice(selectedData) {
    var checkSelect = this.checkSelection(selectedData)
    if(checkSelect != true){
      this.getInvoiceData(selectedData[0].Id);
    }
  }

  checkSelection(selectedData){
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return true;
    }
    if(selectedData.length > 1){
      this.toastr.warning('select only one item');
      return true;
    }
    return false;
  }

  getInvoiceData = (id: any) => {
    this.spinner.show();
    this.incomeService.getSingleInvoiceDetails(id).subscribe((x) => {
      if (x) {
        this.spinner.hide();
        this.InvoiceData = x;
        const modalRef = this.modalService.open(RejectPopupModalComponent, {
          size: "md",
          backdrop: "static",
          keyboard: false,
          centered: true,
        });
        modalRef.componentInstance.data = this.InvoiceData;
        modalRef.componentInstance.TypeId = 2;
        modalRef.componentInstance.isProcessDone.subscribe( ($event) => {
          if( $event == 'Success' ){
            console.log('MODAL RESULT: SUCCESS!');
            this.getPendingInvoices();
          }
        });
      }
      
    })
  }

  modalViewFullHistory(data:any){
    this.spinner.show();
    let modalData = {
      ActionNo: data.Number,
      CreatedDate: data.Date,
      DueDate: data.DueDate,
      Number: this.OperationType == OperationType.DOCUMENT ? data.Number : data.Id,
      CustomerName: data.ToName
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = this.fileName; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = this.OperationType;
  }

  IsOnlyHasDocumentFeature() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      return true;
    else
      return false;
  }
}
