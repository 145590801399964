import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AttachmentServiceService } from "src/app/service/Attachment/attachment-service.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { environment } from "src/environments/environment";
import { MainServiceService } from "../../../service/Main/main-service.service";
import { CurrencyList } from "../../../helper/currency-List";
@Component({
  selector: "app-create-organization",
  templateUrl: "./create-organization.component.html",
  styleUrls: ["./create-organization.component.css"],
})
export class CreateOrganizationComponent implements OnInit, OnDestroy {
  @ViewChild("fileUploader") fileUploader: ElementRef;
  organizationForm: FormGroup;
  constructor(
    private attachmentService: AttachmentServiceService,
    private authService: AuthService,
    private dataService: DataService,
    public fb: FormBuilder,
    private settingsService: SettingsServiceService,
    private router: Router,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService
  ) {}
  organizationTypeName: any;
  imageError: any;
  fileUrl = environment.fileBaseUrl;
  email: string;
  isEmail: boolean;
  ifSubmit: boolean = false;
  logo: any;
  currencyList = CurrencyList;

  ngOnInit(): void {
    this.getLogInUserEmail();
    this.organizationData();
    this.dataService.emitSidebarHide(false);
    this.dataService.emitSetMessageUpdate({
      HasAction: false,
      Message:
        "Remaining Step 3: Setup your organization details and complete all the steps.",
    });
  }

  ngOnDestroy() {
    this.dataService.emitMessageUpdate(true);
    this.dataService.emitSidebarHide(true);
  }

  getLogInUserEmail() {
    this.email = localStorage.getItem("user");
    this.isEmail = (this.email?.includes("@")) ?? false;

  }

  organizationData() {
    this.getAllOrganizationType();
    this.organizationForm = this.fb.group({
      Name: ["", Validators.required],
      Address: ["", Validators.required],
      TimeZoneId: [null],
      FinancialYearEndDay: [31, Validators.required],
      FinancialYearEndMonth: [12, Validators.required],
      OrganizationTypeId: [3, Validators.required],
      BlobName: [],
      BillToName: [""],
      BillToEmail: [
        this.isEmail == false ? this.email : "",
        Validators.required,
      ],
      PhoneNo: [this.isEmail == false ? this.email : "", Validators.required],
      BillingEmail: [this.isEmail == false ? this.email : ""],
      BillingContact: [this.isEmail == false ? this.email : ""],
      VatRegNo: [""],
      Website: [""],
      OrganizationRegNo: [""],
      CurrencyId: [0]
    });
    this.organizationForm.patchValue({ BlobName: "" });
  }
  monthList = [
    { Value: 1, Text: "January", Max: 31 },
    { Value: 2, Text: "February", Max: 28 },
    { Value: 3, Text: "March", Max: 31 },
    { Value: 4, Text: "April", Max: 30 },
    { Value: 5, Text: "May", Max: 31 },
    { Value: 6, Text: "June", Max: 30 },
    { Value: 7, Text: "July", Max: 31 },
    { Value: 8, Text: "August", Max: 31 },
    { Value: 9, Text: "September", Max: 30 },
    { Value: 10, Text: "October", Max: 31 },
    { Value: 11, Text: "November", Max: 30 },
    { Value: 12, Text: "December", Max: 31 },
  ];

  monthNameChange = () => {
    let Max = this.monthList.filter(
      (x) => x.Value == this.organizationForm.value.FinancialYearEndMonth
    );
    this.organizationForm.patchValue({ FinancialYearEndDay: Max[0].Max });
  };

  droPdownController = {
    Name: false,
    Type: false,
    Address: false,
    End: false,
    Logo: false,
  };
  onSaveOrganization = () => {
    if (this.organizationForm.value.BillingContact == "") {
      this.organizationForm
        .get("BillingContact")
        .patchValue(this.organizationForm.value.PhoneNo);
    }
    if (this.organizationForm.value.BillingEmail == "") {
      this.organizationForm
        .get("BillingEmail")
        .patchValue(this.organizationForm.value.BillToEmail);
    }
    this.ifSubmit = true;
    if (this.organizationForm.invalid) {
      this.toaster.warning("Please fill all the required fields!");
      return;
    } else {
      this.spinner.show();
      this.mainService
        .SaveOrganizationData(this.organizationForm.value)
        .subscribe((x) => {
          let userInfo = {
            Token: "",
            CompanyList: [],
            SelectedCompany: {},
            UserName: "",
          };
          if (x.Success) {
            x.CompanyList[0].CurrencyId = this.organizationForm.value.CurrencyId;
            let info = this.authService.getUserInfo();
            userInfo.CompanyList = x.CompanyList;
            userInfo.SelectedCompany = x.CompanyList[0];
            userInfo.UserName = info.UserName;
            this.mainService
              .setSelectedOrganization(x.CompanyList[0].CompanyId)
              .subscribe((y) => {
                userInfo.Token = y.Token;
                this.spinner.hide();
                this.authService.setUserInfo(JSON.stringify(userInfo));
                this.toaster.success("Company added successfully");
                this.dataService.emitOrganizationUpdate(true);
                this.router.navigate(['/accounting/bank-accounts'], { queryParams: { step: 1 }  });
              });
          } else {
            this.spinner.hide();
            this.toaster.error(x.Message);
          }
        });
    }
  }
  onSaveAndBuyOrganization = () => {
    if (this.organizationForm.value.BillingContact == "") {
      this.organizationForm
        .get("BillingContact")
        .patchValue(this.organizationForm.value.PhoneNo);
    }
    if (this.organizationForm.value.BillingEmail == "") {
      this.organizationForm
        .get("BillingEmail")
        .patchValue(this.organizationForm.value.BillToEmail);
    }
    this.ifSubmit = true;
    if (this.organizationForm.invalid) {
      this.toaster.warning("Please fill all the required fields!");
      return;
    } else {
      this.spinner.show();
      this.mainService
        .SaveAndBuyOrganizationData(this.organizationForm.value)
        .subscribe((x) => {
          let userInfo = {
            Token: "",
            CompanyList: [],
            SelectedCompany: {},
            UserName: "",
            IsCompanySubscriptionExpired: true,
            RoleId: 1
          };
          if (x.Success) {
            x.CompanyList[0].CurrencyId = this.organizationForm.value.CurrencyId;
            let info = this.authService.getUserInfo();
            userInfo.CompanyList = x.CompanyList;
            userInfo.SelectedCompany = x.CompanyList[0];
            userInfo.UserName = info.UserName;
            userInfo.RoleId = x.RoleId;
            this.mainService.setSelectedOrganization(x.CompanyList[0].CompanyId).subscribe((y) => {
              userInfo.Token = y.Token;
              this.spinner.hide();
              this.authService.setUserInfo(JSON.stringify(userInfo));
              this.toaster.success("Company added successfully");
              this.dataService.emitOrganizationUpdate(true);
              this.router.navigate(['/subscription/manage-subscription'], { queryParams: { step: 1 }  });
            });
          } else {
            this.spinner.hide();
            this.toaster.error(x.Message);
          }
        });
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  RightToDown(id: string) {
    return this.droPdownController[id] ? true : false;
  }

  showOrHideData(id: string) {
    if (this.droPdownController[id] == true) {
      this.droPdownController[id] = false;
    } else {
      this.droPdownController[id] = true;
    }
  }
  getAllOrganizationType() {
    this.settingsService.GetOrganizationType().subscribe((x) => {
      this.organizationTypeName = x;
    });
  }

  openLogoUploader() {
    document.getElementById("logoUploader").classList.add("show");
    document.getElementById("logoUploader").classList.add("shown");
  }

  closeLogoUploaderPopUp() {
    document.getElementById("logoUploader").classList.remove("show");
    document.getElementById("logoUploader").classList.remove("shown");
    this.selectedLogo = undefined;
    this.selectedFile = undefined;
  }

  selectedFile: string;
  selectedLogo: File;
  upload(event) {
    this.selectedLogo = event.target.files[0];
    this.selectedFile = event.target.files[0].name;
    let res = this.validateImage(event.target.files[0]);
    console.log(res);

    if (!res) {
      this.toaster.warning(this.imageError);
      this.selectedFile = undefined;
      this.selectedLogo = undefined;
    }
  }
  value;
  file_changed(s) {
    this.value = s;
  }

  validateImage(file: File) {
    let _URL = window.URL || window.webkitURL;
    const max_size = 8000000;
    const allowed_types = ["image/png", "image/jpeg"];
    const max_height = 15200;
    const max_width = 25600;
    let width;
    let height;
    let reader = new FileReader();
    if (file.size > max_size) {
      this.imageError = "Maximum size allowed is 1MB";
      return false;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);

    reader.readAsDataURL(file);
    reader.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      console.log(width);
      if (width >= 200 && height >= 150) {
        this.imageError = `Maximum width allowed is 200px and height 150px`;
        return false;
      }
    };
    return true;
  }

  uploadFile(file: File) {
    let formData = new FormData();
    formData.append("ff", file);
    this.attachmentService.uploadLogo(formData).subscribe((x) => {
      let file: any = x;
      this.logo = { logoUrl: this.makeLogoUrl(file.body?.BlobName) };
      this.organizationForm.patchValue({ BlobName: file.body?.BlobName });
      this.closeLogoUploaderPopUp();
      this.fileUploader.nativeElement.value = null;
    });
  }

  removeLogo() {
    this.organizationForm.patchValue({ BlobName: "" });
    this.logo = { logoUrl: this.makeLogoUrl("") };
    this.selectedFile = undefined;
    this.selectedLogo = undefined;
  }

  makeLogoUrl(url: string) {
    return url == undefined || url == "" ? undefined : this.fileUrl + url;
  }

  modelChanged(newObj) {
    this.organizationForm
      .get("BillingContact")
      .patchValue(this.organizationForm.value.PhoneNo);
  }
}
