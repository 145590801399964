<app-dashboard>
  <app-page-header-title mainTitle="{{ 'Contacts.contactList.allContacts' | translate }}" subTitle="Home" backURL="/">
      <div class="full-width text-align-right">
          <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox" />
          <button type="button" routerLink="/contacts/create-contact" class="eCount-def-btn ml-4">
              {{ 'Contacts.contactList.createContact' | translate }}
          </button>
          <button (click)="onBtnExport()" class="eCount-button3 ml-4">
              {{ 'Contacts.contactList.export' | translate }}
          </button>
      </div>
  </app-page-header-title>
  <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('customer')" [routerLinkActive]="['active']" routerLink="/contacts/list/customer"> Customer ({{ customerCount }}) </a>
      </li>
      <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('supplier')" [routerLinkActive]="['active']" routerLink="/contacts/list/supplier"> Supplier ({{ supplierCount }}) </a>
      </li>
      <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('system')" [routerLinkActive]="['active']" routerLink="/contacts/list/system-generated"> System Generated ({{ SystemCount }}) </a>
      </li>
      <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('all')" [routerLinkActive]="['active']" routerLink="/contacts/list/all"> All </a>
      </li>
      <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('delivery')" [routerLinkActive]="['active']" routerLink="/contacts/list/delivery-partner"> Delivery Partner </a>
      </li>
  </ul>
  <div class="pt-3">
      <ag-grid-angular class="ag-theme-alpine" style="width: 100%; height: 420px;" #topGrid id="topGrid" [rowData]="contacts" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)" [defaultColDef]="defaultColDef"></ag-grid-angular>
  </div>
</app-dashboard>

<ngx-smart-modal #balanceHistory identifier="balanceHistory">
  <div class="pb-4">
      <span class="modalHeaderText pl-2">Balance History</span>
      <hr />
      <div class="row pr-2 pl-2">
          <div class="col-md-12">
              <div>
                  <form [formGroup]="BalanceHistoryFrom">
                      <div class="form-group">
                          <span class="formTitle">Payable Amount</span>
                          <input type="number" formControlName="PayableAmount" class="form-control mt-1" disabled />
                      </div>
                      <div class="form-group">
                          <span class="formTitle">Total Receive Loan</span>
                          <input type="text" formControlName="ReceiveLoan" class="form-control mt-1" disabled />
                      </div>
                      <div class="form-group">
                          <span class="formTitle">Receivable Amount</span>
                          <input type="text" formControlName="ReceivableAmount" class="form-control mt-1" disabled />
                      </div>
                      <div class="form-group">
                          <span class="formTitle">Total Grant Loan</span>
                          <input type="text" formControlName="GrantLoan" class="form-control mt-1" disabled />
                      </div>
                      <div class="form-group">
                          <span class="formTitle">Advance Amount</span>
                          <input type="text" formControlName="AdvanceAmount" class="form-control mt-1" disabled />
                      </div>
                      <div class="form-group">
                          <span class="formTitle"> Balance = (Receivable + Grant Loan) - (Advance + Payable + Receive Loan) </span>
                          <input type="text" formControlName="TotalAmount" class="form-control mt-1" disabled />
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</ngx-smart-modal>