import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MainServiceService } from "../../../service/Main/main-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as html2canvas from "html2canvas";
import * as moment from "moment";
import jspdf from "jspdf";

@Component({
  selector: "app-business-partner-report",
  templateUrl: "./business-partner-report.component.html",
  styleUrls: ["./business-partner-report.component.css"]
})

export class BusinessPartnerReportComponent implements OnInit {

  contactId: any;
  contactData: any;
  fromDate: any;
  toDate: any;
  bsData: any;
  openingBalance: any;
  totalPaid: any;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.contactId = Number(this.activeRoute.snapshot.paramMap.get("contactId"));
    
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.fromDate = this.formatDate(firstDay);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.toDate = this.formatDate(lastDay);

    this.getcustomerDetails();
    this.getBSReportByContactId();
  }

  getcustomerDetails = () => {
    this.mainService.GetCustomerById(this.contactId).subscribe((x) => {
      if (x) {
        this.contactData = x;
        console.log(x);
      }
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  }

  getBSReportByContactId = () => {
    const data = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      contactId: this.contactId
    };

    this.spinner.show();
    this.mainService.GetBSReports(data).subscribe((x) => {
      this.bsData = x.data;
      this.openingBalance = x.openingBalance;
      this.totalPaid = x.data.filter((x) => x.TypeId == 3 || x.TypeId == 4 || x.TypeId == 5 || x.TypeId == 6 || x.TypeId == 7 || x.TypeId == 8).reduce((sum, item) => sum + item.Amount, 0);
      this.spinner.hide();
    });
  }

  convetToPDF = () => {
    var data = document.getElementById("bsReport");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      let fromDate = this.convertDateIntoDisplay(this.fromDate);
      let toDate = this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Customer Deposit History (${fromDate} to ${toDate}).pdf`;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }

  goToUrl = (item) => {
    if (item.TypeId == 9) {
      this.router.navigate(["/expense/bill", "Bill-" + item.ReferenceNo]);
    } else if (item.TypeId == 7 || item.TypeId == 10) {
      this.router.navigate(["/income/invoice", "INV-" + item.ReferenceNo]);
    }
  }

  currencyFormatter(currency, sign) {
    if(this.mainService.IsNull(currency)){
      return '';
    }
    else{
      var sansDec = currency.toFixed(2);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return sign + `${formatted}`;
    }
  }

  getDueBalance() {
    if(this.mainService.IsNull(this.bsData)){
      return '';
    }
    else{
      return this.currencyFormatter(Math.abs(this.bsData[this.bsData?.length - 1]?.Balance), '');
    }
  }

}
