<app-dashboard>
    <app-page-header-title mainTitle="Edit Bill / {{BillId}}/ {{StatusName}}" subTitle="Bill Overviews" backURL="/expense/bill-overview/All">
        <div class="full-width text-align-right">
            <div class="dropdown">
                <button type="button" style="z-index: 1;" class="btn attachment-button" (click)="openAttachmentDropdown()">
                    <i style="font-size: 20px !important;" class="material-icons">insert_drive_file</i>
                    <span style="color:darkgrey; font-size: 12px; " *ngIf="totalFile>=1">{{totalFile}}</span>
                </button>
                <div class="x-tip-tl" style="height: 0px;">
                    <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                </div>
                <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                    <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="2"></app-attachment-uploader>
                </div>
            </div>
            <button *ngIf="StatusId!=3 " type="button" (click)="updateBill()" class="eCount-def-btn ml-4">Save</button>
            <button *ngIf="StatusId==3 " type="button" (click)="updateBill()" class="eCount-def-btn ml-4">Update</button>
            <button *ngIf="StatusId!=3 " type="button" (click)="saveAsSubmitBill()" class="eCount-def-btn ml-4">Submit</button>
            <button *ngIf="canApprove" type="button" style="background: #5cac00;" (click)="saveBillData()" class="eCount-def-btn ml-4">Approve</button>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <form [formGroup]="billForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2 col-sm-12" *ngIf="!billForm.value.IsBasicBill">
                    <label>To <span class="clr">*</span></label>
                    <ng-select [items]="allCustomer" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewCustomer" [loading]="loading" name="name" formControlName="SupplierId" [ngClass]="{'submitted':ifSubmit===true}" required>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                            <b>+ Add New Supplier</b>: {{search}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-md-2 col-sm-12" *ngIf="billForm.value.IsBasicBill">
                    <label>To <span class="clr">*</span></label>
                    <p class="form-controlinputBox" type="text" formControlName="SupplierName" style="background-color: #E9ECEF;">{{ billForm.value.SupplierName }}</p>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Date</label>
                    <input class="form-control" type="date" formControlName="BillDate" disabled id="ebtest" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="show($event)" required>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Due Date <span class="clr">*</span></label>
                    <input class="form-control" type="date" formControlName="DueDate" id="ebtest1" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="showSelectedDate($event)" required>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Reference</label>
                    <input class="form-controlinputBox" type="text" formControlName="ReferenceNo">
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Bill No</label>
                    <span class="form-controlinputBox d-flex">
                        <label style="background-color: #dddddd;padding-bottom:10%;">{{ShowBillNo}}</label>
                        <input *ngIf="StatusId!=3" class="form-controlinputBox2" formControlName="BillNo" type="text">
                        <input *ngIf="StatusId==3" class="form-controlinputBox2" formControlName="BillNo" type="text" disabled>
                    </span>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Amounts are</label>
                    <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="TaxTypeId"></ng-select>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-1" [ngClass]="{ 'col-md-2': isDescriptiveBill() }"> Item </div>
                    <div class="col-md-2"> Description <span class="clr">*</span></div>
                    <div class="col-md-2"> Account </div>
                    <div class="col-md-1"> Quantity </div>
                    <div *ngIf="!isDescriptiveBill()" class="col-md-1"> Received </div>
                    <div class="col-md-1"> Price </div>
                    <div class="col-md-1"> VAT% </div>
                    <div class="col-md-1"> AIT% </div>
                    <div class="col-md-2"> Amount </div>
                </div>
                <hr class="pb-0 mb-0" />
                <div>
                    <div formArrayName="BillItems" *ngFor="let bill of billForm.get('BillItems')['controls']; let i = index;">
                        <div class="invoiceGridItemRow d-block" [ngClass]="[bill.selected ? 'selectedRow' : 'notSelectedRow']">
                            <div class="d-flex" [formGroupName]="i">
                                <div class="col-md-1 borderLeft d-flex  pl-0" [ngClass]="{ 'col-md-2': isDescriptiveBill() }">
                                    <div class="dragIcon">
                                        <i class="icon-grid2"></i>
                                    </div>
                                    <div style="width: 100%;margin-left: 5px; ">
                                        <div [ngClass]="{ 'itemWidth': !isDescriptiveBill() }">
                                            <ng-select [items]="PurchaseableItems" placeholder="Select Items" #selectItem class="customSaleItem" [clearable]="false" bindLabel="ItemName" bindValue="Id" (change)="changespurchaseableItems(i)" formControlName="ItemId">
                                                <ng-template ng-header-tmp>
                                                    <a (click)="openItemModal(selectItem)" role="button" aria-haspopup="false" aria-expanded="false">
                                                        <span style="color: #007bff;">+ Add Item</span>
                                                    </a>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 borderLeft ">
                                    <textarea class="form-control" style="resize: none;margin: 5px; min-height: 20px; max-height: 25px;" type="text" formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}" required></textarea>
                                </div>
                                <div class="col-md-2 borderLeft ">
                                    <div style="width: 100%;">
                                        <ng-select [items]="allChartOfAccount" placeholder="Select Account" class="customSaleItem" [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId" groupBy="GroupName"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 borderLeft ">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="Quantity" (change)="updateDataBillItem(bill.ItemId)" [ngClass]="{'submitted':ifSubmit===true}" required>
                                </div>
                                <div *ngIf="!isDescriptiveBill()" class="col-md-1 borderLeft ">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="ReceivedItems" [ngClass]="{'submitted':ifSubmit===true}" [ngStyle]="{'color': isReceivedMax(i)? 'red' : '#444'}" required readonly>
                                </div>
                                <div class="col-md-1 borderLeft ">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="UnitPrice" (change)="updateDataBillItem(bill.ItemId)" [ngClass]="{'submitted':ifSubmit===true}" required>
                                </div>
                                <div class="col-md-1 borderLeft mt-1">
                                    <div style="width: 100%;">
                                        <ng-select [items]="taxRateList" [ngClass]="{'disable': billForm.value.TaxTypeId===3}" placeholder="" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" formControlName="TaxRateId"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 borderLeft mt-1">
                                    <div style="width: 100%;">
                                        <ng-select [items]="taxRateList" [ngClass]="{'disable': billForm.value.TaxTypeId===3}" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" formControlName="AdvanceTaxRateId"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-2  d-flex">
                                    <div>
                                        <input class="form-control" type="text" style="background-color:white; color:black" appDecimaNumericValidator disabled formControlName="LineTotal">
                                    </div>
                                    <div style="padding: 7px 10px; color: #c7c2c2;">
                                        <div class="dropdown mr-4">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                <i class="icon-more float-right"></i>
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                                <div class="productRow pb-3" (click)="removeInvoiceItem(bill.ItemId, i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addBlankItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line </a>
                        </div>
                    </div>
                </div>
                <div class="container-bottom d-flex">
                    <div class="col-md-8 col-sm-12 row">
                        <div class="form-group col-sm-5">
                            <label>Notes</label>
                            <div class="textarea-wrapper">
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea" placeholder="Write down some important notes using only 250 characters" formControlName="Notes"></textarea>
                                <span class="remaning">{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                        <div class="col-sm-7 row">
                            <div class="col-sm-3">
                                <label style="font-weight: 600;">Repeating</label>
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" formControlName="IsRepeating" (change)="changeType()">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="col-sm-7 ml-4" *ngIf="billForm.value.IsRepeating === true">
                                <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types <span class="required-sign"></span>
                                </label>
                                <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id" [ngClass]="{'submitted':ifSubmit===true && billForm.value.IsRepeating === true}" formControlName="RepeatingTypeId" [clearable]="false" required></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4">{{subTotalAmount.toFixed(2)}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="billForm.value.TaxTypeId!=3">
                            <div class="col-md-8">
                                <label>VAT</label>
                            </div>
                            <div class="col-md-4">{{billForm.value.TaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="billForm.value.TaxTypeId!=3">
                            <div class="col-md-8">
                                <label>AIT</label>
                            </div>
                            <div class="col-md-4">{{billForm.value.AdvanceTaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-3">
                            <div class="col-md-8">
                                <label>Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold">{{billForm.value.BillAmount | currency:'':''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>