import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/responseModel.model';

@Injectable({
  providedIn: 'root'
})

export class ManualJournalService {

  baseurl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }

  public saveManualJournal(manualJournal: any): Observable<ResponseModel> {
    const url = this.baseurl +'ManualJournal/Save';
    return this.http.post<any>(url, manualJournal);
  }

  public postManualJournal(id: any): Observable<ResponseModel> {
    return this.http.get<any>(this.baseurl + "ManualJournal/Post?id=" + id);
  }

  public UpdateAndDraftManualJournal(manualJournal: any): Observable<ResponseModel> {
    const url = this.baseurl +'ManualJournal/UpdateAndDraft';
    return this.http.post<ResponseModel>(url, manualJournal);
  }

  public UpdateAndPostManualJournal(manualJournal: any): Observable<ResponseModel> {
    const url = this.baseurl +'ManualJournal/UpdateAndPost';
    return this.http.post<ResponseModel>(url, manualJournal);
  }

  public deleteManualJournal(id: any): Observable<ResponseModel> {
    return this.http.delete<any>(this.baseurl + "ManualJournal/Delete?id=" + id);
  }

  public getManualJournalList(): Observable<any[]> {
    const url = this.baseurl +'ManualJournal/GetList';
    return this.http.get<any[]>(url);
  }

  public getManualJournal(id: number): Observable<any> {
    return this.http.get<any>(this.baseurl + "ManualJournal/Get?id=" + id).pipe(retry(1));
  }

}

