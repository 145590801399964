import { Component, HostListener, OnInit } from '@angular/core';
import * as html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ReportServiceService } from '../../../service/Report/report-service.service';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
@Component({
  selector: 'app-todays-transaction',
  templateUrl: './todays-transaction.component.html',
  styleUrls: ['./todays-transaction.component.css']
})
export class TodaysTransactionComponent implements OnInit {
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    console.log("scrolling");
  this.constantTableHeader();
  }
  fromDate: string = '';
  toDate: string = '';
  allAccountTransaction: any = []
  totalCredit: number=0;
  totalDebit: number=0;
  totalGrossAmount: number=0;
  totalTaxAmount: number=0;
  totalAdvanceTaxAmount: number=0;
  accountNameList : any =[];
  selectedAccountIdList = [];
  companyName: string= "";
  CashIn: number;
  CashOut: number;
  sticky;
  tops;
  currencyCode: string;
  constructor(
    private spinner: NgxSpinnerService,
    private route: Router,
    private reportService: ReportServiceService, 
    private toaster: ToastrService,
    private mainService: MainServiceService
  ) {}

  ngOnInit(): void {
    var date = new Date();
    var firstDay = date;
    this.fromDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.toDate = this.fromDate;
    this.GetAccountNameList();
    this.initialiseOrganization();
    this.currencyCode = this.mainService.getCurrencyCode();
  }

  GetAccountNameList(){
   
    this.reportService.GetAccountNameList().subscribe((x)=>{
      this.accountNameList = x;
      this.reportGenerate();
    });
  }
  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }

  reportGenerate=()=>{
    this.selectedAccountIdList=this.accountNameList.map(item=> item.Id)
    if (!this.toDate || !this.fromDate){
      this.toaster.warning('To Date and From date are required !');
      return;
    }
    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      accountIdList:this.selectedAccountIdList.join(", ")
    }
    
    if(data.accountIdList!=""){
      this.spinner.show();
    this.reportService.GetAccountTransaction(data).subscribe((x)=>{
      console.log(x);
      this.allAccountTransaction = x.accountTransList;
      this.totalCredit= x.TotalCreditView;
      this.totalDebit= x.TotalDebitView;
      this.totalGrossAmount= x.TotalGrossAmount;
      this.totalTaxAmount= x.TotalTaxAmount;
      this.totalAdvanceTaxAmount=x.TotalAdvanceTaxAmount;
      this.getTodaysCahsINCashOutTransaction();
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error please check");
      this.spinner.hide();
    })
  }
  else
  {
    this.toaster.warning('No transaction available');
  }
  }

  convertDateIntoDisplay=(date)=>{
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  convetToPDF = () => {
    var data = document.getElementById('printGeneralReport');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let fromDate=this.convertDateIntoDisplay(this.fromDate);
      let toDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Today's Transaction report (${fromDate} to ${toDate}).pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }

  calculateBalance=(identifier, data)=>{
    let total = 0
    data.map((x)=>{
      if(x[identifier]){
        total = total + x[identifier]
      }
    })
    return total.toFixed(2);
  }

  constantTableHeader() {
    let header = document.getElementById("tableHeader");
    let bodyt = document.getElementById("printGeneralReport");
    let title = document.getElementById("tableTitle");
    let tableBody = document.getElementById("tableBody");
    
    if(!header.classList.contains("sticky")){
      this.sticky = bodyt.offsetTop;
    }
    
    if (window.pageYOffset > this.sticky+55) {
      header.classList.add("sticky");
      header.style.width = (bodyt.offsetWidth-50) + 'px'
      if(tableBody){
        tableBody.classList.add("pt-p50px");
      }
    } else {
      header.classList.remove("sticky");
      header.style.width = (bodyt.offsetWidth-50) + 'px'
      if(tableBody){
        tableBody.classList.remove("pt-p50px");
      }
      
    }
  }
  getTodaysCahsINCashOutTransaction(){
    this.reportService.GetTodaysCashInOutTrans().subscribe((x) => {
      this.CashIn = x.CashIn;
      this.CashOut = x.CashOut;
    });
  }
  backToPreviousPage = () => {
    this.route.navigate(['/']);
  }
}
