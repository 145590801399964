<app-dashboard>
  <div class="f-ss-14px border1pxGray col-sm-12 shadow-sm bg-white rounded">
    <div class="col-sm-12 row" style="margin: 0px;padding: 0px;">
      <div class="col-sm-3">
        <div>
          <span class="f-ss-14px">Account Receivable</span>
          <span class="TitleText">{{ receiveableAmount }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-4">
        <div style="text-align: left;" class="marl">
          <span class="f-ss-14px" style="cursor: pointer; color: blue;" (click)="goToPaymentHistoryPage(1)">Bank</span>
          <span class="TitleText">{{ bankBalance }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-2">
        <div style="text-align: left;">
          <span class="f-ss-14px" style="cursor: pointer; color: blue;" (click)="goToPaymentHistoryPage(2)">Cash</span>
          <span class="TitleText">{{ cashBalance }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-3 text-align-right">
        <div>
          <span class="f-ss-14px">Account Payable</span>
          <span class="TitleText">{{ payableAmount }} {{currencyCode}}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 row mt-3" style="margin: 0px;padding: 0px;">
      <div class="col-sm-3">
        <div>
          <span class="f-ss-14px">Grant Loan</span>
          <span class="TitleText">{{ TotalGrantLoan }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-4">
        <div style="text-align: left;" class="marl">
          <span class="f-ss-14px">Receive Loan</span>
          <span class="TitleText">{{ TotalReceiveLoan }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-2">
        <div style="text-align: left;">
          <span class="f-ss-14px" style="cursor: pointer; color: blue;" (click)="goToPaymentHistoryPage(3)">Digital Wallet</span>
          <span class="TitleText">{{ digitalCashBalance }} {{currencyCode}}</span>
        </div>
      </div>
      <div class="col-sm-3 text-align-right">
        <div  class="f-ss-14px tes">
          Balance
          <span class="TitleText">{{Balance}} {{currencyCode}}</span>
          <span class="tooltiptext">Balance = (Bank, Cash, Digital Wallet) + (Grant Loan + Receivable ) - (Payable + Receive loan )</span>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div
    class="sectionContainer border1pxGray shadow-sm bg-white rounded"
    style="display: inline-block; width: 49%; margin-right: 20px;"
  >
    <div class="chart-wrapper">
      <div>
        <div class="widgetcontent">
          <div class="widgetheader mt-2">
            <header class="panelHeading">
              <a href="" class="widgetheaderanchor">
                <h3
                  class="panelHeading xui-text-panelheading widgetheaderdescription"
                >
                  <span>Invoices owed to you</span>
                </h3>
              </a>
            </header>
          </div>
          <div>
            <div class="summarytablereverse">
              <a
                class="chartbutton f-ssb-14px eCount-button3"
                [routerLink]="['/income/create-invoice']"
              >
                New Sales invoice
              </a>
              <div class="summaryTableBalanceContainer">
                <div class="summaryTableDiv">
                  <table class="summaryMainTable">
                    <tbody>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="['/income/invoice-overview/Draft']"
                              class="text-minorlink"
                            >
                              {{ TotalDraftInvoice }} Draft invoices
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="['/income/invoice-overview/Draft']"
                              class="text-minorlink"
                            >
                              {{ draftInvoiceValue | currency: '':'' }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="[
                                '/income/invoice-overview/Awaiting-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ totalInvoiceAwaitingPayment }} Awaiting payment
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="[
                                '/income/invoice-overview/Awaiting-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{
                                awaitingInvoiceValue - overdueInvoiceValue
                                  | currency: '':''
                              }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="[
                                '/income/invoice-overview/Overdue-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ this.TotalInvoiceOverDue }} Overdue
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="[
                                '/income/invoice-overview/Overdue-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ overdueInvoiceValue | currency: '':'' }}
                            </a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas
        baseChart
        [data]="barChartInvoiceData"
        [labels]="barChartInvoiceLabels"
        [options]="barChartInvoiceOptions"
        [plugins]="barChartInvoicePlugins"
        [colors]="barChartInvoiceColors"
        [legend]="barChartInvoiceLegend"
        [chartType]="barChartInvoiceType"
      ></canvas>
    </div>
  </div>
  <div
    class="sectionContainer border1pxGray shadow-sm bg-white rounded"
    style="display: inline-block; width: 49%;"
  >
    <div class="chart-wrapper">
      <div>
        <div class="widgetcontent">
          <div class="widgetheader mt-2">
            <header class="panelHeading">
              <a href="" class="widgetheaderanchor">
                <h3
                  class="panelHeading xui-text-panelheading widgetheaderdescription"
                >
                  <span>Bills you need to pay</span>
                </h3>
              </a>
            </header>
          </div>
          <div>
            <div class="summarytablereverse">
              <a
                class="chartbutton text-center f-ssb-14px eCount-button3"
                [routerLink]="['/expense/create-new-bill']"
              >
                New bill
              </a>
              <div class="summaryTableBalanceContainer">
                <div class="summaryTableDiv">
                  <table class="summaryMainTable">
                    <tbody>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Awaiting-approval'
                              ]"
                              class="text-minorlink"
                            >
                              {{ TotalDraftBill }} Awaiting Approval
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Awaiting-approval'
                              ]"
                              class="text-minorlink"
                            >
                              {{ draftBillValue | currency: '':'' }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Awaiting-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ totalBillAwaitingPayment }} Awaiting payment
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Awaiting-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{
                                awaitingBillValue - overduebillValue
                                  | currency: '':''
                              }}
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Overdue-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ TotalBillOverDue }} Overdue
                            </a>
                          </span>
                        </td>
                        <td class="summaryLabel-table-cell">
                          <span class="summaryLabel summaryLabel-primary">
                            <a
                              href="#"
                              [routerLink]="[
                                '/expense/bill-overview/Overdue-payment'
                              ]"
                              class="text-minorlink"
                            >
                              {{ overduebillValue | currency: '':'' }}
                            </a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas
        baseChart
        [data]="barChartBillData"
        [labels]="barChartBillLabels"
        [options]="barChartBillOptions"
        [plugins]="barChartBillPlugins"
        [legend]="barChartBillLegend"
        [colors]="barChartBillColors"
        [chartType]="barChartBillType"
      ></canvas>
    </div>
  </div>

  <div class="row" style="margin: 0px;">
    <div
      class="col-md-6 sectionContainer shadow-sm bg-white"
      style="padding: 0px 10px 0px 0px;"
    >
      <div class="chart-wrapper rounded" style="width: 100%;"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div>
    <div
      class="sectionContainer border1pxGray shadow-sm bg-white rounded"
      style="display: inline-block; width: 49%; margin-right: 20px;"
    >
      <div class="chart-wrapper">
        <div>
          <div class="widgetcontent" style="margin-bottom: 10px;">
            <div class="widgetheader mt-2">
              <header class="panelHeading">
                <a href="" class="widgetheaderanchor">
                  <h3
                    class="panelHeading xui-text-panelheading widgetheaderdescription"
                  >
                    <span>Last 7 day's cash in and out</span>
                  </h3>
                </a>
              </header>
            </div>
          </div>
        </div>
        <canvas
          baseChart
          [datasets]="barChartLast7Data"
          [labels]="barChartLast7Labels"
          [options]="barChartLast7Options"
          [plugins]="barChartLast7Plugins"
          [colors]="barChartLast7Colors"
          [legend]="barChartLast7Legend"
          [chartType]="barChartLast7Type"
        ></canvas>
      </div>
    </div>
    <div
      class="sectionContainer border1pxGray shadow-sm bg-white rounded"
      style="display: inline-block; width: 49%;"
    >
      <div class="chart-wrapper">
        <div>
          <div class="widgetcontent" style="margin-bottom: 10px;">
            <div class="widgetheader mt-2">
              <header class="panelHeading">
                <a href="" class="widgetheaderanchor">
                  <h3
                    class="panelHeading xui-text-panelheading widgetheaderdescription"
                  >
                    <span>Last 6 month's cash in and out</span>
                  </h3>
                </a>
              </header>
            </div>
          </div>
        </div>
        <canvas
          baseChart
          [datasets]="barChartLast6MonthData"
          [labels]="barChartLast6MonthLabels"
          [options]="barChartLast6MonthOptions"
          [plugins]="barChartLast6MonthPlugins"
          [colors]="barChartLast7Colors"
          [legend]="barChartLast6MonthLegend"
          [chartType]="barChartLast6MonthType"
        ></canvas>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</app-dashboard>
