import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';
import { AttachmentServiceService } from "src/app/service/Attachment/attachment-service.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { environment } from "src/environments/environment";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import { MainServiceService } from "../../../service/Main/main-service.service";
import { SettingsServiceService } from "../../../service/Settings/settings-service.service";
import { CurrencyList } from "../../../helper/currency-List";
@Component({
  selector: "app-organization-setting",
  templateUrl: "./organization-setting.component.html",
  styleUrls: ["./organization-setting.component.css"],
})
export class OrganizationSettingComponent implements OnInit {
  organizationForm: FormGroup;
  SelectedCompany: any;
  logo: any;
  ifSubmit = false;
  imageError:string = '';
  backSteps = 0;
  cardImageBase64: string;
  fileUrl = environment.fileBaseUrl;
  isUpdateAble: boolean = false;
  @ViewChild("fileUploader") fileUploader: ElementRef;
  monthList = [
    { Value: 1, Text: "January", Max: 31 },
    { Value: 2, Text: "February", Max: 28 },
    { Value: 3, Text: "March", Max: 31 },
    { Value: 4, Text: "April", Max: 30 },
    { Value: 5, Text: "May", Max: 31 },
    { Value: 6, Text: "June", Max: 30 },
    { Value: 7, Text: "July", Max: 31 },
    { Value: 8, Text: "August", Max: 31 },
    { Value: 9, Text: "September", Max: 30 },
    { Value: 10, Text: "October", Max: 31 },
    { Value: 11, Text: "November", Max: 30 },
    { Value: 12, Text: "December", Max: 31 },
  ];

  organizationTypeName: any;

  droPdownController = {
    Name: false,
    Type: false,
    Address: false,
    End: false,
    Logo: false,
  };
  currencyList = CurrencyList;

  constructor(
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private attachmentService: AttachmentServiceService,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    public fb: FormBuilder,
    private settingsService: SettingsServiceService,
    private accountingService: AcountingServiceService
  ) {}

  ngOnInit() {
    var userInfo = this.authService.getUserInfo();
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.backSteps = params.step ===  undefined ? 0 : params.step;
    });
    if (this.backSteps > 0)
    {
      this.dataService.emitSidebarHide(false);
    }
    var list = userInfo.CompanyList;
    console.log(userInfo);
    this.organizationForm = this.fb.group({
      Id: [0],
      Name: ["", Validators.required],
      Address: ["", Validators.required],
      FinancialYearEndDay: ["", Validators.required],
      FinancialYearEndMonth: ["", Validators.required],
      OrganizationTypeId: [3, Validators.required],
      BlobName: [""],
      BillToEmail: ["", Validators.required],
      BillToName: [""],
      PhoneNo: ["", Validators.required],
      BillingEmail: [""],
      BillingContact: [""],
      VatRegNo: [""],
      Website: [""],
      OrganizationRegNo: [""],
      CurrencyId: [0]
    });
    this.getAllOrganizationType();
    this.organizationData();
  }
  organizationData() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.mainService
        .getSelectedOrganizationDetails(userInfo.SelectedCompany.CompanyId)
        .subscribe((x) => {
          this.organizationForm.patchValue({
            Id: x.Details.Id,
            Name: x.Details.Name,
            Address: x.Details.Address,
            FinancialYearEndDay: x.Details.FinancialYearEndDay,
            FinancialYearEndMonth: x.Details.FinancialYearEndMonth,
            OrganizationTypeId:
              x.Details.OrganizationTypeId == null
                ? 3
                : x.Details.OrganizationTypeId,
            BlobName: x.Details.BlobName,
            BillToEmail: x.Details.BillToEmail,
            BillToName: x.Details.BillToName,
            PhoneNo: x.Details.PhoneNo,
            VatRegNo: x.Details.VatRegNo,
            Website: x.Details.Website,
            BillingEmail: x.Details.BillingEmail,
            BillingContact: x.Details.BillingContact,
            OrganizationRegNo: x.Details.OrganizationRegNo,
            CurrencyId: x.Details.CurrencyId
          });
          this.isUpdateAble = x.HasTransaction;
          this.disAbledInput();
          this.logo = { logoUrl: this.makeLogoUrl(x.Details.BlobName) };
          this.spinner.hide();
        });
    }
  }

  disAbledInput = () => {
    this.isUpdateAble == true
      ? this.organizationForm.get("OrganizationTypeId").disable()
      : null;
    this.isUpdateAble == true
      ? this.organizationForm.get("FinancialYearEndDay").disable()
      : null;
    this.isUpdateAble == true
      ? this.organizationForm.get("FinancialYearEndMonth").disable()
      : null;
  };

  makeLogoUrl(url: string) {
    return url == undefined || url == "" ? undefined : this.fileUrl + url;
  }
  monthNameChange = () => {
    let Max = this.monthList.filter(
      (x) => x.Value == this.organizationForm.value.FinancialYearEndMonth
    );
    this.organizationForm.patchValue({ FinancialYearEndDay: Max[0].Max });
  };

  onSaveOrganization = () => {
    this.ifSubmit = true;
    if (this.organizationForm.invalid) {
      this.toaster.warning("Please fill all the required fields!");
      return;
    } else {
      this.spinner.show();
      this.mainService
        .UpdateOrganizationData(this.organizationForm.value)
        .subscribe((x) => {
          if (x.Success) {
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            this.SelectedCompany = {
              CompanyId: this.organizationForm.value.Id,
              Name: this.organizationForm.value.Name,
              CurrencyId: this.organizationForm.value.CurrencyId
            };
            let data = {
              Token: userInfo.Token,
              SelectedCompany: this.SelectedCompany,
              CompanyList: x.CompanyList,
              UserName: userInfo.UserName,
            };
            localStorage.setItem("userInfo", JSON.stringify(data));
            this.dataService.emitDefaultNotificationUpdate(true);
            this.dataService.emitOrganizationUpdate(true);
            this.spinner.hide();
            this.toaster.success("Company Updated Successfully", "Success!");
          } else {
            this.spinner.hide();
            this.toaster.warning(x.Message);
          }
        });
    }
  };

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  RightToDown(id: string) {
    return this.droPdownController[id] ? true : false;
  }

  showOrHideData(id: string) {
    if (this.droPdownController[id] == true) {
      this.droPdownController[id] = false;
    } else {
      this.droPdownController[id] = true;
    }
  }

  getAllOrganizationType() {
    this.settingsService.GetOrganizationType().subscribe((x) => {
      this.organizationTypeName = x;
    });
  }

  openLogoUploader() {
    document.getElementById("logoUploader").classList.add("show");
    document.getElementById("logoUploader").classList.add("shown");
  }

  closeLogoUploaderPopUp() {
    document.getElementById("logoUploader").classList.remove("show");
    document.getElementById("logoUploader").classList.remove("shown");
    this.selectedLogo = undefined;
    this.selectedFile = undefined;
  }

  selectedFile: string;
  selectedLogo: File;
  async upload(event) {
    this.selectedLogo = event.target.files[0];
    this.selectedFile = event.target.files[0].name;
    let res = await this.validateImage(event.target.files[0]);
    console.log(res);

    if (!res) {
      this.toaster.warning(this.imageError);
      this.selectedFile = undefined;
      this.selectedLogo = undefined;
    }
  }
  value;
  file_changed(s) {
    this.value = s;
  }

  async checkResolution(file:File){
    const img = new Image();
    img.src =  window.URL.createObjectURL(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      console.log(width);
      if (width != 150 && height != 150) {
        this.imageError = `Maximum width allowed is 150px and height 150px`;
      }
    };
  }

  async validateImage(file: File) {
    let _URL = window.URL || window.webkitURL;
    const max_size = 8000000;
    const allowed_types = ["image/png", "image/jpeg"];
    const max_height = 15200;
    const max_width = 25600;
    let width;
    let height;

    if (file.size > max_size) {
      this.imageError = "Maximum size allowed is 1MB";
      return false;
    }

    await this.checkResolution(file);
    console.log(this.imageError);
    return true;
  }

  uploadFile(file: File) {

    if (this.imageError.toString().length>0) {
      this.toaster.warning(this.imageError);
      this.selectedFile = undefined;
      this.selectedLogo = undefined;
      this.imageError = '';
      return;
    }

    let formData = new FormData();
    formData.append("ff", file);

    this.attachmentService.uploadLogo(formData).subscribe((x) => {
      let file: any = x;
      this.logo = { logoUrl: this.makeLogoUrl(file.body?.BlobName) };
      this.organizationForm.patchValue({ BlobName: file.body?.BlobName });
      this.closeLogoUploaderPopUp();
      this.fileUploader.nativeElement.value = null;
    });
  }

  removeLogo() {
    this.organizationForm.patchValue({ BlobName: "" });
    this.logo = { logoUrl: this.makeLogoUrl("") };
    this.selectedFile = undefined;
    this.selectedLogo = undefined;
  }
  modelChanged(newObj) {
    this.organizationForm
      .get("BillingContact")
      .patchValue(this.organizationForm.value.PhoneNo);
  }
  gotoNextStep = () => {
    this.ifSubmit = true;
    if (this.organizationForm.invalid) {
      this.toaster.warning("Please fill all the required fields!");
      return;
    } else {
      this.spinner.show();
      this.mainService
        .UpdateOrganizationData(this.organizationForm.value)
        .subscribe((x) => {
          if (x.Success) {
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            this.SelectedCompany = {
              CompanyId: this.organizationForm.value.Id,
              Name: this.organizationForm.value.Name,
            };
            let data = {
              Token: userInfo.Token,
              SelectedCompany: this.SelectedCompany,
              CompanyList: x.CompanyList,
              UserName: userInfo.UserName,
            };
            localStorage.setItem("userInfo", JSON.stringify(data));
            this.dataService.emitDefaultNotificationUpdate(true);
            this.dataService.emitOrganizationUpdate(true);
            this.backSteps == 1 ? this.router.navigate(['/owner/business-owner-create'], { queryParams: { step: 1 }  })
            : this.router.navigate(['/subscription/manage-subscription'], { queryParams: { step: 1 }  });
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.toaster.warning(x.Message);
          }
        });
    }
  }
}
