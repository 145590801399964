<div class="modal-header" style="top: 10px;">
    <h4  class="modal-title" style="font-size: 17px; font-weight: 500;" *ngIf="fromParent == 1">Grant Loan</h4>
    <h4  class="modal-title" style="font-size: 17px; font-weight: 500;" *ngIf="fromParent == 2">Receive Loan</h4>
    <button [value]="0" type="button" class="eCount-def-btn mlb mb-2 mt-1" (click)="save(0)">
        Save
    </button>
    <button [value]="1" type="button" class="eCount-def-btn mb-2 mt-1 ml-1" (click)="save(1)">
        Submit
    </button>
    <button [value]="2" type="button" class="eCount-def-btn approve-green-button mb-2 mt-1 ml-1" (click)="save(2)">
        Approve
    </button>
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
    <div class="pt-4 row headSection modal-body">
        <form [formGroup]="bpLoanForm" class="d-flex w-100">
            <div class="col-5 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-sm-12">
                        <label  class="fnt"> Loan Type<span class="clr">*</span></label>
                        <select required class="form-control" style="width: 100%;pointer-events: none;" formControlName="LoanTypeId">
                            <option value="1" >Grant Loan</option>
                            <option value="2" >Receive Loan</option>
                        </select>
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-sm-12">
                        <label  class="fnt">Business Partner<span class="clr">*</span></label>
                        <ng-select [items]="allCustomer" [clearable]="false" bindLabel="CompanyName"  bindValue="Id"  formControlName="ContactId">
                            <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                                <span>{{item.CompanyName}}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-sm-12">
                        <label  class="fnt">Amount<span class="clr">*</span></label>
                        <input type="number" formControlName="Amount" (change)="updateTotalPrice()" appDecimaNumericValidator  class="fnt amountSec form-control inputSection"  />
                    </div>
                </div>
                <div class="form-group d-flex">         
                    <div class="col-sm-12">
                        <label class="fnt">Date</label>
                        <input class="form-control" type="date" formControlName="Date" id="oltest1" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="showSelectedDate($event)" required>
                    </div>
                </div>  
                <div class="form-group d-flex">
                    <div class="col-sm-12">
                        <label  class="fnt">Description<span class="clr">*</span></label>
                        <textarea type="text"   formControlName="Description" style="height: 65px;" rows="3" placeholder=""  class="form-control inputSection"></textarea>
                    </div>
                </div>
                </div>
 
       
         <div class="col-7 f-ss-14px">

            <div class="form-group d-flex">
                <div class="col-sm-12">
                    <label  class="fnt">Account<span class="clr">*</span></label>
                    <ng-select [items]="bankList" [clearable]="false" bindLabel="AccountName"  bindValue="GLAccountId"  formControlName="AccountId">
                        <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                            <span>{{item.BankAccount}}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="col-sm-12 row">
                <div class="col-sm-3 mart">
                    <label class="switch">
                        <input name="IsCustomer" formControlName="IsFixed" (change)="changeFixedRateOrInterest()" class="switch-input"
                            type="checkbox" />
                        <span class="switch-label" data-on="Fixed" data-off="Fixed"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <label  class="fnt">Interest Rate %</label>
                    <input class="form-control" type="number" formControlName="InterestRate" (focus)="onFocusEvent($event)" appDecimaNumericValidator (change)="updateTotalPrice()" [readonly]="this.bpLoanForm.value.IsFixed == true">
                </div>
                <div class="col-sm-4">
                    <label  class="fnt">Fixed Rate</label>
                    <input class="form-control" type="number" formControlName="FixedRate" (focus)="onFocusEvent($event)" appDecimaNumericValidator (change)="updateTotalPrice()" [readonly]="this.bpLoanForm.value.IsFixed == false" >
                </div>
            </div>
        <div class="form-group d-flex">         
            <div class="col-sm-12">
                <label class="fnt mt-4">Interest Amount</label>
                <input class="form-control" type="text" formControlName="InterestAmount"  required readonly>
            </div>
        </div> 
        <div class="form-group d-flex">         
            <div class="col-sm-12">
                <label  class="fnt mt-2">Loan Term (Months)<span class="clr">*</span></label>
                <input class="form-control" type="number" formControlName="LoanPeriod" (focus)="onFocusEvent($event)" appDecimaNumericValidator  required>
            </div>
        </div> 
        <div class="form-group d-flex">         
            <div class="col-sm-12">
                <label  class="fnt">Total Amount</label>
                <input class="form-control" type="text" formControlName="TotalAmount"   required readonly>
            </div>
        </div> 
        </div>
        </form>
    </div>


