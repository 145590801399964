import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentCreatePageComponent } from '../document-create-page/document-create-page.component';
import { DocumentInfoModalComponent } from '../document-info-modal/document-info-modal.component';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { faCheckSquare, faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DocumentStatus } from 'src/app/enum/document-status';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { CustomDocumentApprovalFlowModalComponent } from '../custom-document-approval-flow-modal/custom-document-approval-flow-modal.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { DocumentVersioningModalComponent } from '../document-versioning-modal/document-versioning-modal.component';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { OperationType } from 'src/app/enum/operation-type';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';

@Component({
  selector: 'app-document-overview',
  templateUrl: './document-overview.component.html',
  styleUrls: ['./document-overview.component.css']
})

export class DocumentOverviewComponent implements OnInit {
  name: string = undefined;
  files: any;
  isPdf: boolean;
  zoom: number = 1;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  routes: string;
  users: [];
  searchText: string = "";
  imageSearch: string = "";
  url: string="";
  firstHalf: any;
  secondHalf: any;
  tempFiles: any[] = [];
  fileName: string;
  changeOnInventory = false;
  seeMoreOption: boolean = false;
  customerName: string;
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  status: any;
  submittedAttachments:any[] = [];
  draftCount:number = 0;
  awaitingApprovalCount: number = 0;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  achiveCount: number = 0;
  faCheckSquare = faCheckSquare;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  selectedSearchOption: number;
  filteredResults: any[] = [];
  showResults: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 50;
  isShowMoreOption: boolean = false;
  private searchTermSubject: Subject<string> = new Subject<string>();
  searchResult$: Observable<string> = this.searchTermSubject.asObservable();
  src: string;
  selected: any;
  imagewidth: number;
  searchOptions = [
    {Id: 1, Name: 'Employee Id'},
    {Id: 2, Name: 'Reference 1'},
    {Id: 3, Name: 'Reference 2'},
    {Id: 4, Name: 'Document Type'},
    {Id: 5, Name: 'Tag'}
  ];
  searchOptionMappings = {
    1: 'ReferenceNo',
    2: 'Reference1',
    3: 'Reference2',
    4: 'DocumentTypeName',
    5: 'EmployeeId'
  };

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private modal: NgbModal,
    private attachmentService: AttachmentServiceService,
    private activeRouter: ActivatedRoute,
    private modalService: NgbModal,
    private documentService: DocumentServiceService,
    private settingsService: SettingsServiceService,
    private approvalHistoryModalService: ApprovalHistoryModalService
  ) { }

  ngOnInit(): void {
    this.status = this.activeRouter.snapshot.paramMap.get("status");
    this.selectedSearchOption = 1;
    this.configBaseUrl();
    this.getAllFilesByType();
    localStorage.setItem('type','Settings');
    this.searchResult$.pipe(
      debounceTime(100),
      switchMap(s => {
          if(s == '') this.more()
          else this.getSearchResults(s)
          return s;
      })
    ).subscribe();
    this.getAllDynamicLabelNameForDocumentInputs();
  }

  //START REGION => SEARCHING
  onSearch(query: string) {
    if (query.length === 0) {
      this.showResults = false;
      this.filteredResults = [];
      return;
    }
    const selectedProperty = this.searchOptionMappings[this.selectedSearchOption];
    const Results = this.tempFiles?.filter((result) => result[selectedProperty]?.toLowerCase()?.includes(query?.toLowerCase()) && result.ApprovalStatus == this.getMappedApprovalStatus());
    this.filteredResults = [...new Set(Results.map(result => result[selectedProperty]))];
    this.showResults = true;
  }

  onListItemClick(result: string) {
    const inputField = document.querySelector('.documentSearchBox') as HTMLInputElement;
    inputField.value = result;
    this.showResults = false;
    if(result != '')
      this.getSearchResults(result);
  }

  setSelectedSearchOption(option: number) {
    this.onListItemClick('');
    this.selectedSearchOption = option;
    this.files = this.tempFiles?.filter(x => x.ApprovalStatus === this.getMappedApprovalStatus());
  }

  updateFilter = (event) => {
    this.seeMoreOption = false;
    this.searchTermSubject.next(event.target.value);
  }
  
  getSearchResults(searchTerm: string) {
    const selectedProperty = this.searchOptionMappings[this.selectedSearchOption];
    const status = this.getMappedApprovalStatus()
    if(status == null) {
      this.files = this.tempFiles?.filter(x => x[selectedProperty]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) && (x.ApprovalStatus == null|| x.ApprovalStatus == undefined));
    }
    else {
      this.files = this.tempFiles?.filter(x => x[selectedProperty]?.toLowerCase().includes(searchTerm?.toLowerCase()) && x.ApprovalStatus == this.getMappedApprovalStatus());
    }
  }

  getMappedApprovalStatus() {
    switch(this.status) {
      case 'draft':
        return DocumentStatus.DRAFT;
      case 'awaiting-approval':
        return DocumentStatus.AWAITINGAPPROVAL;
      case 'approved':
        return DocumentStatus.APPROVED;
      case 'rejected':
        return DocumentStatus.REJECTED;
      case 'archive':
        return null;
    }
  }

  //END REGION => SEARCHING
  seeMore() {
    this.pageNumber = this.pageNumber + 1;
    this.getAllFilesByType();
  }

  more(){
    if(this.status=='all'){
      this.tempFiles = [];
      this.getAllFilesByType();
    }
    else if(this.status=='draft'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==1);
    }
    else if(this.status=='awaiting-approval'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==2);
    }
    else if(this.status=='approved'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==3);
    }
    else if(this.status=='rejected'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==4);
    }
    else{
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==null|| x.ApprovalStatus==undefined);
    }
    this.seeMoreOption = false;
  }

  configBaseUrl(){
    this.url = location.origin+"/#/";
  }

  getAllFilesByType() {
    this.imageSearch = '';
    var userInfo = this.authService.getUserInfo();
    this.spinner.show();
    if(userInfo.IsCompanyHasOnlyDocumentFeature){
      this.attachmentService.GetAllTagsAttachmentDocumentRelatedOnly(this.pageNumber, this.pageSize).subscribe((x: any) => {
        this.spinner.hide();
        if(x.length == this.pageSize) this.isShowMoreOption = true;
        x.forEach(element => {
          this.tempFiles.push(element);
        });
        this.reLoadRowData(this.status);
        this.draftCount = this.tempFiles?.filter(x=>x.ApprovalStatus==1).length;
        this.awaitingApprovalCount = this.tempFiles?.filter(x=>x.ApprovalStatus==2).length;
        this.approvedCount = this.tempFiles?.filter(x=>x.ApprovalStatus==3).length;
        this.rejectedCount = this.tempFiles?.filter(x=>x.ApprovalStatus==4).length;
        this.achiveCount = this.tempFiles?.filter(x=>x.ApprovalStatus==null|| x.ApprovalStatus==undefined).length;
      }, (err) => {
        this.files = [];
        this.spinner.hide();
      });
    }
    else{
      this.attachmentService.getAllTagsAttachment().subscribe((x: any) => {
        this.spinner.hide();
        x.forEach(element => {
          this.tempFiles.push(element);
        });
        this.reLoadRowData(this.status);
        this.draftCount = this.tempFiles?.filter(x=>x.ApprovalStatus==1).length;
        this.awaitingApprovalCount = this.tempFiles?.filter(x=>x.ApprovalStatus==2).length;
        this.approvedCount = this.tempFiles?.filter(x=>x.ApprovalStatus==3).length;
        this.rejectedCount = this.tempFiles?.filter(x=>x.ApprovalStatus==4).length;
        this.achiveCount = this.tempFiles?.filter(x=>x.ApprovalStatus==null|| x.ApprovalStatus==undefined).length;
      }, (err) => {
        this.files = [];
        this.spinner.hide();
      });
    }
  }

  mouseOver(id: number) {
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1?.classList?.add("mdivpaddinghover");
    element1?.classList?.remove("mdivpadding");
    element.classList.add("mystyle");
    let element2 = document.getElementById("imagesview" + id)
    element2.classList.add("imagediv-hover");
    document.getElementById("options" + id).classList.add("show");
    document.getElementById("options" + id).classList.remove("hide");
  }

  mouseOut(id: number) {
    if(this.submittedAttachments.find(x=>x.BlobName==id)) return;
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1?.classList?.remove("mdivpaddinghover");
    element1?.classList?.add("mdivpadding");
    element.classList.remove("mystyle");
    let element2 = document.getElementById("imagesview" + id);
    element2.classList.remove("imagediv-hover");
    document.getElementById("options" + id).classList.remove("show");
    document.getElementById("options" + id).classList.add("hide");
  }

  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  isPdfFile(file: any) {
    let ext = file.BlobName?.split(".")[1];
    return ext == 'pdf' ? true : false;
  }

  view(file: any) {
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }

  share(file: any) {
    this.selected = file;
    this.getAllUsers();
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
  }

  print(file: any, id: any, pdf: boolean){
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  getAllUsers() {
    this.spinner.show();
    this.users = [];
    this.mainService.GetSubscribedUserList().subscribe((x) => {
      this.spinner.hide();
      this.users = x;
    });
  }

  public createNewItem = (documentingType: number) => {
    const modalRef = this.modal.open(DocumentCreatePageComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.actionType = iQuidiActionType.Save;
    modalRef.componentInstance.documentingType = documentingType;
    modalRef.result.then((result) => {
      if (result.Success){
        this.tempFiles = [];
        this.getAllFilesByType();
      }
    });
  }

  public editItem = (file: any) => {
    const modalRef = this.modal.open(DocumentCreatePageComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.actionType = iQuidiActionType.Update;
    modalRef.componentInstance.documentingType = file.TagType;
    modalRef.componentInstance.parentData = file;
    modalRef.result.then((result) => {
      if (result.Success){
        this.tempFiles = [];
        this.getAllFilesByType();
      }
    });
  }
  
  shareFile(id:number){
    var userInfo = this.authService.getUserInfo();
    this.attachmentService.shareFile({ BlobName: this.selected.BlobName, SharedToId: id,
      ReferenceNo: this.selected.ReferenceNo, FileName: this.selected.FileName, Parent: this.name }).subscribe((x) => {
      this.toastr.success("Shared successful", 'Success!');
    });
  }

  reLoadRowData(data:any){
    if(this.status != data){
      this.onListItemClick('');
    }
    this.status = data;
    if(data=='all'){
      this.getAllFilesByType();
    }
    else if(data=='draft'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==1);
    }
    else if(data=='awaiting-approval'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==2);
    }
    else if(data=='approved'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==3);
    }
    else if(data=='rejected'){
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==4);
    }
    else{
      this.files = this.tempFiles?.filter(x=>x.ApprovalStatus==null|| x.ApprovalStatus==undefined);
    }
    this.seeMoreOption = this.files.length > 50 ? true : false ;
      if (this.seeMoreOption){
        this.firstHalf = this.files?.slice(0, 49);
        this.secondHalf = this.files?.slice(20, this.files?.length + 1);
        this.files = [...this.firstHalf];
      }
  }

  pressDocument(item:any){
    if(this.submittedAttachments.find(x=>x.BlobName==item.BlobName)){
      this.submittedAttachments = this.submittedAttachments.filter(x=>x.BlobName!=item.BlobName);
      this.mouseOut(item.BlobName);
    }
    else{
      this.submittedAttachments.push(item);
      this.mouseOver(item.BlobName);
    }
  }

  submitItems() {
    if(this.submittedAttachments.length==0){
      this.toastr.warning('Please select at least one document.');
      return;
    }
    this.settingsService.GetCustomApprovalSettings().subscribe(x => {
      if(x != null){
        if(x.DocumentApproval == true){
          this.submitItemsWithCustomApproval();
        }
        else if(x.DocumentApproval == false){
          this.submitItemsWithoutCustomApproval();
        }
      }
      else{
        this.submitItemsWithoutCustomApproval();
      }
    })
  }

  submitItemsWithoutCustomApproval(){
    this.spinner.show();
    this.attachmentService.docDraftToApproval(this.submittedAttachments).subscribe((x) => {
      this.toastr.success("Document submitted Successfully.");
      location.reload();
      this.spinner.hide();
    }, (err) => {});
  }

  submitItemsWithCustomApproval(){
    const modalRef = this.modal.open(CustomDocumentApprovalFlowModalComponent, { size: 'md', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.submittedAttachments = this.submittedAttachments;
    modalRef.result.then((result) => {
      if (result.Success){
        this.tempFiles = [];
        this.getAllFilesByType();
      }
    });
  }

  documentModalView(data:any){
    this.documentModalViewFullHistory(data);
  }

  documentModalViewFullHistory(data:any){
    this.spinner.show();
    let modalData = {
      ActionNo: data.Id,
      CreatedDate: data.CreatedDate,
      DueDate: data.CreatedDate,
      Number: data.Id,
      CustomerName: data.UpdatedByName,
      DocumentBlobName: data?.BlobName,
      ApprovalStatus: data?.ApprovalStatus
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent, { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = 'Document'; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = OperationType.DOCUMENT;
  }

  deleteCurrDocFile(file:any){
    this.attachmentService.deleteCurrDocFile(file).subscribe((x) => {
      this.toastr.success("Deleted successfully", 'Success!');
      location.reload();
    });
  }

  openDocumentModalFullHistory(file){
    this.documentService.saveModalInfoData( { Number: file.Id } );
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,
      { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  isOnlyHasDocumentFeature() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      return true;
    else
      return false;
  }

  viewVersionHistory(file) {
    const modalRef = this.modal.open(DocumentVersioningModalComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.file = file;
    modalRef.result.then((result) => {
      if (result == 'Success'){
        this.getAllFilesByType();
      }
    });
  }

  IsNull(data: any){
    return this.mainService.IsNull(data);
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
        let labelIndices = [0, 2, 3, 4, 5];
        labelIndices.forEach((index, i) => {
            let labelName = x[index]?.LabelName;
            if (!this.mainService.IsNull(labelName)) {
                this.searchOptions[i].Name = labelName;
            }
        });
    });
  }

  formattedVersionNo(versionNo: number) {
    let VersionNo = Number.isInteger(versionNo) ? `${versionNo}.0` : versionNo;
    return VersionNo;
  }

}
