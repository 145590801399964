<app-dashboard>
    <div>
        <app-page-header-title mainTitle="Manual Journal" subTitle="Manual Journal List" backURL="/manual-journal/manual-journal-list/all">
            <div class="full-width text-align-right">
                <button type="button" *ngIf="manualJournalData.IsDraft" [routerLink]="['/manual-journal/manual-journal-edit/', manualJournalData.Id]" class="eCount-def-btn ml-2">Edit</button>
                <button type="button" *ngIf="manualJournalData.IsDraft" (click)="postManualJournal()" class="eCount-def-btn ml-4">Post</button>
                <button type="button" *ngIf="manualJournalData.IsDraft" style="background: red;" (click)="deleteManualJournal()" class="eCount-def-btn ml-4">Delete</button>
            </div>
        </app-page-header-title>
        <div class="row">
            <div class="col-md-12 d-flex f-ss-14px">
                <div class="col-md-2 d-block pl-1">
                    <p class="ToHeading">Narration</p>
                    <div>{{manualJournalData.Narration}}</div>
                </div>
                <div class="col-md-2">
                    <p class="ToHeading">Date</p>
                    <div>{{convertDateIntoDisplay(manualJournalData.manualJournalDate)}}</div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row f-ss-14px">
            <div class="col-md-12 invoice-grid">
                <table class="w-100" border="1|0">
                    <thead>
                        <tr>
                            <td>Description</td>
                            <td>Account</td>
                            <td class="text-right">Debit</td>
                            <td class="text-right">Credit</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of manualJournalData.ManualJournalItems">
                            <td>{{item.ItemDescription}}</td>
                            <td class="editable-cell">{{item.AccountName}}</td>
                            <td class="text-right">{{item.DebitAmount}}</td>
                            <td class="text-right">{{item.CreditAmount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row f-ss-14px">
            <div class="container-bottom d-flex w-100">
                <div class="col-md-8 col-sm-12">
                    <span></span>
                </div>
                <div class="col-md-4 col-sm-12 p-0 d-block">
                    <div class="d-flex w-100">
                        <div class="col-md-4">
                            <label>Subtotal</label>
                        </div>
                        <div class="col-md-4 float-right">
                            <p class="text-center mr-3">{{manualJournalData.SubTotalAmount}}</p>
                        </div>
                        <div class="col-md-4 float-right">
                            <p class="text-center pr-3">{{manualJournalData.SubTotalAmount}}</p>
                        </div>
                    </div>
                    <div class="d-flex w-100 equalLine">
                        <span></span>
                    </div>
                    <div class="d-flex w-100 mt-3">
                        <div class="col-md-4">
                            <label>Total</label>
                        </div>
                        <div class="col-md-4 float-right">
                            <p class="text-center mr-3 ToHeading">{{manualJournalData.SubTotalAmount}}</p>
                        </div>
                        <div class="col-md-4 float-right">
                            <p class="text-center pr-3 ToHeading">{{manualJournalData.SubTotalAmount}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-dashboard>