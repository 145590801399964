<app-dashboard>
    <app-page-header-title mainTitle="Business Partner Report" subTitle="{{'Contacts.contactList.allContacts'| translate}}" backURL="contacts/list/all">
        <div class="full-width text-align-right"></div>
    </app-page-header-title>
    <div class="row">
        <div class="col-md-9 d-flex">
            <div class="form-group p-all-0px col-md-2">
                <input class="form-control" type="date" placeholder="From Date" [(ngModel)]="fromDate" data-date-format="dd-MM-yyyy">
            </div>
            <div class="form-group p-all-0px col-md-2 ml-2">
                <input class="form-control" type="date" [(ngModel)]="toDate">
            </div>
            <div class="col-md-3">
                <button type="button" class="eCount-def-btn ml-4" (click)="getBSReportByContactId()">Generate Report</button>
            </div>
        </div>
        <div class="col-md-3 ml-auto">
            <div class="card m-0 p-0">
                <div class="card-body m-0 p-0">
                    <h6 class="title ml-3 mt-2" *ngIf="contactData?.IsSupplier">Bills you need to pay &gt;</h6>
                    <h6 class="title ml-3 mt-2" *ngIf="contactData?.IsCustomer">Invoices due to you &gt;</h6>
                    <h5 class="sub-title ml-3">{{ getDueBalance() }}</h5>
                </div>
            </div>
        </div>
    </div>
    <div id="bsReport">
        <div class="report-header row ml-2 mt-2">
            <div class="col-md-9">
                <div>
                    <p class="txtsize">Customer Deposit History</p>
                </div>
                <div class="row ml-0 mb-0">
                    <span>{{ contactData?.CompanyName }}</span>
                    <p>
                        <span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{ convertDateIntoDisplay(fromDate)}} to {{convertDateIntoDisplay(toDate) }}
                    </p>
                </div>
            </div>
            <div class="col-md-3 mt-0 mb-0" data-html2canvas-ignore="true">
                <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Company Name : </span>
                    <span class="font-weight-normal">{{ contactData?.CompanyName }}</span>
                </p>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Name : </span>
                    <span class="font-weight-normal">{{ contactData?.PrimaryPersonFullName }}</span>
                </p>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Website : </span>
                    <span class="font-weight-normal">{{ contactData?.Website }}</span>
                </p>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Email : </span>
                    <span class="font-weight-normal">{{ contactData?.Email }}</span>
                </p>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Contact No : </span>
                    <span class="font-weight-normal">{{ contactData?.ContactNo }}</span>
                </p>
            </div>
            <div class="col-md-4 mt-0">
                <p>
                    <span class="font-weight-bold">Address : </span>
                    <span class="font-weight-normal"> {{ contactData?.StreetAddress }} </span>
                </p>
            </div>
        </div>
        <div class="sticky">
            <div id="tableHeader" class="tableHeader d-flex">
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Date</p>
                </div>
                <div class="tableHeaderColumn col-md-3">
                    <p class="tableHeaderText pt-1">Type</p>
                </div>
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Description</p>
                </div>
                <div class="tableHeaderColumn col-md-2 ">
                    <p class="tableHeaderText pt-1">Reference</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Amount</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Balance(Due)</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1" *ngIf="contactData?.IsSupplier">Paid</p>
                    <p class="tableHeaderText pt-1" *ngIf="contactData?.IsCustomer">Deposit(Paid)</p>
                </div>
            </div>
        </div>
        <div class="d-flex f-ss-14px bottom pt-1 pb-1">
            <div class="tableHeaderColumn2 col-md-10">
                <span class="font-weight-bold">Opening Balance</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 ml-2">
                <span class="float-right runningBalanceText">{{ openingBalance }}</span>
            </div>
        </div>
        <div class="d-flex f-ss-14px bottom pt-1 pb-1" *ngFor="let itemInner of bsData">
            <div class="tableHeaderColumn2 col-md-2">
                <span>{{ itemInner.CreatedAt }}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-3">
                <span class="tableHeaderText">{{ itemInner.Type }}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 ml-2" [ngSwitch]="itemInner.TypeId">
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="3">{{ 'INV-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="4">{{ 'Bill-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="7">{{ 'INV-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="8">{{ 'Bill-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="9">{{ 'Bill-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchCase="10">{{ 'INV-'+itemInner.ReferenceNo }}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)" *ngSwitchDefault>{{ itemInner.ReferenceNo }}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1 text-blue">
                <span class="float-right runningBalanceText">{{ itemInner.BalanceView }}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1" [ngSwitch]="itemInner.TypeId">
                <span class="float-right" *ngSwitchCase="3">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchCase="4">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchCase="5">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchCase="6">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchCase="7">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchCase="8">{{ itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-' }}</span>
                <span class="float-right" *ngSwitchDefault> - </span>
            </div>
        </div>
        <div class="d-flex f-ss-14px bottom pt-1 pb-1">
            <div class="tableHeaderColumn2 col-md-10">
                <span class="font-weight-bold">Total</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 ml-2">
                <span class="float-right runningBalanceText">{{ currencyFormatter(totalPaid, '') }}</span>
            </div>
        </div>
    </div>
</app-dashboard>