import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BusinessPartnerLoanService } from "src/app/service/Business-partner-loan/business-partner-loan.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment = require("moment");
import { iQuidiActionType } from "src/app/enum/iquidiActionType";

@Component({
  selector: "app-bp-loan-create",
  templateUrl: "./bp-loan-create.component.html",
  styleUrls: ["./bp-loan-create.component.css"],
})

export class BpLoanCreateComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private loanService: BusinessPartnerLoanService,
    private spinner: NgxSpinnerService
  ) {}

  public bpLoanForm: FormGroup;
  bankList: any;
  allCustomer: any;
  subTotalAmount = 0;
  ifSubmit = false;
  @Input() fromParent;

  ngOnInit(): void {
    this.initializeForm();
    this.getAllSupplier();
    this.getBankList();
  }

  initializeForm() {
    this.bpLoanForm = this.fb.group({
      ContactId: [""],
      Description: ["", Validators.required],
      AccountId: ["", Validators.required],
      Date: [""],
      Amount: ["", Validators.required],
      TotalAmount: [0],
      LoanPeriod: [0, Validators.required],
      InterestAmount: [0],
      LoanTypeId: [this.fromParent, Validators.required],
      LoanTypeName: [ this.fromParent == 1 ? "Grant Loan" : "Receive Loan", Validators.required ],
      InterestRate: [0],
      FixedRate: [0],
      IsFixed: [false],
      Status: [0],
      iQuidiActionType: [0],
    });
    this.bpLoanForm.get("Date").patchValue(new Date());
    this.showSelectedDate(new Date());
  }

  showSelectedDate(e) {
    const value = this.bpLoanForm.value.Date;
    $("#oltest1").on("change", function () {
      this.setAttribute("data-date", moment(value, "YYYY-MM-DD").format(this.getAttribute("data-date-format")));
    }).trigger("change");
  }

  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }

  changeFixedRateOrInterest = () => {
    if (this.bpLoanForm.value.IsFixed === true) {
      this.bpLoanForm.get("InterestRate").patchValue("");
    } else {
      this.bpLoanForm.get("FixedRate").patchValue("");
    }
    this.updateTotalPrice();
  }

  updateTotalPrice = () => {
    if (this.bpLoanForm.value.IsFixed === true) {
      const amount = this.bpLoanForm.value.Amount;
      const value = parseFloat( amount === "" || isNaN(amount) || amount == null ? 0 : this.bpLoanForm.value.Amount);
      const fixedRate = this.bpLoanForm.value.FixedRate ?? 0;
      const totalValue = fixedRate + value;
      this.bpLoanForm.get("InterestAmount").patchValue(fixedRate);
      this.bpLoanForm.get("TotalAmount").patchValue(totalValue);
    } else {
      const amount = this.bpLoanForm.value.Amount;
      const value = parseFloat(amount === "" || isNaN(amount) || amount == null ? 0 : this.bpLoanForm.value.Amount);
      let percantCalculate = (value * (this.bpLoanForm.value.InterestRate ?? 0)) / 100;
      percantCalculate = isNaN(percantCalculate) ? 0 : percantCalculate;
      this.bpLoanForm.get("InterestAmount").patchValue(percantCalculate);
      this.bpLoanForm.get("TotalAmount").patchValue(value + percantCalculate);
    }
  }

  getAllSupplier = () => {
    this.loanService.GetAllContact().subscribe((x: any) => {
      this.allCustomer = x;
    });
  }

  getBankList() {
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.bankList = x;
    });
  }

  save = (buttonPressed) => {
    if (this.bpLoanForm.invalid) {
      this.toaster.warning("Please fill all the required field!");
      return;
    }
    if (this.mainService.IsNull(this.bpLoanForm.value.InterestAmount)) {
      this.toaster.warning("Interest Amount can not be empty!");
      return;
    }
    this.spinner.show();

    if (buttonPressed == iQuidiActionType.Save) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Save);
    if (buttonPressed == iQuidiActionType.Submit) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Submit);
    if (buttonPressed == iQuidiActionType.Approve) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Approve);
    this.loanService.Save(this.bpLoanForm.value).subscribe(
      (x) => {
        if (x.Success) {
          this.spinner.hide();
          this.toaster.success("Loan added successfully");
          this.activeModal.close(x);
          this.router.navigate([ this.fromParent == 1 ? "/business-partner/grant-loan-list/All" : "/business-partner/receive-loan-list/All" ]);
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }

}
