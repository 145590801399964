import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessPartnerLoanService } from 'src/app/service/Business-partner-loan/business-partner-loan.service';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import moment = require('moment');
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-business-partner-loan-edit',
  templateUrl: './business-partner-loan-edit.component.html',
  styleUrls: ['./business-partner-loan-edit.component.css']
})
export class BusinessPartnerLoanEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private loanService: BusinessPartnerLoanService,
    private spinner: NgxSpinnerService,
  ) { }
  public bpLoanForm: FormGroup;
  bankList: any;
  allCustomer: any;
  subTotalAmount = 0;
  ifSubmit = false;
  @Input() id;
  @Input() fromParent; //LoanTypeId (GrantLoan, ReceiveLoan)

  loanModel: any = {};
  url: any;
  IfSettle = false;
  bpLoanData: any;
  ngOnInit(): void {
    this.initializeForm();
    this.getAllSupplier();
    this.getBankList();
    this.getBPLoanData();
  }
  
  initializeForm() {
    this.bpLoanForm = this.fb.group({
      Id:[0],
      ContactId: [''],
      Description: ['',  Validators.required],
      AccountId: ['', Validators.required],
      Date: [''],
      CreatedAt: [''],
      CreatedById: [''],
      LoanDateId: [''],
      SettleAmount: [0],
      Amount: ['', Validators.required],
      TotalAmount: [0],
      LoanPeriod: [0, Validators.required],
      InterestAmount: [0],
      LoanTypeId: [this.fromParent, Validators.required],
      InterestRate: [0],
      FixedRate: [0],
      IsFixed: [false],
      Status: [0],
      iQuidiActionType: [0]
    });
    this.bpLoanForm.get('Date').patchValue(new Date());
    this.showSelectedDate(new Date());
  }

  patchAllValues(){
    this.bpLoanForm.controls['Id'].patchValue(this.bpLoanData.Id);
    this.bpLoanForm.controls['ContactId'].patchValue(this.bpLoanData.ContactId);
    this.bpLoanForm.controls['Description'].patchValue(this.bpLoanData.Description);
    this.bpLoanForm.controls['AccountId'].patchValue(this.bpLoanData.AccountId);
    this.bpLoanForm.controls['Date'].patchValue(this.bpLoanData.Date);

    this.bpLoanForm.controls['CreatedAt'].patchValue(this.bpLoanData.CreatedAt);
    this.bpLoanForm.controls['CreatedById'].patchValue(this.bpLoanData.CreatedById);
    this.bpLoanForm.controls['LoanDateId'].patchValue(this.bpLoanData.LoanDateId);
    this.bpLoanForm.controls['SettleAmount'].patchValue(this.bpLoanData.SettleAmount);

    this.bpLoanForm.controls['Amount'].patchValue(this.bpLoanData.Amount);
    this.bpLoanForm.controls['TotalAmount'].patchValue(this.bpLoanData.TotalAmount);
    this.bpLoanForm.controls['LoanPeriod'].patchValue(this.bpLoanData.LoanPeriod);
    this.bpLoanForm.controls['InterestAmount'].patchValue(this.bpLoanData.InterestAmount);
    this.bpLoanForm.controls['LoanTypeId'].patchValue(this.bpLoanData.LoanTypeId);
    this.bpLoanForm.controls['InterestRate'].patchValue(this.mainService.IsNull(this.bpLoanData.InterestRate) ? '' : this.bpLoanData.InterestRate);
    this.bpLoanForm.controls['FixedRate'].patchValue(this.mainService.IsNull(this.bpLoanData.InterestRate) ? this.bpLoanData.InterestAmount : '');
    this.bpLoanForm.controls['IsFixed'].patchValue(this.mainService.IsNull(this.bpLoanData.InterestRate) ? true : false);
    this.bpLoanForm.controls['Status'].patchValue(this.bpLoanData.Status);
    //this.bpLoanForm.controls['iQuidiActionType'].patchValue();
  }

  showSelectedDate(e) {
    const value = this.bpLoanForm.value.Date;
    $('#oltest1').on('change', function() {
      this.setAttribute(
          'data-date',
          moment(value, 'YYYY-MM-DD')
          .format( this.getAttribute('data-date-format') )
    );
  }).trigger('change');
  }

  getBPLoanData = () => {
    this.spinner.show();
    this.loanService.GetGrantLoanbyId(this.id).subscribe((x: any) => {
      this.loanModel = x.result;
      this.IfSettle = (this.loanModel.TotalAmount - this.loanModel.SettleAmount) <= 0 ? true : false;
      this.url = this.loanModel.LoanTypeId === 1 ?
      'business-partner/grant-loan-list/active' : 'business-partner/receive-loan-list/active';
      this.bpLoanData = x.result;
      this.patchAllValues();
      this.spinner.hide();
    });
  }

  onFocusEvent(event: any)
  {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }
  changeFixedRateOrInterest = () =>
  {
    if (this.bpLoanForm.value.IsFixed === true)
    {
      this.bpLoanForm.get('InterestRate').patchValue('');
    }
    else
    {
      this.bpLoanForm.get('FixedRate').patchValue('');
    }
    this.updateTotalPrice();
  }

  updateTotalPrice = () =>
  {
    if (this.bpLoanForm.value.IsFixed === true)
    {
      const amount = this.bpLoanForm.value.Amount;
      console.log(amount);
      const value = parseFloat((amount === '' || isNaN(amount) || amount == null ) ? 0 : this.bpLoanForm.value.Amount);
      const fixedRate = this.bpLoanForm.value.FixedRate ?? 0;
      const totalValue = fixedRate + value;
      this.bpLoanForm.get('InterestAmount').patchValue(fixedRate);
      this.bpLoanForm.get('TotalAmount').patchValue(totalValue);
    }
    else
    {
      const amount = this.bpLoanForm.value.Amount;
      console.log(amount);
      const value = parseFloat((amount === '' || isNaN(amount) || amount == null) ? 0 : this.bpLoanForm.value.Amount );
      let percantCalculate =  (value * (this.bpLoanForm.value.InterestRate ?? 0)) / 100;
      percantCalculate = isNaN(percantCalculate) ? 0 : percantCalculate;
      this.bpLoanForm.get('InterestAmount').patchValue(percantCalculate);
      this.bpLoanForm.get('TotalAmount').patchValue(value + percantCalculate );
    }
  }
  getAllSupplier = () => {
    this.loanService.GetAllContact().subscribe((x: any) => {
      this.allCustomer = x;
    });
  }
  getBankList()
  {
      this.bankList = [];
      this.mainService.GetAllBank().subscribe((x) => {
        this.bankList = x;
    });
  }
 save = (buttonPressed) => {
  if (this.bpLoanForm.invalid) {
    this.toaster.warning('Please fill all the required field !');
    return;
  }
  if (this.bpLoanForm.value.InterestAmount === '' || this.bpLoanForm.value.InterestAmount === undefined ||
   this.bpLoanForm.value.InterestAmount == null) {
    this.toaster.warning('Interest Amount can not be empty !');
    return;
  }
  this.spinner.show();

  if(buttonPressed == iQuidiActionType.Save) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Save);
  if(buttonPressed == iQuidiActionType.Submit) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Submit);
  if(buttonPressed == iQuidiActionType.Approve) this.bpLoanForm.controls.iQuidiActionType.patchValue(iQuidiActionType.Approve);
  this.loanService.Update(this.bpLoanForm.value).subscribe((x) => {
    if (x.Success)
    {
      this.spinner.hide();
      this.toaster.success('Loan Updated Successfully');
      this.activeModal.close(x);
      location.reload();
    }
    else
    {
      this.spinner.hide();
    }
  } , err => {
    this.spinner.hide();
    this.toaster.error('ooh, something went wrong !');
  });
 }
}
