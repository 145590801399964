<app-dashboard>
    <app-page-header-title mainTitle="Edit Manual Journal" subTitle="Manual Journal List" backURL="/manual-journal/manual-journal-list/all">
        <div class="full-width text-align-right">
            <div class="row">
                <div class="col-sm-12">
                    <div class="dropdown">
                        <button id="attachment_btn " type="button" style="z-index: 1;" class="btn attachment-button">
                            <i id="attachment_btn" style="font-size: 20px !important;" class="material-icons">insert_drive_file</i>
                            <span id="attachment_btn" style="color:darkgrey; font-size: 12px; " *ngIf="totalFile >= 1">{{totalFile}}</span>
                        </button>
                        <div class="x-tip-tl" style="height: 0px;">
                            <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                        </div>
                        <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                            <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="2"></app-attachment-uploader>
                        </div>
                    </div>
                    <button type="button" (click)="saveManualJournal()" class="eCount-def-btn ml-4">Save</button>
                    <button type="button" (click)="postManualJournal()" class="eCount-def-btn ml-4">Post</button>
                </div>
            </div>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <form [formGroup]="ManualJournalForm">
            <div class="row">
                <div class="col-md-4">
                    <label>Narration</label>
                    <input class="form-controlinputBox" type="text" formControlName="Narration" (change)="changeItemsDescription()">
                </div>
                <div class="col-md-2">
                    <label>Date</label>
                    <input class="form-controlDate" type="date" formControlName="ManualJournalDate" id="smtest" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                </div>
                <div class="col-md-7 mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="changeDefaultDescriptionSettings()" formControlName="DefaultDescriptionSettings">
                        <label class="form-check-label checkbox-margin-top" for="flexCheckDefault">Default narration to journal line description</label>
                    </div>
                </div>
            </div>
            <div class="row d-block topSection">
                <div class="d-flex">
                    <div class="col-md-4"> Description <span class="clr">*</span></div>
                    <div class="col-md-3"> Account </div>
                    <div class="col-md-2 text-center"> Debit </div>
                    <div class="col-md-2 text-center"> Credit </div>
                    <div class="col-md-1"></div>
                </div>
                <hr class="pb-0 mb-0" />
                <div>
                    <div formArrayName="ManualJournalItems" *ngFor="let item of ManualJournalForm.get('ManualJournalItems')['controls']; let i = index;">
                        <div class="invoiceGridItemRow d-block" [formGroupName]="i">
                            <div class="d-flex mt-1">
                                <div class="col-md-4 borderLeft ">
                                    <input class="form-control" type="text" formControlName="ItemDescription">
                                </div>
                                <div class="col-md-3 borderLeft ">
                                    <div style="width: 100%;">
                                        <ng-select [items]="allChartOfAccount" placeholder="Select Account" class="account-border" [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId" groupBy="GroupName"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-2 borderLeft rowSpaceing">
                                    <input class="form-control text-center" type="text" formControlName="DebitAmount" (change)="updateItems()" appDecimaNumericValidator>
                                </div>
                                <div class="col-md-2 borderLeft rowSpaceing">
                                    <input class="form-control text-center" type="text" formControlName="CreditAmount" (change)="updateItems()" appDecimaNumericValidator>
                                </div>
                                <div class="col-md-1 rowSpaceing d-flex">
                                    <div style="padding: 7px 10px; color: #c7c2c2;">
                                        <div class="dropdown mr-4">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                <i class="icon-more float-right"></i>
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                                <div class="productRow pb-3" (click)="removeItems(i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line 
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container-bottom d-flex">
                    <div class="col-md-6 col-sm-12">
                        <span></span>
                    </div>
                    <div class="col-md-5 col-sm-12 p-0 d-block ml-4">
                        <div class="d-flex w-100">
                            <div class="col-md-3">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4 ml-4">{{debitSubTotal.toFixed(2)| currency:'':''}}</div>
                            <div class="col-md-3 ml-4">{{creditSubTotal.toFixed(2)| currency:'':''}}</div>
                        </div>
                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-3">
                            <div class="col-md-3">
                                <label>Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold ml-4">{{debitTotal.toFixed(2)| currency:'':''}}</div>
                            <div class="col-md-3 font-weight-bold ml-4">{{creditTotal.toFixed(2)| currency:'':''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>