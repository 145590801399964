import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrderDetailsPageViewModel } from 'src/app/models-viewModels/manufactureOrderDetailsPageViewModel';
import { ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureOrder, ManufactureOrderDetails } from 'src/app/models/manufacture.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-manufacutre-order-details',
  templateUrl: './manufacutre-order-details.component.html',
  styleUrls: ['./manufacutre-order-details.component.css']
})
export class ManufacutreOrderDetailsComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;

  manufactureOrderId: number;
  manufactureOrderData: ManufactureOrderDetailsPageViewModel;
  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>();

  totalOrderItemsConsume: number = 0;
  totalMiscellaneousItemsConsume: number = 0;

  constructor(
    private route: ActivatedRoute,
    private manufactureService: ManufactureService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder,
    private mainService: MainServiceService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let no = +params['id'];
      this.manufactureOrderId = no;
      this.getOrder();
   });
   this.initializeForm();
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: [''],
      FormulaName: [''],
      Quantity: [''],
      CreatedDate: [''],
      StartDate: [''],
      EndDate: [''],
      Reference: [''],
      BatchNo: [''],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([])
    });
    this.ManufactureOrderForm.get('CreatedDate').patchValue(new Date());
  }

  getOrder() {
    this.manufactureService.getOrder(this.manufactureOrderId).subscribe(orderData => {
        this.manufactureOrderData = orderData;
        this.itemMap = new Map(orderData.ItemDetails.map(item => [item.Id, item]));
        this.setParentData(orderData);

        const { ManufactureOrderDetails } = orderData.ManufactureOrder;
        ManufactureOrderDetails.forEach((item, i) => {
            if (item.ItemType === ManufactureItemType.NORMAL) {
                this.totalOrderItemsConsume += item.TotalConsumed;
                this.addItem(item, i);
            } else if (item.ItemType === ManufactureItemType.MISCELLANEOUS) {
                this.totalMiscellaneousItemsConsume += item.TotalConsumed;
                this.addMiscellaneousItem(item, i);
            }
        });
    });
  }


  setParentData(orderData) {
    const { ManufactureOrder, ManufactureFormula } = orderData;
    const formatDate = (date) => moment(date).format("Do MMM YYYY");
    const formValues = {
        Id: ManufactureOrder.Id,
        FinishedProductId: ManufactureOrder.Item.ItemCode,
        FormulaName: ManufactureFormula?.FormulaName,
        Quantity: ManufactureOrder.ProductionQuantity,
        CreatedDate: formatDate(ManufactureOrder.CreatedDate),
        StartDate: formatDate(ManufactureOrder.StartDate),
        EndDate: formatDate(ManufactureOrder.EndDate),
        Reference: ManufactureOrder.Reference,
        BatchNo: ManufactureOrder.BatchNo
    };
    this.ManufactureOrderForm.patchValue(formValues);
  }


  addItem(item: ManufactureOrderDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      RawMaterialId: [item.Item.ItemName],
      RecipeQuantity: [this.getItemRecipeQuantity(item).toFixed(2)],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.UsedItem]
    });
  }

  getItemRecipeQuantity(item: ManufactureOrderDetails) {
    let formulaQuantity = this.manufactureOrderData.ManufactureFormula.Quantity;
    let itemQuantity = this.manufactureOrderData.ManufactureFormula.ManufactureFormulaDetails.filter(x => x.ItemId == item.ItemId)[0].Quantity;
    let recipeQuantity = itemQuantity / formulaQuantity;
    return recipeQuantity;
  }

  getItemBalance(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.UsedItem <= stockQuantity ? item.UsedItem : stockQuantity;
  }

  addMiscellaneousItem(item: ManufactureOrderDetails, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItem(item, i));
  }

  createMiscellaneousItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      MiscellaneousRawMaterialId: [item.Item.ItemName],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.UsedItem]
    });
  }

  editManufactureOrder(){
    this.router.navigate(["manufacture/edit-manufacture-order", this.manufactureOrderId]);
  }

  approveManufactureOrder() {
    if(this.manufactureOrderData.ManufactureOrder.ManufactureFormulaId == null){
      this.toaster.warning('Please add a formula!');
      return;
    }
    let data: ManufactureOrder = this.formatData();
    this.spinner.show();
    this.manufactureService.approveManufactureOrder(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  formatData(): ManufactureOrder {
    const { ManufactureOrder } = this.manufactureOrderData;
    ManufactureOrder.Item = null;
    ManufactureOrder.ManufactureFormula = null;
    ManufactureOrder.Status = this.getManufactureOrderStatus(ManufactureOrder.ManufactureOrderDetails);

    ManufactureOrder.ManufactureOrderDetails.forEach(item => { item.Item = null });
    return ManufactureOrder;
}


  getManufactureOrderStatus(manufactureOrderDetails: ManufactureOrderDetails[]) {
    let status = ManufactureOrderStatus.TO_BE_PROCESSED;
    manufactureOrderDetails.forEach((element) => {
      let stockQuantity = this.itemMap.get(element.ItemId).StockQuantity;
      if(element.UsedItem > stockQuantity){
        status =  ManufactureOrderStatus.WAITING_AFTER_APPROVED;
        return status;
      }
    });
    return status;
  }

  deleteManufactureOrder(){
    this.spinner.show();
    let data: ManufactureOrder = this.formatData();
    this.manufactureService.deleteManufactureOrder(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  produceManufactureOrder() {
    this.router.navigate(["manufacture/produce-manufacutring-order", this.manufactureOrderData.ManufactureOrder.Id]);
  }

  canShowOrderItems() {
    let hasOrderItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureFormulaId != null;
    return hasOrderItems;
  }

  canShowMiscellaneousItems() {
    let hasMiscellaneousItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0;
    return hasMiscellaneousItems;
  }

  canEditManufactureOrder() {
    let canEdit = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canEdit;
  }

  canApproveManufactureOrder() {
    let canApprove = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canApprove;
  }

  canDeleteManufactureOrder() {
    let canDelete = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canDelete;
  }

  canProduceManufactureOrder() {
    let canProduce = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.TO_BE_PROCESSED || this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED;
    return canProduce;
  }

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = 'MO-'+this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderNo+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    const statusMap = {
      [ManufactureOrderStatus.WAITING]: 'Draft',
      [ManufactureOrderStatus.WAITING_AFTER_APPROVED]: 'Waiting',
      [ManufactureOrderStatus.TO_BE_PROCESSED]: 'To Process',
      [ManufactureOrderStatus.PROCESSED]: 'Produced'
    };
    return statusMap[this.manufactureOrderData?.ManufactureOrder?.Status] || '';
  }

  currencyFormatter(currency, sign) {
    if(this.mainService.IsNull(currency)){
      return '';
    }
    else{
      var sansDec = currency.toFixed(2);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return sign + `${formatted}`;
    }
  }

}
