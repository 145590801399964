import { Component, OnInit } from '@angular/core';
import {  TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { MainServiceService } from "../../service/Main/main-service.service"
import { MessageManagerService } from "../../service/MessageManager/messagemanager.service"
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { SubscriptionService } from 'src/app/service/Subscription/subscription.service';

declare var $: any;
@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.css'],
  providers: [DatePipe]
})

export class HeaderComponentComponent implements OnInit {
  faCoffee = faBars;
  SelectedCompany: any
  contactList: any = []
  CompanyList: any = []
  userList: any=[]
  UserName:string='';
  days: number;
  url = '';
  show: boolean = false;
  selectedLanguage = 'en';
  messageData: any = {};
  userInfo: any;

  constructor(public authService: AuthService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private mainService: MainServiceService,
    private dataService: DataService,
    private messageManagerService:MessageManagerService,
    private toastr : ToastrService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.dataService.organizationUpdate.subscribe(() => {
      this.getSelectedLanguage();
      this.initialiseOrganization();
      this.userInfo = this.authService.getUserInfo();
    });
    this.dataService.setMessageUpdate.subscribe(x => {
      this.messageData = x;
    });
    this.dataService.messageUpdate.subscribe(x => {
      this.getMessage();
    });
    this.dataService.updateLastStep.subscribe(x => {
      this.CompanyList = x;
    });
    this.initialiseOrganization();
  }

  getSelectedLanguage = () => {
    this.selectedLanguage = localStorage ? localStorage['language'] : null;
  }

  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }

  public UpdateStep() {
    this.messageManagerService.StepDone().subscribe(x => {
      this.router.navigate([this.messageData.RedirectUrl], { queryParams: { step: 1 }  });
      if (x.AllDone){
        var userInfo = this.authService.getUserInfo();
        userInfo.CompanyList=x.Data;
        this.CompanyList=x.Data;
        this.authService.setUserInfo(JSON.stringify(userInfo));
      }
      this.getMessage();
    });
  }

  private getMessage(){
    this.spinner.show();
    this.messageManagerService.GetMessage().subscribe(x => {
      this.messageData = x;
      this.dataService.emitSetWarningPopup(this.messageData);
      this.spinner.hide();
      if (!x.StepComplete){
        this.url = x.Message.match(/'([^']+)'/)[1];
        this.url = this.url.replace('/#' , '');
        this.router.navigate([this.url], { queryParams: { step: 1 }  });
      }
      if(x.StepComplete && (x.ExpiredInDays * (-1) > 30)){
        this.router.navigate(['/']);
      }
      else if (x.ExpiredInDays < 0){
        this.subscriptionReactivate();
      }
    });
  }

  subscriptionReactivate(){
    const userInfo = this.authService.getUserInfo();
    const companyInfo = {
      CompanyName: userInfo.SelectedCompany.Name.toString(),
      CompanyId: userInfo.SelectedCompany.CompanyId
    }
    this.subscriptionService.GetSubscriberByCompany(companyInfo).subscribe((x) => {
      let hasRenewablePackage = x.filter( y => y.IsActive == false && y.IsExpired == false).length > 0 ? true : false;
      if(hasRenewablePackage) this.ReActivateSubscription();
    });
  }

  GoToSubscription(){
    this.router.navigate(["subscription/manage-subscription"]);
  }

  GoToRenewSubscription(){
    this.router.navigate(["subscription-renew/subscription-renew"]);
  }

  GoToDashBoard(){
    this.router.navigate(["/"]);
  }

  ReActivateSubscription(){
    this.mainService.ReActivateSubscription().subscribe(x => {
      if( !x.Success ){
        this.GoToRenewSubscription();
      }else{
        this.messageManagerService.GetMessage().subscribe(x => {
          this.messageData = x;
          this.dataService.emitSetWarningPopup(this.messageData);
          this.spinner.hide();
          if (!x.StepComplete)
          {
            this.url = x.Message.match(/'([^']+)'/)[1];
            this.url = this.url.replace('/#' , '');
            this.router.navigate([this.url], { queryParams: { step: 1 }  });
          }
          else if(x.StepComplete && !(x.ExpiredInDays * (-1) > 30)){
            this.router.navigate(['/']);
          }
          else if (x.ExpiredInDays * (-1) > 30){
            this.GoToRenewSubscription();
          }

          let userInfo = this.authService.getUserInfo();
          userInfo.IsCompanySubscriptionExpired = false;
          this.authService.setUserInfo(JSON.stringify(userInfo));
          this.GoToDashBoard();
        });
      }
    });
  }


  parseDate(str) {
    var mdy = str.split('-');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
  }

  initialiseOrganization(){
    const userInfo = this.authService.getUserInfo();
    const list = userInfo ?  userInfo.CompanyList : [];
    if (list.length > 0){
      this.getMessage();
    }
    this.userList=[]
    this.getShortName(list);
    if (userInfo) {
      this.SelectedCompany = userInfo.SelectedCompany;
      this.UserName = userInfo.UserName;
      this.authService.getMyCompanyList().subscribe((x)=>{
        this.CompanyList = x;
      })
    }
    this.getLastLoginTime();
  }

  getShortName(list: any){
    let st = '';
    list.forEach(value => {
      value.Name.split('').forEach(v =>
        st += v[0].toString());
      if (st.length == 1 && value.Name.length > 1){
        this.userList.push(value.Name.substring(0, 2));
      }
      else{
        this.userList.push(st.substring(0, 2));
      }
      st = '';
    });
  }

  changeLanguage=(lang)=>{
    this.translate.use(lang);
    if (localStorage) {
      localStorage['language'] = lang;
    }
    this.getSelectedLanguage();
  }

  showHideSidebar=()=>{
    $("#wrapper").toggleClass("toggled");
  }

  logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('language');
    localStorage.setItem('language', this.selectedLanguage);
    this.dataService.emitDefaultNotificationUpdate(true);
    this.router.navigate(['/auth/login']);
  }

  selectOrganization(item){
    this.hideBar();
    const companyInfo = {
      CompanyName: item.Name.toString(),
      CompanyId: item.CompanyId
    }
    this.mainService.setSelectedOrganization(item.CompanyId).subscribe((x)=>{
      this.subscriptionService.GetSubscriberByCompany(companyInfo).subscribe((y) => {
        let subscriptionList = y.filter(sb => sb.IsActive == true);
        let isGracePeriodExpired = y[0]?.IsGracePeriodExpired;
        let lastLoginTime = y.filter( lt => lt.CompanyId == item.CompanyId)[0]?.LastLoginTime;
        let isCompanyHasOnlyDocumentFeature = y.filter( lt => lt.CompanyId == item.CompanyId)[0]?.IsCompanyHasOnlyDocumentFeature;
        let roleId = y.filter( lt => lt.CompanyId == item.CompanyId)[0]?.RoleId;
        let companyBusinessTypeId = y.filter( lt => lt.CompanyId == item.CompanyId)[0]?.CompanyBusinessTypeId;
        this.SelectedCompany = item;
        this.SelectedCompany.CurrencyId = y.filter( lt => lt.CompanyId == item.CompanyId)[0]?.CurrencyId;
        let data = {
          Token: x.Token,
          SelectedCompany: this.SelectedCompany,
          CompanyList: this.CompanyList,
          UserName:this.UserName,
          IsCompanySubscriptionExpired: subscriptionList.length == 0 ? true : false,
          IsGracePeriodExpired: isGracePeriodExpired,
          LastLoginTime: lastLoginTime,
          IsCompanyHasOnlyDocumentFeature: isCompanyHasOnlyDocumentFeature,
          RoleId: roleId,
          CompanyBusinessTypeId: companyBusinessTypeId
        }
        this.authService.setUserInfo(JSON.stringify(data));
          this.getMessage();
          this.show=true;
          this.dataService.emitDefaultNotificationUpdate(true);
          // This portion added for when company is has only document feature
          this.dataService.emitsetSidebarUpdate(true);
          if(this.authService.IsCompanySubscriptionExpired()){
            this.router.navigate(['/subscription-renew/subscription-renew']);
            this.authService.openSubscriptionExpiredWarningModal();
          }
          else if(data.RoleId == 1)
            this.router.navigate(['/my-pending-list-dashboard']);
          else if(data.IsCompanyHasOnlyDocumentFeature)
            this.router.navigate(['/document/document-overview/draft']);
          else
            this.router.navigate(['/']);
        }, err=>{
          this.toastr.error("Error!","There are some error plase check!");
        })
      });  
  }

  hideBar=()=>{
    var element = document.getElementById("navBarTrigger1");
    element.classList.remove("show");
    var element = document.getElementById("navBarTrigger2");
    element.classList.remove("show");
  }
  
  public goUrl() {
    this.router.navigate(['subscription/manage-subscription']);
  }

  getLastLoginTime() {
    const userInfo =this.authService.getUserInfo();
    const lastLoginTime = userInfo?.LastLoginTime == null ? new Date() : userInfo?.LastLoginTime;
    return new Date(lastLoginTime).toUTCString();
  }

  getRedirectLink() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      this.router.navigate(['/document/document-overview/draft']);
    else
      this.router.navigate(['/']);
  }

}
