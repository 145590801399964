<app-dashboard>
    <app-page-header-title mainTitle="Active & Inactive" subTitle="Home" backURL="/" searchBox="{{ true }}">
        <div class="full-width text-align-right pb-2 d-flex justify-content-end">
            <ng-select [(ngModel)]="selectedSearchOption" (ngModelChange)="setSelectedSearchOption($event)" class="documentSearchOptions" [clearable]="false">
                <ng-option *ngFor="let option of searchOptions" [value]="option.Id">{{ option.Name }}</ng-option>
            </ng-select>
            <div class="autocomplete">
                <input type="text" placeholder="search" (keyup)="updateFilter($event)" (input)="onSearch($event.target.value)" class="documentSearchBox" />
                <ul class="unoder-table" *ngIf="showResults">
                    <li class="table-list" *ngFor="let result of filteredResults" (click)="onListItemClick(result)">{{ result }}</li>
                </ul>
            </div>
            <button type="button" *ngIf="status == 'active'" (click)="InActive()" class="eCount-def-btn ml-1">InActive</button>
            <button type="button" *ngIf="status == 'inactive'" (click)="Active()" class="eCount-def-btn ml-1">Active</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('active')" [routerLinkActive]="['active']" routerLink="/document/document-active-inactive-overview/active">Active ()</a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('inactive')" [routerLinkActive]="['active']" routerLink="/document/document-active-inactive-overview/inactive">Inactive ({{inactiveCount}})</a>
        </li>
    </ul>
    <div class="row" style="margin: 0px; padding: 20px 0px 0px 0px">
        <div *ngFor="let file of files" id="demo{{ file.BlobName }}" (mouseover)="mouseOver(file.BlobName)" (mouseout)="mouseOut(file.BlobName)" class="col-3 mdivpadding">
            <div id="hover{{ file.BlobName }}" class="hover{{ file.BlobName }} alls attachment-div">
                <div id="imagesview{{ file.BlobName }}" class="row images imagediv">
                    <div id="pdfPr{{ file.BlobName }}" *ngIf="isPdfFile(file)" class="pdfViewer">
                        <img src="../../../assets/main/images/Adobe-PDF-File-Icon-logo-vector-01.svg" />
                        <p style="margin: 0 auto; width: 100%; text-align: center;font-size: 13px;">{{ file.FileName }}</p>
                    </div>
                    <img *ngIf="!isPdfFile(file)" class="col-md-12" width="30%" style="border: none; margin: 0 auto" src="{{ fileUrl }}{{ file.BlobName }}" alt="..." class="img-thumbnail" />
                    <p style=" margin: 0 auto; width: 100%; text-align: center; font-size: 13px;">{{ file.FileName }}</p>
                </div>
                <div class="row" style="margin: 0px; padding: 20px">
                    <p style="width: 100%; color: black; margin: 0px; font-weight: bold">
                        <b>{{ file.DocumentTypeName }}</b>
                    </p>
                    <table *ngIf="!changeOnInventory" style="width: 100%; color: #7f7f7f; font-size: 13px">
                        <tr>
                            <!-- Employee Id works as a ReferenceNo -->
                            <td>{{ searchOptions[0]?.Name }}:</td>
                            <td>{{ file?.ReferenceNo }}</td>
                          </tr>
                          <tr *ngIf="!IsNull(file?.EmployeeId)"> 
                            <!-- tag works as an EmployeeId -->
                            <td>Tag:</td>
                            <td>{{ file.EmployeeId }}</td>
                          </tr>
                          <tr *ngIf="!IsNull(file?.Reference1)">
                            <td>{{ searchOptions[1]?.Name }}:</td>
                            <td>{{ file?.Reference1 }}</td>
                          </tr>
                          <tr *ngIf="!IsNull(file?.Reference2)">
                            <td>{{ searchOptions[2]?.Name }}:</td>
                            <td>{{ file?.Reference2 }}</td>
                          </tr>
                        <tr>
                            <td>Created Date :</td>
                            <td>{{ convertDateToDisplay(file.CreatedDate) }}</td>
                        </tr>
                        <tr>
                            <td>Created By :</td>
                            <td>{{ file?.UpdatedByName }}</td>
                        </tr>
                        <tr *ngIf="!IsNull(file?.VersionNo) && file?.TagType == 2">
                            <td>Version No :</td>
                            <td>{{ formattedVersionNo(file?.versionNo) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div id="options{{ file.BlobName }}" class="row hide fileoptions" style="margin: 0px">
                <div style="text-align: center; width: 100%">
                    <input class="form-check-input filled-in mt-0" type="checkbox" (click)="pressDocument(file)" style="height: 7%; width:6%;">
                    <a *ngIf="!file.IsInactive" class="option-icon-color ml-1 mt-2" (click)="view(file)">
                        <i class="material-icons-outlined">insert_drive_file</i>
                    </a>
                    <a *ngIf="!file.IsInactive" href="{{ fileUrl }}{{ file.BlobName }}" download="{{ file.BlobName }}" class="option-icon-color mt-2">
                        <i class="material-icons">file_download</i>
                    </a>
                    <a *ngIf="!file.IsInactive" (click)="print(file, null, isPdfFile(file))" class="option-icon-color">
                        <i class="material-icons-outlined">print</i>
                    </a>
                    <a *ngIf="!file.IsInactive" (click)="share(file)" class="option-icon-color" data-toggle="modal" data-target="#shareModal">
                        <i class="material-icons-outlined">share</i>
                    </a>
                    <a *ngIf="file.ApprovalStatus==3  && !file.IsInactive" (click)="documentModalView(file)">
                        <i class="material-icons approvedCheckIcon">check_box</i>
                    </a>
                    <a *ngIf="file.ApprovalStatus==4  && !file.IsInactive" (click)="documentModalView(file)">
                        <i class="material-icons rejectedBanIcon">block</i>
                    </a>
                    <a (click)="documentModalViewFullHistory(file)" *ngIf="(file.ApprovalStatus==1 || file.ApprovalStatus==2)  && !file.IsInactive">
                        <fa-icon [icon]="faInfoCircle" class="seeHistoryIcon"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" class="attachment-header">{{selected?.FileName}}</span>
            <hr class="notShow" />
            <div class="row pr-2 pl-2">
                <div class="col-md-12 notShow">
                    <div class="row">
                        <div class="col-md-4">
                            <table style="color: #7f7f7f; font-size: 13px">
                                <tr>
                                    <td>Created Date :</td>
                                    <td>{{ convertDateToDisplay(selected?.CreatedDate) }}</td>
                                </tr>
                                <tr>
                                    <td>Created By :</td>
                                    <td>{{ selected?.UpdatedByName }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-8" style="text-align: right">
                            <a>
                                <span class="option-icon-color" (click)="zoomIn()">
                                    <i class="material-icons borderradiusleft attachmenticon">zoom_in</i>
                                </span>
                            </a>
                            <a>
                                <span class="option-icon-color" (click)="zoomOut()">
                                    <i class="material-icons attachmenticon">zoom_out</i>
                                </span>
                            </a>
                            <a [hidden]="isPdf">
                                <span class="option-icon-color" (click)="rotate('left')">
                                    <i class="material-icons attachmenticon">rotate_left</i>
                                </span>
                            </a>
                            <a [hidden]="isPdf">
                                <span class="option-icon-color" (click)="rotate('right')">
                                    <i class="material-icons attachmenticon">rotate_right</i>
                                </span>
                            </a>
                            <a href="{{ fileUrl }}{{ selected?.BlobName }}" download="{{ selected?.BlobName }}" class="option-icon-color">
                                <i class="material-icons attachmenticon">file_download</i>
                            </a>
                            <a>
                                <span (click)="print(selected, 'contentToPrint', isPdf)" class="option-icon-color">
                                    <i class="material-icons-outlined attachmenticon">print</i>
                                </span>
                            </a>
                            <a>
                                <span (click)="share(selected)" class="option-icon-color" data-toggle="modal" data-target="#shareModal">
                                    <i class=" material-icons-outlined borderradiusright border-right attachmenticon">share</i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="contentToPrint" class="col-md-12">
                    <div [hidden]="!isPdf">
                        <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" style="display: block"></pdf-viewer>
                    </div>
                    <div [hidden]="isPdf">
                        <div style="overflow: overlay">
                            <img id="image" style="width: 100%" src="{{ src }}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
  
    <div class="modal fade" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="shareModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{ selected?.FileName }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="row pr-2 pl-2">
                        <div class="col-md-12">
                            <div style="width: 300px; margin: 0 auto; height: 200px; overflow: hidden;" [hidden]="!isPdf">
                                <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" style="display: block"></pdf-viewer>
                            </div>
                            <div style="width: 300px; margin: 0 auto" [hidden]="isPdf">
                                <div style="overflow: overlay">
                                    <img id="image" style="width: 100%" src="{{ src }}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div style="width: 100%; margin: 0 auto; text-align: center">
                            <input class="userSearch" type="text" placeholder="search" [(ngModel)]="searchText" />
                        </div>
                        <ul style=" list-style: none; width: 100%; padding: 0 18px; margin: 0px;">
                            <li class="row userlist" *ngFor="let user of users | filter: searchText">
                                <span class="col-md-12">{{ user?.FirstName }}
                                    <button (click)="shareFile(user.Id)" class="btn share-btn" style="float: right">share</button>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </app-dashboard>