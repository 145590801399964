<div class="xnav-banner" [hidden]="messageData.Message == null" id="infoShow">
  <div style="text-align: center;" class="xnav-banner--text-center xnav-banner--text" [hidden]="messageData.Message == null">
      <span [innerHTML]="messageData.Message"></span>
      <span *ngIf="messageData.OnlyRedirect" class="eCount-def-btn message-action" routerLink="{{ messageData.RedirectUrl }}">
          {{ messageData.ActionTitle }}
      </span>
      <span *ngIf="messageData.HasAction" (click)="UpdateStep()" class="eCount-def-btn message-action">
          {{ messageData.ActionTitle }}
      </span>
  </div>
</div>
<nav id="navMenu" class="navbar navbar-expand-lg navbar-light bg-light border-bottom headerBg">
  <div class="sidebar-heading sidebarHead d-flex" style="width: 256px;">
      <a (click)="getRedirectLink()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="logo">
          <img src="../../../assets/image/logo_dark.png" alt="" class="logos" />
      </a>
  </div>
  <div class="f-ss-14px collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="mt-2 ml-auto navbar-nav mt-lg-0">
          <li id="navBarTrigger1" class="nav-item dropdown blk">
              <p class="lastloginTimeText">Your last login: {{ getLastLoginTime() | date:'MMMM d, y, h:mm a' }}</p>
          </li>
          <li id="navBarTrigger1" class="nav-item dropdown blk">
              <a [hidden]="!SelectedCompany" style="
          background: #f9f9f9;
          border: 1px solid transparent;
          color: rgb(68, 51, 51) !important;
          border-radius: 8px;
        " class="nav-link dropdown-toggle organization" data-toggle="dropdown" href="#" aria-expanded="false" data-animation="scale-up" role="button">
                  <span>{{ SelectedCompany ? SelectedCompany.Name : null }}</span>
              </a>
              <div id="navBarTrigger2" [hidden]="!CompanyList" class="f-ss-14px dropdown-menu dropdown-menu-right dropdown-content wmin-md-300" role="menu">
                  <div id="sidebars" class="sidebar-scroll">
                      <a (click)="hideBar()" class="dropdown-item" href="javascript:void(0)" [routerLink]="['/settings']" role="menuitem">
                          <img class="settings-width" src="../../../assets/image/setting.png" /> Settings </a>
                      <a (click)="hideBar()" class="dropdown-item" href="javascript:void(0)" [routerLink]="['/settings/subscription-list']" role="menuitem">
                          <img class="wd" src="../../../assets/image/billing.png" /> Subscription and Bills </a>
                      <a (click)="hideBar()" class="pb-2 dropdown-item" href="javascript:void(0)" [routerLink]="['/organization/create']" role="menuitem">
                          <i class="ml-1 icon-atom2" aria-hidden="true"></i> Add New Organization </a>
                      <div class="dropdown-divider" role="presentation"></div>
                      <div class="pt-2 pb-1 dropdown-content-header pointer" style="border-bottom: 1px solid lightgray;" *ngFor="let item of CompanyList; let i = index">
                          <div class="selectedOrganization row" (click)="selectOrganization(item)">
                              <span class="nav-link navbar-avatar userNameNavbar rounded-circle">
                                  {{ userList[i] }}
                              </span>
                              <span class="mt-3 companyName">
                                  {{ item.Name ? item.Name : null }}
                                  <i *ngIf="!item.StepConfigured" class="icon-warning" style="color: orangered;"></i>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
          </li>
          <li class="ml-3 nav-item dropdown blk" (click)="hideBar()">
              <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                  <img class="languageImg" src="../../../assets/image/addIcon2.png" />
              </a>
              <div class="dropdown-menu dropdown-menu-right f-ss-14px" aria-labelledby="navbarDropdown" style="width: 220px;">
                  <div class="pb-3 languageRow">
                      <span class="f-ss-16px">Create New</span>
                  </div>
                  <div class="languageRow" routerLink="/income/create-invoice">
                      <span class="languageText">Invoice</span> `
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow" routerLink="/expense/create-new-bill">
                      <span class="languageText">Bill</span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow">
                      <span class="languageText" routerLink="/contacts/create-contact"> Contact </span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow">
                      <span class="languageText" routerLink="/spendmoney/create"> Spend Money </span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow">
                      <span class="languageText" routerLink="/receivemoney/create"> Receive Money </span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow">
                      <span class="languageText" routerLink="/transfermoney/create"> Transfer Money </span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="languageRow">
                      <span class="languageText" routerLink="/manual-journal/manual-journal-create"> Manual Journal </span>
                  </div>
              </div>
          </li>
          <li class="ptp-12px prp-4px">
              <span (click)="this.changeLanguage('en')" [ngClass]="{ selected: selectedLanguage == 'en' }" class="ml-2 languageText languageName pall-0">
                  {{ 'HEADER.English' | translate }}
              </span>
              <span class="pall-0" style="font-weight: 900;">|</span>
              <span (click)="this.changeLanguage('bn')" [ngClass]="{ selected: selectedLanguage == 'bn' }" class="mr-2 languageText languageName pall-0">
                  {{ 'HEADER.Bengali' | translate }}
              </span>
          </li>
          <li class="nav-item dropdown">
              <a class="nav-link navbar-avatar userNameNavbar rounded-circle" data-toggle="dropdown" href="#" aria-expanded="false" data-animation="scale-up" role="button">
                  <span>{{ UserName }}</span>
              </a>
              <div class="dropdown-menu" role="menu">
                  <a class="dropdown-item" routerLink="/profile/user-profile" role="menuitem">Profile</a>
                  <div class="dropdown-divider" role="presentation"></div>
                  <a class="dropdown-item" routerLink="/change-password/change-password" role="menuitem">Change Password</a>
                  <div class="dropdown-divider" role="presentation"></div>
                  <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" role="menuitem">Logout</a>
              </div>
          </li>
      </ul>
  </div>
</nav>