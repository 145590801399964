import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';
import { Router } from "@angular/router";
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentInfoModalComponent } from '../document-info-modal/document-info-modal.component';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { faCheckSquare, faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DocumentStatus } from 'src/app/enum/document-status';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { DocumentUnlockModalComponent } from '../document-unlock-modal/document-unlock-modal.component';

@Component({
  selector: 'app-document-active-inactive-overview',
  templateUrl: './document-active-inactive-overview.component.html',
  styleUrls: ['./document-active-inactive-overview.component.css']
})

export class DocumentActiveInactiveOverviewComponent implements OnInit {
  name: string = undefined;
  files: any;
  isPdf: boolean;
  zoom: number = 1;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  routes: string;
  users: [];
  searchText: string = "";
  imageSearch: string = "";
  url: string="";
  firstHalf: any;
  secondHalf: any;
  tempFiles: any;
  fileName: string;
  changeOnInventory = false;
  seeMoreOption: boolean = false;
  customerName: string;
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  status: any;
  submittedAttachments:any[] = [];
  activeCount:number = 0;
  inactiveCount: number = 0;
  faCheckSquare = faCheckSquare;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  src: string;
  selected: any;
  imagewidth: number;

  searchOptions = [
    {Id: 1, Name: 'Employee Id'},
    {Id: 2, Name: 'Reference 1'},
    {Id: 3, Name: 'Reference 2'},
    {Id: 4, Name: 'Document Type'},
    {Id: 5, Name: 'Tag'}
  ]

  searchOptionMappings = {
    1: 'ReferenceNo',
    2: 'Reference1',
    3: 'Reference2',
    4: 'DocumentTypeName',
    5: 'EmployeeId'
  };

  selectedSearchOption: number;
  filteredResults: any[] = [];
  showResults: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 8;

  private searchTermSubject: Subject<string> = new Subject<string>();
  searchResult$: Observable<string> = this.searchTermSubject.asObservable();

  constructor(
    private authService: AuthService, 
    private toastr: ToastrService, 
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService, 
    private ngxSmartModalService: NgxSmartModalService, 
    private modal: NgbModal,
    private attachmentService: AttachmentServiceService,
    private activeRouter: ActivatedRoute,
    private modalService: NgbModal,
    private documentService: DocumentServiceService,
    private settingsService: SettingsServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.status = this.activeRouter.snapshot.paramMap.get("status");
    this.selectedSearchOption = 1;
    this.configBaseUrl();
    this.getAllFilesByType();
    localStorage.setItem('type','Settings');

    this.searchResult$.pipe(
      debounceTime(100),
      switchMap(s => {
          if(s == '') this.more()
          else this.getSearchResults(s)
          return s;
      })
    ).subscribe();
    this.getAllDynamicLabelNameForDocumentInputs();
  }

    //START REGION => SEARCHING
  onSearch(query: string) {
    if (query.length === 0) {
      this.showResults = false;
      this.filteredResults = [];
      return;
    }
    const selectedProperty = this.searchOptionMappings[this.selectedSearchOption];
    let Results;
    if(this.status == 'inactive'){
      Results = this.tempFiles?.filter((result) => result[selectedProperty]?.toLowerCase()?.includes(query?.toLowerCase()) && result.IsInactive == true);
    }else{
      Results = this.tempFiles?.filter((result) => result[selectedProperty]?.toLowerCase()?.includes(query?.toLowerCase()) && result.IsInactive == false);
    }
    //this.filteredResults = Results.map(result => result[selectedProperty]);
    this.filteredResults = [...new Set(Results.map(result => result[selectedProperty]))];
    this.showResults = true;
  }

  onListItemClick(result: string) {
    const inputField = document.querySelector('.documentSearchBox') as HTMLInputElement;
    inputField.value = result;
    this.showResults = false;
    if(result != '')
      this.getSearchResults(result);
  }

  setSelectedSearchOption(option: number) {
    this.onListItemClick('');
    this.selectedSearchOption = option;
    if(this.status == 'active'){
      this.files = [];
    }
    else{
      this.files = this.tempFiles?.filter(x => x.IsInactive == true);
    }
  }

  updateFilter = (event) => {
    this.seeMoreOption = false;
    if (event.target.value !== '') {
      this.searchTermSubject.next(event.target.value);
    }
  }
  
  getSearchResults(searchTerm: string) {
    const selectedProperty = this.searchOptionMappings[this.selectedSearchOption];
    if(this.status == 'inactive') {
      this.files = this.tempFiles?.filter(x => x[selectedProperty]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) && x.IsInactive == true);
    }
    else {
      this.files = this.tempFiles?.filter(x => x[selectedProperty]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) && x.IsInactive == false);
    }
  }
  //END REGION => SEARCHING

  seeMore() {
    this.pageNumber = this.pageNumber + 1;
    this.getAllFilesByType();
  }

  seePrevious() {
    this.pageNumber = this.pageNumber - 1;
    this.getAllFilesByType();
  }

  more(){
    if(this.status == 'all'){
      this.getAllFilesByType();
    }
    else if(this.status == 'inactive'){
      this.files = this.tempFiles?.filter(x => x.IsInactive == true);
    }
    else{
      this.files = [];
    }
    this.seeMoreOption = false;
  }

  configBaseUrl(){
    this.url = location.origin+"/#/";
  }

  getAllFilesByType() {
    this.imageSearch = '';
    var userInfo = this.authService.getUserInfo();
    if(userInfo.IsCompanyHasOnlyDocumentFeature){
      this.attachmentService.GetOnlyApprovedAndArchiveTagsAttachmentDocumentRelatedOnly().subscribe((x: any) => {
        this.spinner.hide();
        this.tempFiles = x;
        this.reLoadRowData(this.status);
        this.inactiveCount = this.tempFiles?.filter(x => x.IsInactive == true).length;
        this.activeCount = this.tempFiles?.filter(x => x.IsInactive == false).length;
      }, (err) => {
        this.files = [];
        this.spinner.hide();
      });
    }
    else{
      this.attachmentService.GetOnlyApprovedAndArchiveAttachments().subscribe((x: any) => {
        this.spinner.hide();
        this.tempFiles = x;
        this.reLoadRowData(this.status);
        this.inactiveCount = this.tempFiles?.filter(x => x.ApprovalStatus == DocumentStatus.INACTIVE).length;
        this.activeCount = this.tempFiles?.filter(x => x.ApprovalStatus != DocumentStatus.INACTIVE).length;
      }, (err) => {
        this.files = [];
        this.spinner.hide();
      });
    }
  }

  mouseOver(id: number) {
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.add("mdivpaddinghover");
    element1.classList.remove("mdivpadding");
    element.classList.add("mystyle");
    let element2 = document.getElementById("imagesview" + id)
    element2.classList.add("imagediv-hover");
    document.getElementById("options" + id).classList.add("show");
    document.getElementById("options" + id).classList.remove("hide");
  }

  mouseOut(id: number) {
    if(this.submittedAttachments.find(x=>x.BlobName==id)) return;
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.remove("mdivpaddinghover");
    element1.classList.add("mdivpadding");
    element.classList.remove("mystyle");
    let element2 = document.getElementById("imagesview" + id);
    element2.classList.remove("imagediv-hover");
    document.getElementById("options" + id).classList.remove("show");
    document.getElementById("options" + id).classList.add("hide");
  }

  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }

  view(file: any) {
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }

  share(file: any) {
    this.selected = file;
    this.getAllUsers();
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
  }

  print(file: any, id: any, pdf: boolean){
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  getAllUsers() {
    this.spinner.show();
    this.users = [];
    this.mainService.GetSubscribedUserList().subscribe((x) => {
      this.spinner.hide();
      this.users = x;
    });
  }
  
  shareFile(id:number){
    this.attachmentService.shareFile({ BlobName: this.selected.BlobName, SharedToId: id,
      ReferenceNo: this.selected.ReferenceNo, FileName: this.selected.FileName, Parent: this.name }).subscribe((x) => {
      this.toastr.success("Shared successful", 'Success!');
    }, (err) => {
    });
  }

  reLoadRowData(status: any){
    if(this.status != status){
      this.onListItemClick('');
    }
    this.status = status;
    if(status == 'inactive'){
      this.files = this.tempFiles?.filter(x => x.IsInactive == true);
    }
    else if(status == 'active'){
      this.files = [];
    }
    this.seeMoreOption = this.files.length > 20 ? true : false ;
      if (this.seeMoreOption){
        this.firstHalf = this.files?.slice(0, 19);
        this.secondHalf = this.files?.slice(20, this.files?.length + 1);
        this.files = [...this.firstHalf];
      }
  }

  pressDocument(item:any){
    if(this.submittedAttachments.find(x=>x.BlobName==item.BlobName)){
      this.submittedAttachments = this.submittedAttachments.filter(x=>x.BlobName!=item.BlobName);
      this.mouseOut(item.BlobName);
    }
    else{
      this.submittedAttachments.push(item);
      this.mouseOver(item.BlobName);
    }
  }

  documentModalView(data:any){
    this.spinner.show();
    let modalData = {
      DocumentBlobName:data.BlobName,
      DocumentName: data.FileName,
      DocumentType: data.ReferenceNo,
      Number: data.Id,
      UploadedAt: data.CreatedDate,
      UploadedBy: data.UpdatedByName,
      openFrom: 'overview',
      ApprovalStatus: data.ApprovalStatus
    }
    this.documentService.saveModalInfoData(modalData);
    if(data.ApprovalStatus==2) this.documentService.setSeeFullHistory(true);
    else this.documentService.setSeeFullHistory(false);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  documentModalViewFullHistory(data:any){
    this.spinner.show();
    let modalData = {
      DocumentBlobName:data.BlobName,
      DocumentName: data.FileName,
      DocumentType: data.ReferenceNo,
      Number: data.Id,
      UploadedAt: data.CreatedDate,
      UploadedBy: data.UpdatedByName,
      openFrom: 'overview',
      ApprovalStatus: data.ApprovalStatus
    }
    this.documentService.saveModalInfoData(modalData);
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent, { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  deleteCurrDocFile(file:any){
    this.attachmentService.deleteCurrDocFile(file).subscribe((x) => {
      this.toastr.success("Updated successfully", 'Success!');
      location.reload();
    }, (err) => {
    });
  }

  openDocumentModalFullHistory(file){
    this.documentService.saveModalInfoData( { Number: file.Id } );
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent, { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  seelockFile(file){
    const modalRef = this.modal.open(DocumentUnlockModalComponent, { size: 'md', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.document = file;
    modalRef.result.then((result) => {
      if (result == 'Success'){
        this.getAllFilesByType();
        this.submittedAttachments = [];
      }
    });
  }

  isOnlyHasDocumentFeature() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      return true;
    else
      return false;
  }

  InActive(){
    this.spinner.show();
    this.submittedAttachments.forEach(attachment => {
      attachment.IsInactive = true;
    });
    this.attachmentService.DeactivateDocuments(this.submittedAttachments).subscribe((x) => {
      this.toastr.success("Document inactivated Successfully.");
      this.spinner.hide();
      this.getAllFilesByType();
      this.submittedAttachments = [];
    }, (err) => {});
  }

  Active(){
    this.spinner.show();
    this.submittedAttachments.forEach(attachment => {
      attachment.IsInactive = false;
    });
    this.attachmentService.ActivateDocuments(this.submittedAttachments).subscribe((x) => {
      this.toastr.success("Document activated Successfully.");
      this.spinner.hide();
      this.getAllFilesByType();
      this.submittedAttachments = [];
    }, (err) => {});
  }

  IsNull(data: any){
    return this.mainService.IsNull(data);
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
        let labelIndices = [0, 2, 3, 4, 5];
        labelIndices.forEach((index, i) => {
            let labelName = x[index]?.LabelName;
            if (!this.mainService.IsNull(labelName)) {
                this.searchOptions[i].Name = labelName;
            }
        });
    });
  }

  formattedVersionNo(versionNo: number) {
    let VersionNo = Number.isInteger(versionNo) ? `${versionNo}.0` : versionNo;
    return VersionNo;
  }

}