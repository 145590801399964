import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessPartnerLoanService } from 'src/app/service/Business-partner-loan/business-partner-loan.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { BusinessPartnerLoanEditComponent } from '../../business-partner-loan-edit/business-partner-loan-edit.component';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { LoanStatus } from 'src/app/enum/loan-status';
import { RejectPopupModalComponent } from 'src/app/pages/Reject-popup-modal/reject-popup-modal/reject-popup-modal.component';
@Component({
  selector: 'app-view-bp-loan',
  templateUrl: './view-bp-loan.component.html',
  styleUrls: ['./view-bp-loan.component.css']
})
export class ViewBpLoanComponent implements OnInit {

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private ngxmodalService: NgxSmartModalService,
    private mainService: MainServiceService,
    private BPLoanService: BusinessPartnerLoanService,
    private modalService: NgbModal
  ) { }

  id: any;
  bankAccounts: any;
  SettlementForm: FormGroup;
  loanModel: any = {};
  url: any;
  IfSettle = false;
  SettlementList: any;
  canApproveOrReject: boolean;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getBPLoanData();
    this.initForm();
    this.getBanks();
  }

  initForm = () => {
    this.SettlementForm = this.fb.group({
      Amount: ['', Validators.required],
      Description: ['', Validators.required],
      Date: [ Validators.required],
      BPLoanId: [],
      ContactId: [],
      AccountId: ['', Validators.required]
    });
    this.SettlementForm.get('Date').patchValue(new Date());
    this.showbups(new Date());
  }

  getBanks = () => {
    this.bankAccounts = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.bankAccounts = x;
    });
  }

  getBPLoanData = () => {
    this.spinner.show();
    this.BPLoanService.GetGrantLoanbyId(this.id).subscribe((x: any) => {
      this.loanModel = x.result;
      this.canApproveOrReject = x.canApproveOrReject;
      this.IfSettle = (this.loanModel.TotalAmount - this.loanModel.SettleAmount) <= 0 ? true : false;
      this.url = this.loanModel.LoanTypeId === 1 ? 'business-partner/grant-loan-list/active' : 'business-partner/receive-loan-list/active';
      this.SettlementList = x.SettlementList;
      this.spinner.hide();
    });
  }

  submitDiscountAmount(){
    if (this.SettlementForm.invalid) {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    if(Number(this.SettlementForm.value.Amount) > (this.loanModel.TotalAmount - this.loanModel.SettleAmount)){
      this.toaster.warning('Can not settlement excess amount!');
      return;
    }

    this.spinner.show();
    if (this.loanModel.LoanTypeId == 1){
    this.BPLoanService.SaveGrantSettleMent(this.SettlementForm.value).subscribe((x) => {
        if (x){
          this.spinner.hide();
          this.ngxmodalService.getModal('AddSettlement').close();
          this.router.navigate(['business-partner/grant-loan-list/active']);
        }
      });
    }
    else {
      this.BPLoanService.SaveReceiveSettleMent(this.SettlementForm.value).subscribe((x) => {
        if (x) {
          this.spinner.hide();
          this.ngxmodalService.getModal('AddSettlement').close();
          this.router.navigate(['business-partner/receive-loan-list/active']);
        }
      });
    }
  }

  openSettlementPopup = () => {
    this.SettlementForm.get('BPLoanId').patchValue(this.loanModel.Id);
    this.SettlementForm.get('ContactId').patchValue(this.loanModel.ContactId);
    this.ngxmodalService.create('AddSettlement', 'content').open();
    this.showbups(new Date());
  }

  showbups(e) {
    const value = this.SettlementForm.value.Date;
    $("#bupstest").on("change", function() {
      this.setAttribute("data-date", moment(value, "YYYY-MM-DD").format( this.getAttribute("data-date-format")))
    }).trigger("change");
  }

  save(buttonPressed){
    this.spinner.show();
    this.loanModel.iQuidiActionType = buttonPressed;
    this.BPLoanService.UpdateStatus(this.loanModel).subscribe(x => {
      this.spinner.hide();
      if(x.Success){
        this.toaster.success("Operation Done Successfully!");
        location.reload();
      }  
      else this.toaster.error("Something went wrong!");
    });
  }

  edit(){
    const modalRef = this.modalService.open(BusinessPartnerLoanEditComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.id = this.loanModel.Id;
    modalRef.componentInstance.fromParent = 1;
  }

  reject(){
    this.loanModel.iQuidiActionType = iQuidiActionType.Reject;
    this.confirmBillRejection();
  }
  
  confirmBillRejection() {
    const modalRef = this.modalService.open(RejectPopupModalComponent, { size: 'md', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.data = this.loanModel;
    modalRef.componentInstance.TypeId = 6; //GrantLoan
    modalRef.result.then((result) => 
    {
      if (result == true)
      {
        this.spinner.show();
        this.BPLoanService.UpdateStatus(this.loanModel).subscribe(x => {
          this.spinner.hide();
          if(x.Success){
            this.toaster.success("Operation Done Successfully!");
            location.reload();
          }
          else this.toaster.error("Something went wrong!");
        });
      }
    });
  }

  canUpdateLoan(){
    if(this.loanModel.Status == LoanStatus.Draft) return true;
    if(this.loanModel.Status == LoanStatus.Rejected) return true;
    return false;
  }

  canRejectLoan(){
    if(this.loanModel.Status == LoanStatus.AwaitingApproval && this.canApproveOrReject) return true;
    return false;
  }

  canApproveLoan(){
    if(this.loanModel.Status == LoanStatus.Active) return false;
    if(this.loanModel.Status == LoanStatus.Closed) return false;
    if(this.loanModel.Status == LoanStatus.Rejected) return false;
    if(this.canApproveOrReject == false) return false;
    return true;
  }

  canSubmitLoan(){
    if(this.loanModel.Status == LoanStatus.Draft ) return true;
    if(this.loanModel.Status == LoanStatus.Rejected) return true;
    return false;
  }

  canSaveLoan(){
    if(this.loanModel.Status == null ) return true;
    if(this.loanModel.Status == LoanStatus.Draft ) return true;
    return false;
  }

  canSettleLoan(){
    if(this.loanModel.LoanTypeId == 2 && this.loanModel.IsSettle == false) return true;
    else if(this.loanModel.LoanTypeId == 1 && this.loanModel.Status == LoanStatus.Active) return true;
    else return false;
  }

  getStatusName(){
    if(this.loanModel.LoanTypeId === 1) {
      return this.getGrantLoanStatusName();
    }
    else{
      return this.getReceiveLoanStatusName();
    }
  }

  getGrantLoanStatusName(){
    switch(this.loanModel.Status){
      case 1:
        return "Draft";
      case 2:
        return "AwaitingApproval";
      case 3:
        return "Active";
      case 4:
        return "Closed";
      case 5: 
        return "Rejected";
      default:
        return ""; 
    }
  }

  getReceiveLoanStatusName(){
    return this.loanModel.IsSettle == false ? "Active" : "Closed";
  }

}
