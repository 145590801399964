<app-dashboard>
    <app-page-header-title mainTitle="Edit Invoice/ {{InvoiceId}} / {{StatusName}}" subTitle="Invoice Overview" backURL="/income/invoice-overview/All">
        <div class="full-width text-align-right">
            <div class="dropdown">
                <button style="z-index: 1;" type="button" class="btn attachment-button" (click)="openAttachmentDropdown()">
                    <i style="font-size: 20px !important;" class="material-icons">insert_drive_file</i>
                    <span style="color:darkgrey; font-size: 12px;" *ngIf="totalFile>=1">{{totalFile}}</span>
                </button>
                <div class="x-tip-tl" style="height: 0px;">
                    <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                </div>
                <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                    <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="1"></app-attachment-uploader>
                </div>
            </div>
            <button type="button" *ngIf="StatusId != 3 && StatusId != 4" (click)="updateDraftInvoice()" class="eCount-def-btn ml-4">Save</button>
            <button type="button" *ngIf="StatusId != 3 && StatusId != 4" (click)="saveAsSubmitInvoice()" class="eCount-def-btn ml-4">Submit</button>
            <button type="button" *ngIf="StatusId == 3 || StatusId == 4" (click)="updateApprovedData()" class="eCount-def-btn ml-4">Update</button>
            <button type="button" *ngIf="StatusId != 3 && StatusId != 4 && canApprove" style="background: #5cac00;" (click)="saveCreateInvoice()" class="eCount-def-btn ml-4">Approve</button>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <form [formGroup]="invoiceForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2 col-sm-12">
                    <label>To <span class="clr">*</span></label>
                    <ng-select [items]="allCustomer" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewCustomer" [loading]="loading" name="name" formControlName="CustomerId" [ngClass]="{'submitted':ifSubmit===true}" required>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                            <b>+ Add New Customer</b>: {{search}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Date</label>
                    <input class="form-control" type="date" formControlName="InvoiceDate" id="eitest" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="show($event)" required disabled>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Due Date <span class="clr">*</span></label>
                    <input class="form-control" type="date" formControlName="DueDate" [ngClass]="{'submitted':ifSubmit===true}" data-date="" id="eitest1" data-date-format="D MMM YYYY" value="2015-08-09" (change)="showSelectedDate($event)" required>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Reference</label>
                    <input class="form-controlinputBox" type="text" formControlName="ReferenceNo">
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Invoice No</label>
                    <span class="form-controlinputBox d-flex">
                        <label style="background-color: #dddddd;padding-bottom:10%;">INV-{{ShowInvoiceNo}}</label>
                        <input *ngIf="StatusId!=3" class="form-controlinputBox2" formControlName="InvoicceNo" type="text">
                        <input *ngIf="StatusId==3" class="form-controlinputBox2" formControlName="InvoicceNo" type="text" disabled>
                    </span>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>Amounts are</label>
                    <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="TaxTypeId"></ng-select>
                </div>
                <div class="col-md-4 col-sm-12 ml-0 mt-2" *ngIf="allDeliveryPartner?.length > 0">
                    <label>Active Third Party Delivery</label>
                    <label class="switch">
                        <input class="switch-input" type="checkbox" formControlName="IsActiveDeliveryPartner" (change)="toogleDeliveryPartner()">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-1" [ngClass]="{'col-md-2': isDescriptiveInvoice()}"> Item </div>
                    <div class="col-md-1" [ngClass]="{'col-md-2': IsAllItemsDescriptiveOrService()}"> Description <span class="clr">*</span></div>
                    <div class="col-md-2"> Account </div>
                    <div class="col-md-1"> Quantity </div>
                    <div *ngIf="!IsAllItemsDescriptiveOrService()" class="col-md-1"> Shipped </div>
                    <div class="col-md-1"> Price </div>
                    <div class="col-md-1"> Discount% </div>
                    <div class="col-md-1"> VAT% </div>
                    <div class="col-md-1"> AIT% </div>
                    <div class="col-md-2"> Amount </div>
                </div>
                <hr class="pb-0 mb-0" />
                <div>
                    <div formArrayName="InvoiceItems" *ngFor="let invoice of invoiceForm.get('InvoiceItems')['controls']; let i = index;">
                        <div class="invoiceGridItemRow d-block" [ngClass]="[invoice.selected ? 'selectedRow' : 'notSelectedRow']">
                            <div class="d-flex" [formGroupName]="i">
                                <div class="col-md-1 borderLeft d-flex rowSpaceing pl-0" [ngClass]="{'col-md-2': isDescriptiveInvoice()}">
                                    <div class="dragIcon">
                                        <i class="icon-grid2"></i>
                                    </div>
                                    <div style="width: 100%;margin-left: 5px; ">
                                        <div [ngClass]="{ 'itemWidth': !isDescriptiveInvoice() }">
                                            <ng-select [items]="allSaleableItem" placeholder="Select Items" #selectItem class="customSaleItem" [clearable]="false" bindLabel="ItemName" bindValue="Id" (change)="changesellableItems(i)" formControlName="ItemId">
                                                <ng-template ng-header-tmp>
                                                    <a (click)="openItemModal(selectItem)" role="button" aria-haspopup="false" aria-expanded="false">
                                                        <span style="color: #007bff;">+ Add Item</span>
                                                    </a>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 borderLeft rowSpaceing" [ngClass]="{'col-md-2': IsAllItemsDescriptiveOrService()}">
                                    <textarea class="form-control" style="resize: none;margin: 5px; min-height: 20px; max-height: 25px;" type="text" formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}" required></textarea>
                                </div>
                                <div class="col-md-2 borderLeft rowSpaceing">
                                    <div>
                                        <ng-select [items]="allChartOfAccount" placeholder="Select Account" class="customSaleItem" [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId" groupBy="GroupName"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 borderLeft rowSpaceing">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="Quantity" (click)="changeSelectRowInItem(invoice.ItemId, i)" (change)="updateDataInvoiceItem(invoice.value.ItemId, i)" [ngClass]="{'submitted':ifSubmit===true}" required>
                                </div>
                                <div *ngIf="!IsAllItemsDescriptiveOrService()" class="col-md-1 borderLeft rowSpaceing">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="ShippedItems" [ngClass]="{'submitted':ifSubmit===true}" [ngStyle]="{'color': isShippedMaxOrMin(i)? 'red' : '#444'}" required readonly>
                                </div>
                                <div class="col-md-1 borderLeft rowSpaceing">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="UnitPrice" (change)="updateDataInvoiceItem(invoice.value.ItemId, i)" [ngClass]="{'submitted':ifSubmit===true}" required>
                                </div>
                                <div class="col-md-1 borderLeft rowSpaceing">
                                    <input class="form-control" type="text" appDecimaNumericValidator formControlName="DiscountPercent" (focus)="onFocusEvent($event)" (change)="updateDiscountInvoiceItem(invoice.value.ItemId, i)">
                                </div>
                                <div class="col-md-1 borderLeft mt-1">
                                    <div style="width: 100%;">
                                        <ng-select [items]="taxRateList" [ngClass]="{'disable': invoiceForm.value.TaxTypeId===3}" placeholder="" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" formControlName="TaxRateId"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 borderLeft mt-1">
                                    <div style="width: 100%;">
                                        <ng-select [items]="taxRateList" [ngClass]="{'disable': invoiceForm.value.TaxTypeId===3}" placeholder="" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" formControlName="AdvanceTaxRateId"></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-2 rowSpaceing d-flex ml-0 mr-0 pl-0 pr-0">
                                    <div class="ml-0 mr-0 pl-0 pr-0">
                                        <input class="form-control m-0 p-0" type="number" style="background-color:white; color:black; width: 100px;" appDecimaNumericValidator disabled formControlName="LineTotal">
                                    </div>
                                    <div style="padding: 7px 10px; color: #c7c2c2;" class="ml-0 mr-0 pl-0 pr-0">
                                        <div class="dropdown mr-4 ml-0 mr-0 pl-0 pr-0">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" class="ml-0 mr-0 pl-0 pr-0">
                                                <i class="icon-more float-right ml-0 mr-0 pl-0 pr-0"></i>
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                                <div class="productRow pb-3" (click)="removeInvoiceItem(invoice.ItemId, i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addBlankItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line 
                            </a>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="font-weight-bold" [hidden]="this.invoiceForm.value.IsActiveDeliveryPartner == false">Delivery Partner</p>
                    <div formArrayName="DeliveryPartnerItems" *ngFor="let item of invoiceForm.get('DeliveryPartnerItems')['controls']; let i = index;">
                        <div class="invoiceGridItemRow d-block">
                            <div class="d-flex selectedRows" [formGroupName]="i">
                                <div class="col-md-2 borderLeft d-flex rowSpaceing pl-0">
                                    <div class="dragIcon">
                                        <i class="icon-grid2"></i>
                                    </div>
                                    <div style="width: 100%;margin-left: 5px; ">
                                        <ng-select [items]="allDeliveryPartner" placeholder="Select Partner" #selectItem class="customSaleItem mt-2" [clearable]="false" bindLabel="CompanyName" bindValue="Id" formControlName="DeliveryPartnerId" [ngClass]="{'submitted':ifSubmit===true}" required></ng-select>
                                    </div>
                                </div>
                                <div class="col-md-3 borderLeft rowSpaceing">
                                    <textarea class="form-control descriptionTextArea" type="text" formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}" readonly></textarea>
                                </div>
                                <div class="col-md-4 borderLeft rowSpaceing">
                                    <input class="form-control" type="number" appDecimaNumericValidator formControlName="UnitPrice" placeholder="price" (change)="updateTotalAfterDeliveryCharge()" (focus)="onFocusEvent($event)" [ngClass]="{'submitted':ifSubmit===true}">
                                </div>
                                <div class="col-md-3 rowSpaceing d-flex">
                                    <div>
                                        <input class="form-control" type="number" appDecimaNumericValidator formControlName="LineTotal">
                                    </div>
                                    <div style="padding: 7px 0px 7px 10px; color: #c7c2c2;">
                                        <div class="dropdown ml-4">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                <img src="../../../../assets/image/icons/more.png" class="float-right icon mr-4" />
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                                <div class="productRow pb-3" (click)="removeDeliveryItem(i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-bottom d-flex" (click)="changeSelectRowInItem(0,0)">
                    <div class="col-md-8 col-sm-12 row">
                        <div class="form-group col-sm-5 ">
                            <label>Notes</label>
                            <div class="textarea-wrapper">
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea" placeholder="Write down some important notes using only 250 characters" formControlName="Notes"></textarea>
                                <span class="remaning">{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                        <div class="col-sm-7 row">
                            <div class="col-sm-3">
                                <label style="font-weight: 600;">Repeating</label>
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" formControlName="IsRepeating" (change)="changeType()">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="col-sm-7 ml-4" *ngIf="invoiceForm.value.IsRepeating === true">
                                <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types <span class="required-sign"></span></label>
                                <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id" [ngClass]="{'submitted':ifSubmit===true && invoiceForm.value.IsRepeating === true}" formControlName="RepeatingTypeId" [clearable]="false" required></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4">{{subTotalAmount | currency:'':''}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="invoiceForm.value.TaxTypeId !=3">
                            <div class="col-md-8">
                                <label>VAT</label>
                            </div>
                            <div class="col-md-4">{{invoiceForm.value.TaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="invoiceForm.value.TaxTypeId !=3">
                            <div class="col-md-8">
                                <label>AIT</label>
                            </div>
                            <div class="col-md-4">{{invoiceForm.value.AdvanceTaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-3">
                            <div class="col-md-8">
                                <label>TOTAL</label>
                            </div>
                            <div class="col-md-4 font-weight-bold">{{invoiceForm.value.InvoiceAmount | currency:'':''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>