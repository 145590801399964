export const CurrencyList = [
  { Value: 1, Code: "USD", Text: "United States Dollar", Symbol: "$" },
  { Value: 2, Code: "EUR", Text: "Euro", Symbol: "€" },
  { Value: 3, Code: "JPY", Text: "Japanese Yen", Symbol: "¥" },
  { Value: 4, Code: "GBP", Text: "British Pound Sterling", Symbol: "£" },
  { Value: 5, Code: "AUD", Text: "Australian Dollar", Symbol: "A$" },
  { Value: 6, Code: "CAD", Text: "Canadian Dollar", Symbol: "C$" },
  { Value: 7, Code: "CHF", Text: "Swiss Franc", Symbol: "CHF" },
  { Value: 8, Code: "CNY", Text: "Chinese Yuan", Symbol: "¥" },
  { Value: 9, Code: "SEK", Text: "Swedish Krona", Symbol: "kr" },
  { Value: 10, Code: "NZD", Text: "New Zealand Dollar", Symbol: "NZ$" },
  { Value: 11, Code: "BDT", Text: "Bangladeshi Taka", Symbol: "৳" },
  { Value: 12, Code: "INR", Text: "Indian Rupee", Symbol: "₹" },
  { Value: 13, Code: "SGD", Text: "Singapore Dollar", Symbol: "S$" },
  { Value: 14, Code: "HKD", Text: "Hong Kong Dollar", Symbol: "HK$" },
  { Value: 15, Code: "ZAR", Text: "South African Rand", Symbol: "R" },
  { Value: 16, Code: "BRL", Text: "Brazilian Real", Symbol: "R$" },
  { Value: 17, Code: "RUB", Text: "Russian Ruble", Symbol: "₽" },
  { Value: 18, Code: "MXN", Text: "Mexican Peso", Symbol: "$" },
  { Value: 19, Code: "IDR", Text: "Indonesian Rupiah", Symbol: "Rp" },
  { Value: 20, Code: "TRY", Text: "Turkish Lira", Symbol: "₺" },
  { Value: 21, Code: "KRW", Text: "South Korean Won", Symbol: "₩" },
  { Value: 22, Code: "PLN", Text: "Polish Zloty", Symbol: "zł" },
  { Value: 23, Code: "THB", Text: "Thai Baht", Symbol: "฿" },
  { Value: 24, Code: "MYR", Text: "Malaysian Ringgit", Symbol: "RM" },
  { Value: 25, Code: "SAR", Text: "Saudi Riyal", Symbol: "﷼" },
  { Value: 26, Code: "AED", Text: "United Arab Emirates Dirham", Symbol: "د.إ" },
  { Value: 27, Code: "PHP", Text: "Philippine Peso", Symbol: "₱" },
  { Value: 28, Code: "PKR", Text: "Pakistani Rupee", Symbol: "₨" },
  { Value: 29, Code: "EGP", Text: "Egyptian Pound", Symbol: "£" },
  { Value: 30, Code: "VND", Text: "Vietnamese Dong", Symbol: "₫" },
  { Value: 31, Code: "NOK", Text: "Norwegian Krone", Symbol: "kr" },
  { Value: 32, Code: "DKK", Text: "Danish Krone", Symbol: "kr" },
  { Value: 33, Code: "CZK", Text: "Czech Koruna", Symbol: "Kč" },
  { Value: 34, Code: "HUF", Text: "Hungarian Forint", Symbol: "Ft" },
  { Value: 35, Code: "ILS", Text: "Israeli New Shekel", Symbol: "₪" },
  { Value: 36, Code: "NGN", Text: "Nigerian Naira", Symbol: "₦" },
  { Value: 37, Code: "KES", Text: "Kenyan Shilling", Symbol: "KSh" },
  { Value: 38, Code: "ZMW", Text: "Zambian Kwacha", Symbol: "ZK" },
  { Value: 39, Code: "ARS", Text: "Argentine Peso", Symbol: "$" },
  { Value: 40, Code: "CLP", Text: "Chilean Peso", Symbol: "$" },
  { Value: 41, Code: "COP", Text: "Colombian Peso", Symbol: "$" },
  { Value: 42, Code: "TWD", Text: "New Taiwan Dollar", Symbol: "NT$" },
  { Value: 43, Code: "QAR", Text: "Qatari Riyal", Symbol: "﷼" },
  { Value: 44, Code: "KWD", Text: "Kuwaiti Dinar", Symbol: "د.ك" },
  { Value: 45, Code: "BHD", Text: "Bahraini Dinar", Symbol: "ب.د" },
  { Value: 46, Code: "OMR", Text: "Omani Rial", Symbol: "﷼" },
  { Value: 47, Code: "LKR", Text: "Sri Lankan Rupee", Symbol: "₨" },
  { Value: 48, Code: "MMK", Text: "Myanmar Kyat", Symbol: "K" },
  { Value: 49, Code: "BND", Text: "Brunei Dollar", Symbol: "B$" },
  { Value: 50, Code: "XOF", Text: "West African CFA Franc", Symbol: "CFA" },
  { Value: 51, Code: "XAF", Text: "Central African CFA Franc", Symbol: "FCFA" }
]; 