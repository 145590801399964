import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { AcountingServiceService } from "src/app/service/Accounting/acounting-service.service";
import { ManualJournalService } from "src/app/service/Manual Journal/manual-journal.service";

@Component({
  selector: "app-manual-journal-details",
  templateUrl: "./manual-journal-details.component.html",
  styleUrls: ["./manual-journal-details.component.css"],
})

export class ManualJournalDetailsComponent implements OnInit {

  total: any;
  manualJournalId: number;
  manualJournalData: any;
  chartOfAccount: any = [];

  constructor(
    private route: ActivatedRoute,
    private accountingService: AcountingServiceService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private manualJournalService: ManualJournalService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.manualJournalId = +params["id"];
      this.getMaualJournalById();
    });
  }

  getMaualJournalById() {
    this.manualJournalService.getManualJournal(this.manualJournalId).subscribe((x) => {
      this.manualJournalData = x;
      this.manualJournalData = this.getAccountName(this.manualJournalData);
    });
  }

  postManualJournal() {
    this.spinner.show();
    this.manualJournalService.postManualJournal(this.manualJournalData.Id).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manual-journal/manual-journal-list/all"]);
      } else {
        this.toaster.error("Manual Journal not posted!");
      }
      this.spinner.hide();
    });
  }

  deleteManualJournal() {
    this.spinner.show();
    this.manualJournalService.deleteManualJournal(this.manualJournalData.Id).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manual-journal/manual-journal-list/all"]);
      } else {
        this.toaster.error("Manual Journal not deleted!");
      }
      this.spinner.hide();
    });
  }

  getAccountName = (manualJournal) => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
      manualJournal.ManualJournalItems.forEach((element) => {
        var x = this.chartOfAccount.find((p) => p.Id === element.AccountId);
        element.AccountName = x.AccountName;
      });
    });
    return manualJournal;
  }

  convertDateIntoDisplay = (date: Date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  }

}
