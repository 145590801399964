import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { Subscription } from "rxjs";
import { WarningPopupComponent } from "../../components/Warning-Popup/warning-popup/warning-popup.component";
import {
  faDollarSign,
  faShoppingBag,
  faShoppingBasket,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { AuthService } from "../../service/auth/auth.service";
import { DashboardService } from "../../service/Dashboard/dashboard.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit, OnDestroy {
  //#region Properties
  billSummaryItems: any;
  invoiceSummaryItems: any;
  receiveableAmount: 0;
  payableAmount: 0;
  bankBalance: 0;
  cashBalance: 0;
  digitalCashBalance: 0;
  overdueInvoiceValue = 0;
  draftInvoiceValue: 0;
  totalInvoiceAwaitingPayment: 0;
  awaitingInvoiceValue = 0;
  messageModel: any = {};
  overduebillValue = 0;
  draftBillValue: 0;
  TotalGrantLoan = 0;
  Balance: any;
  TotalReceiveLoan = 0;
  totalBillAwaitingPayment = 0;
  awaitingBillValue = 0;
  TotalDraftInvoice = 0;
  TotalDraftBill = 0;
  TotalBillOverDue = 0;
  TotalInvoiceOverDue: 0;
  showWarning = false;
  Icons = { faDollarSign, faTags, faShoppingBag, faShoppingBasket };
  currencyCode: string;

  //#endregion

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private mainService: MainServiceService
  ) {}

  ngOnInit() {
              // This portion commented for when company is has only document feature
    //this.dataService.emitsetSidebarUpdate(true);
    this.currencyCode = this.mainService.getCurrencyCode();
    this.dataService.emitSidebarHide(true);
    this.dataService.changeSelectedOrganization.subscribe((x) => {
      this.getFormData();
    });
    this.dataService.setWarningPopup.subscribe((x) => {
      if (
        (x.ActionTitle == "Click Here" && x.RedirectUrl != null) ||
        (x.IsTrial == true && x.RedirectUrl != null)
      ) {
        //this.createNewItem(x);
      }
    });
    this.getFormData();
  }
  ngOnDestroy() {
    this.dataService.emitSidebarUpdate(false);
  }
  //#region Method
  getFormData = () => {
    this.getReceiveablePayableSummary();
    this.getBillSummary();
    this.getInvoiceSummary();
    this.getLast7DaysCashInOutTrans();
    this.getLast6MonthCashInOutTrans();
    this.getInvoiceChartSlotValues();
    this.getBillChartSlotValues();
  };
  getReceiveablePayableSummary = () => {
    this.dashboardService.GetReceiveablePayableSummary().subscribe((x) => {
      this.receiveableAmount = x.ReceiveableAmountView;
      this.payableAmount = x.PayableAmountView;
      this.bankBalance = x.BankBalanceView;
      this.Balance = x.BalanceView;
      this.cashBalance = x.CashBalanceView;
      this.digitalCashBalance = x.DigitalCashBalanceView;
      this.TotalGrantLoan = x.GrantLoanView;
      this.TotalReceiveLoan = x.ReceiveLoanView;
    });
  }
  getBillSummary = () => {
    this.dashboardService.GetBillSummaryList().subscribe((x) => {
      this.billSummaryItems = x;
      this.draftBillValue = x.DraftBillValue;
      console.log(x);
      this.TotalDraftBill = x.TotalDraft == 0 ? 0 : x.TotalDraft;
      this.TotalBillOverDue = x.TotalOverdue == 0 ? 0 : x.TotalOverdue;
      this.totalBillAwaitingPayment =
        x.TotalAwaitingPayment == 0 ? 0 : x.TotalAwaitingPayment;
      this.awaitingBillValue = x.AwaitingBillValue;
      this.overduebillValue = x.OverdueBillValue;
    });
  };
  getInvoiceSummary = () => {
    this.dashboardService.GetInvoiceSummaryList().subscribe((x) => {
      this.invoiceSummaryItems = x;
      console.log(x);
      this.TotalDraftInvoice = x.TotalDraft == 0 ? 0 : x.TotalDraft;
      this.TotalInvoiceOverDue = x.TotalOverdue == 0 ? 0 : x.TotalOverdue;
      this.draftInvoiceValue = x.DraftInvoiceValue;
      this.totalInvoiceAwaitingPayment =
        x.TotalAwaitingPayment == 0 ? 0 : x.TotalAwaitingPayment;
      this.awaitingInvoiceValue = x.AwaitingInvoiceValue;
      this.overdueInvoiceValue = x.OverdueInvoiceValue;
    });
  };
  getLast7DaysCashInOutTrans = () => {
    this.dashboardService.GetLast7DaysCashInOutTrans().subscribe((x) => {
      this.barChartLast7Labels = x.Labels;
      console.log(x);
      this.barChartLast7Data = [
        { data: x.CashInList, label: "Cash In" },
        { data: x.CashOutList, label: "Cash Out" },
      ];

      let data7Days = JSON.parse(JSON.stringify(this.barChartLast7Data));
      this.barChartLast7Data = data7Days;

      let lable7days = JSON.parse(JSON.stringify(this.barChartLast7Labels));
      this.barChartLast7Labels = lable7days;
    });
  };
  getLast6MonthCashInOutTrans = () => {
    this.dashboardService.GetLast6MonthCashInOutTrans().subscribe((x) => {
      this.barChartLast6MonthLabels = x.Labels;
      this.barChartLast6MonthData = [
        { data: x.CashInList, label: "Cash In" },
        { data: x.CashOutList, label: "Cash Out" },
      ];

      let data6Month = JSON.parse(JSON.stringify(this.barChartLast6MonthData));
      this.barChartLast6MonthData = data6Month;

      let lable6Month = JSON.parse(
        JSON.stringify(this.barChartLast6MonthLabels)
      );
      this.barChartLast6MonthLabels = lable6Month;
    });
  };
  getInvoiceChartSlotValues = () => {
    this.dashboardService.GetInvoiceChartSlotValues().subscribe((x) => {
      this.barChartInvoiceLabels = x.map((a) => a.Name);
      this.barChartInvoiceData = x.map((a) => a.Value);
    });
  };
  getBillChartSlotValues = () => {
    this.dashboardService.GetBillChartSlotValues().subscribe((x) => {
      this.barChartBillLabels = x.map((a) => a.Name);
      this.barChartBillData = x.map((a) => a.Value);
    });
  };
  public createNewItem = (x) => {
    const modalRef = this.modalService.open(WarningPopupComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    this.messageModel.Message = x.IsTrial ? "purchase a plan" : "renew";
    this.messageModel.Plan = x.IsTrial ? "trial period" : "subscription";
    this.messageModel.purchaseType = x.IsTrial ? "buy" : "renew";
    modalRef.componentInstance.fromParent = this.messageModel;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  };
  //#endregion

  //#region Last 7Days

  barChartLast7Options: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [{ gridLines: { drawBorder: false }, display: false }],
    },
    tooltips: {
      backgroundColor: "rgba(255,255,255,0.9)",
      bodyFontColor: "#2b74bd",
      borderColor: "#d3d3d3",
      borderWidth: 1,
      cornerRadius: 0,
      caretPadding: 15,
      displayColors: false,
      enabled: true,
      intersect: true,
      mode: "x",
      titleFontColor: "#000000", // '#89d0f5',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
    },
  };
  barChartLast7Labels: Label[] = [];
  barChartLast7Type: ChartType = "bar";
  barChartLast7Legend = true;
  barChartLast7Plugins = [];
  barChartLast7Colors: Color[] = [
    {
      borderColor: "blue",
      backgroundColor: "#007bff",
    },
  ];
  public barChartLast7Data: ChartDataSets[] = [
    { data: [], label: "Cash In" },
    { data: [], label: "Cash Out" },
  ];

  //#endregion

  //#region Last 6 Month
  barChartLast6MonthOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [{ gridLines: { drawBorder: false }, display: false }],
    },
    tooltips: {
      backgroundColor: "rgba(255,255,255,0.9)",
      bodyFontColor: "#2b74bd",
      borderColor: "#d3d3d3",
      borderWidth: 1,
      cornerRadius: 0,
      caretPadding: 15,
      displayColors: false,
      enabled: true,
      intersect: true,
      mode: "x",
      titleFontColor: "#000000", // '#89d0f5',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
    },
  };

  barChartLast6MonthLabels: Label[] = [];
  barChartLast6MonthType: ChartType = "bar";
  barChartLast6MonthLegend = true;
  barChartLast6MonthPlugins = [];

  public barChartLast6MonthData: ChartDataSets[] = [
    { data: [], label: "Cash In" },
    { data: [], label: "Cash Out" },
  ];

  //#endregion

  //#region Invoice Items
  barChartInvoiceOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [{ gridLines: { drawBorder: false }, display: false }],
    },
    tooltips: {
      backgroundColor: "rgba(255,255,255,0.9)",
      bodyFontColor: "#2b74bd",
      borderColor: "#d3d3d3",
      borderWidth: 1,
      cornerRadius: 0,
      caretPadding: 15,
      displayColors: false,
      enabled: true,
      intersect: true,
      mode: "x",
      titleFontColor: "#000000", // '#89d0f5',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
      callbacks: {
        label: function (tooltipItem, data) {
          return tooltipItem.index < 2
            ? "OverDue: " + tooltipItem.yLabel
            : "Outstanding: " + tooltipItem.yLabel;
        },
      },
    },
  };
  barChartInvoiceLabels: string[] = [];
  barChartInvoiceType: ChartType = "bar";
  barChartInvoiceLegend = false;
  barChartInvoicePlugins = [];
  barChartInvoiceColors = [
    {
      backgroundColor: [
        "#d6dade",
        "#d6dade",
        "#007bff",
        "#89d0f5",
        "#89d0f5",
        "#89d0f5",
      ],
    },
  ];
  barChartInvoiceData: number[] = [];

  //#endregion

  //#region Bill Items
  barChartBillOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [{ gridLines: { drawBorder: false }, display: false }],
    },
    tooltips: {
      backgroundColor: "rgba(255,255,255,0.9)",
      bodyFontColor: "#2b74bd",
      borderColor: "#d3d3d3",
      borderWidth: 1,
      cornerRadius: 0,
      caretPadding: 15,
      displayColors: false,
      enabled: true,
      intersect: true,
      mode: "x",
      titleFontColor: "#000000", // '#89d0f5',
      titleMarginBottom: 10,
      xPadding: 15,
      yPadding: 15,
      callbacks: {
        label: function (tooltipItem, data) {
          return tooltipItem.index < 2
            ? "OverDue: " + tooltipItem.yLabel
            : "Outstanding: " + tooltipItem.yLabel;
        },
      },
    },
  };

  barChartBillLabels: string[] = [];
  barChartBillType: ChartType = "bar";
  barChartBillLegend = false;
  barChartBillPlugins = [];
  barChartBillData: number[] = [];
  barChartBillColors = [
    {
      backgroundColor: [
        "#d6dade",
        "#d6dade",
        "#007bff",
        "#89d0f5",
        "#89d0f5",
        "#89d0f5",
      ],
    },
  ];

  //#endregion

  goToPaymentHistoryPage(paymentType: number) {
    this.router.navigate(['acounting/return-payment-history/Bill/', paymentType]);
  }
}
