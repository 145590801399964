
<app-dashboard>
    <div class="headSection marginNegative5">
        <div class="row borderBottom1Px pb-1">
            <div class="col-md-3 d-flex" style="padding: 0px 15px;">
                <div class="d-block">
                    <div class="pointer" (click)="backToPreviousPage()">
                        <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                        <span class="subTextTitle f-ss-14px">Home</span>
                    </div>
                    <span class="mainTextTitle f-ssb-14px" style="width: 220px;">Todays's Transaction</span>
                </div>
            </div>
 
        </div>
    </div>
    <div class="card">
        <div class="card-body wd">
          <div class="row col-sm-12">
            <div class="col-sm-6">
                <p class="mrt">Today's Cash In</p>
                <p class="txt">  {{CashIn | currency:'':''}} {{currencyCode}}</p>
            </div>
            <div class="col-sm-6">
                <p class="mrt">Today's Cash Out</p>
                <p class="txt">  {{CashOut | currency:'':''}} {{currencyCode}}</p>
               </div>
          </div>
        </div>
      </div>
    <div id="printGeneralReport" >
        <div class="customReportPage">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize">Today's Transaction Reports</p>
           </div>
           <div class="row ml-0">
               <span>{{companyName}}</span>
               
               <p><span class="ml-2 mr-2 f-ss-14px">|</span>{{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>
      
            <div class="sticky">
            <div id="tableHeader" class="tableHeader d-flex">
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Date</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Source</p>
                </div>
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Description</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1" style="float: left;">Reference</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Debit</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Credit</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Balance</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Gross</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">VAT</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">AIT</p>
                </div>
            </div>
        </div>
 
        <div id="tableBody"  class="AccountSection" *ngFor="let item of allAccountTransaction">
            <div class="x-grid-group-title  f-ssb-14px">
                <h1 style="border-bottom: 1px solid #999;">{{item.Name}}</h1>
            </div>
            <div class="d-flex f-ss-14px" [ngClass]="{
                'paddingTopBottom': itemInner.IsOpeningBalance,
                'normalRowSpaceing': !itemInner.IsOpeningBalance
            }" *ngFor="let itemInner of item.Items">
                <div class="tableHeaderColumn2 col-md-2">
                    <span *ngIf="itemInner.IsOpeningBalance" class="runningBalanceText">{{itemInner.SourceName}}</span>
                    <span *ngIf="!itemInner.IsOpeningBalance" class="runningBalanceText">{{convertDateIntoDisplay(itemInner.PostingDate)}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="tableHeaderText"></span>
                </div>
                <div class="tableHeaderColumn2 col-md-2">
                    <span class="tableHeaderText">{{itemInner.Narration}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="tableHeaderText">{{itemInner.ReferenceNo}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{itemInner.Debit ? itemInner.DebitView : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.Credit ? itemInner.CreditView : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.RunningBalanceValue ? itemInner.RunningBalanceValue : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.GrossAmountValue ? itemInner.GrossAmountValue : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.TaxAmount ? itemInner.TaxAmount : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.AdvanceTaxAmount ? itemInner.AdvanceTaxAmount : '-'}}</span>
                </div>
            </div>

            <div class="d-flex normalRowSpaceing f-ss-14px">
                <div class="tableHeaderColumn2 col-md-6">
                    <span class="runningBalanceText font-weight-bold colorBlack">Total {{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{item.DebitTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.CreditTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.GrossAmountTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.TaxAmountTotal}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.TotalAdvanceTaxAmount}}</span>
                </div>
            </div>
            <div  *ngIf="item.HasClosingBalance" class="paddingTopBottom d-flex f-ss-14px ">
                <div class="tableHeaderColumn2 col-md-6">
                    <span class="runningBalanceText">Closing Balance</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right" *ngIf="item.IsClosingBalanceAsDebit">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right" *ngIf="!item.IsClosingBalanceAsDebit">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
            </div>   
        </div>
        <div class="paddingTopBottom mr-t-20px d-flex f-ss-14px" style="background-color: #f9f9f9;">
            <div class="tableHeaderColumn2 col-md-2">
                <span class="runningBalanceText">Total</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-2">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1 ml-1">
                <span class="float-right">{{totalDebit}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalCredit}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalGrossAmount}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalTaxAmount}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalAdvanceTaxAmount}}</span>
            </div>
        </div>
    </div>
    </div>
</app-dashboard>

