<app-dashboard>
    <div [hidden]="!invoiceFound">
        <app-page-header-title mainTitle=" {{InvoiceData.IsReverseTransaction == true ? 'Reverse Invoice' : 'Invoice'}} / {{InvoiceData.InvoicceNo}} / {{InvoiceData.StatusName}}" subTitle="Back" backURL="{{redirectUrl}}" rightAcion="{{InvoiceData.InvoicceNo ?  true : false }}">
            <div class="full-width text-align-right">
                <div class="dropdown">
                    <button type="button" id="attachment_btn" class="btn attachment-button" style="z-index: 1;">
                        <i id="attachment_btn" style="font-size: 20px !important;" class="material-icons">insert_drive_file</i>
                        <span id="attachment_btn" *ngIf="totalFile>0" style="color:darkgrey; font-size: 12px;">{{totalFile}}</span>
                    </button>
                    <div class="x-tip-tl" style="height: 0px;">
                        <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                    </div>
                    <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                        <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="1"></app-attachment-uploader>
                    </div>
                </div>
                <button *ngIf="InvoiceData.StatusId == 1 || InvoiceData.StatusId == 7" type="button" [routerLink]="['/income/edit-invoice',InvoiceId]" class="eCount-def-btn ml-2">Edit</button>
                <button *ngIf="InvoiceData.StatusId == 1 || InvoiceData.StatusId == 7" type="button" (click)="submitInvoiceData()" class="eCount-def-btn ml-4">Submit</button>
                <button *ngIf="canApprove && InvoiceData.StatusId == 2" type="button" style="background: red;" (click)="confirmInvoiceRejection()" class="eCount-def-btn ml-4">Reject</button>
                <button *ngIf="canApprove && (InvoiceData.StatusId == 1 || InvoiceData.StatusId == 2)" type="button" style="background: #5cac00;" (click)="ApproveSingleInvoice()" class="eCount-def-btn ml-4">Approve</button>
                <button *ngIf="InvoiceData.StatusId == 3 && isAcceptDeliveryPartner == false && InvoiceData?.DeliveryPartnerItems?.length > 0" type="button" (click)="openDPMakePayment()" class="eCount-def-btn ml-4">Accept payment By Delivery Partner</button>
                <button *ngIf="InvoiceData?.StatusId == 3 && isPaymentBtnVisible && (InvoiceData?.DeliveryPartnerItems?.length  == 0 || isAcceptDeliveryPartner == true)" type="button" (click)="openMakePayment()" class="eCount-def-btn ml-4">
                    <span *ngIf="InvoiceData?.IsReverseTransaction != true">Accept payment</span>
                    <span *ngIf="InvoiceData?.IsReverseTransaction == true">Make payment</span>
                </button>
                <button type="button" (click)="print(InvoiceData.InvoicceNo)" class="eCount-button3 ml-4">Print</button>
                <div style="padding: 7px 10px; color: #c7c2c2; display: inline-flex;" *ngIf="canShowDropdown()">
                    <div class="dropdown">
                        <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                            <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                        </div>
                        <div class="dropdown-menu context-menu-wrapper pl-0 pr-0" aria-labelledby="navbarDropdown">
                            <div class="context-menu-item" *ngIf="canShowEditDropdownButton()" (click)="onEditableRoute()">
                                <span class="context-menu-text text-center" style="padding: 10px;">Edit</span>
                                <hr style="margin: 5px 0;">
                            </div>
                            <div class="context-menu-item" *ngIf="canShowDeleteDropdownButton()" (click)="confirmDeletion()">
                                <span class="context-menu-text text-center" style="padding: 10px">Delete</span>
                                <hr style="margin: 5px 0;">
                            </div>
                            <div class="context-menu-item" *ngIf="canShowResetToDraftDropdownButton()" (click)="ResetToDraft()">
                                <span class="context-menu-text text-center" style="padding: 10px">Reset To Draft</span>
                                <hr style="margin: 5px 0;">
                            </div>
                            <div class="context-menu-item" *ngIf="canShowDiscountDropdownButton()" (click)="openDiscountPopup()">
                                <span class="context-menu-text text-center" style="padding: 10px">Discount</span>
                                <hr style="margin: 5px 0;">
                            </div>
                            <div class="context-menu-item" *ngIf="canShowAdvanceAdjustmentDropdownButton()" (click)="makeAdjustment()">
                                <span class="context-menu-text text-center" style="padding: 10px">Advance Adjustment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-page-header-title>
        <div *ngIf="(InvoiceData.StatusId == 4 || isInAwaitingPaymentPhase) && InvoiceData.OrderNo == null && !isAllItemsDescriptiveOrService">
            <div class="contianer full-width" style="margin-bottom: 15px; margin-top: 0px;">
                <div class="row nav nav-tabs d-flex justify-content-end" id="myTab" role="tablist">
                    <div class="col-md-10 nav-item nav-bar"></div>
                    <div class="col-md-2 nav-item nav-link nav-bar" [routerLink]="['/income/invoice-delivery-order-list', invoiceNumber, 'All']">
                        <a>Delivery Orders({{deliveryData}})</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="printInvoice" [hidden]="!invoiceFound">
            <div class="m">
                <div class="row f-ss-14px">
                    <div class="col-md-12 d-flex">
                        <div class="col-md-3 d-block negmargin">
                            <p class="ToHeading">To</p>
                            <div>Name : {{InvoiceData.Contacts ? InvoiceData.Contacts.CompanyName : ''}}</div>
                            <div>Email : {{InvoiceData.Contacts ? InvoiceData.Contacts.Email : ''}}</div>
                            <div>Address : {{InvoiceData.Contacts ? InvoiceData.Contacts.StreetAddress : ''}}</div>
                            <div>Phone No : {{InvoiceData.Contacts ? InvoiceData.Contacts.ContactNo : ''}}</div>
                        </div>
                        <div class="col-md-3">
                            <p class="ToHeading">Date</p>
                            <div>Invoice Date : {{convertDateIntoDisplay(InvoiceData.InvoiceDate)}}</div>
                            <div>Due Date : {{convertDateIntoDisplay(InvoiceData.DueDate)}}</div>
                        </div>
                        <div class="col-md-6">
                            <p class="ToHeading">Invoice Details</p>
                            <div>Invoice NO # {{InvoiceData.InvoicceNo}}</div>
                            <div *ngIf="InvoiceData.OrderNo == null">Reference No # {{InvoiceData.ReferenceNo}} </div>
                            <div *ngIf="InvoiceData.OrderNo != null">Reference No # <a [routerLink]="['/sales/sales-order-details',InvoiceData.SalesOrderId]">{{InvoiceData.OrderNo}}</a></div>
                            <div>Payment Status: {{InvoiceData.StatusName}}</div>
                            <div *ngIf="InvoiceData.StatusId == 7">Reject Reason : <span class="text-red">{{InvoiceData.RejectReason}} </span></div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row f-ss-14px">
                    <div class="col-md-12 tax exchange">
                        <div class="float-right">
                            <label> Amounts are </label>
                            <strong *ngIf="InvoiceData.TaxTypeId==1"> VAT and AIT Exclusive </strong>
                            <strong *ngIf="InvoiceData.TaxTypeId==2"> VAT and AIT Inclusive </strong>
                            <strong *ngIf="InvoiceData.TaxTypeId==3"> No VAT and AIT </strong>
                        </div>
                    </div>
                    <div class="col-md-12 invoice-grid">
                        <table class="w-100" border="1|0">
                            <thead>
                                <tr>
                                    <td>Item</td>
                                    <td>Description</td>
                                    <td>Quantity</td>
                                    <td *ngIf="(salesOrderNo == null || salesOrderNo == '') && !isAllItemsDescriptiveOrService">Shipped</td>
                                    <td>Unit Price</td>
                                    <td>Discount%</td>
                                    <td>Account</td>
                                    <td>VAT Rate</td>
                                    <td>AIT Rate</td>
                                    <td class="amount">
                                        <div class="float-right">Amount {{currencyCode}}</div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of InvoiceData.InvoiceItems; let i = index;">
                                    <td>{{formatItem(item.ItemId)}}</td>
                                    <td>{{item.Description}}</td>
                                    <td>
                                        {{item.Quantity}}
                                        <span *ngIf="canShowDangerIcon(item, i)" id="dangerBtn" class="deletebtn">
                                            <img style="height: 33px;" src="../../assets/icons/danger2.jpg" />
                                        </span>
                                        <span *ngIf="canShowSuccessIcon(item, i)" id="dangerBtn" class="deletebtn">
                                            <img style="height: 33px" src="../../assets/icons/successImg.jpg" />
                                        </span>
                                    </td>
                                    <td *ngIf="canShowShipped(item, i)" [ngStyle]="{'color': isShippedMaxOrMin(item.Quantity, InvoiceData.ShippedItems[i])? 'red' : '#444'}">{{InvoiceData.ShippedItems[i]}}</td>
                                    <td *ngIf="(salesOrderNo == null || salesOrderNo == '') && !isAllItemsDescriptiveOrService && item.isDescriptiveOrServiceItem">-</td>
                                    <td>{{twoDecimalPlace(item.UnitPrice) | currency:'':''}}</td>
                                    <td>{{twoDecimalPlace(item.DiscountPercent)}}</td>
                                    <td class="editable-cell">{{item.Account}}</td>
                                    <td>{{formatTaxRate(item.TaxRateId)}}</td>
                                    <td>{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                    <td>
                                        <div class="float-right">{{twoDecimalPlace(item.Quantity * item.UnitPrice) | currency:'':''}}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row f-ss-14px">
                    <div class="container-bottom d-flex w-100">
                        <div class="col-md-8 col-sm-12 row">
                            <div class="form-group col-sm-5">
                                <label>Notes </label>
                                <div class="textarea-wrapper">
                                    <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea" placeholder="Write down some important notes using only 250 characters">{{InvoiceData.Notes}}</textarea>
                                    <span class="remaning">{{myInput.value.length}}/250</span>
                                </div>
                            </div>
                            <div class="col-sm-7 row" [hidden]="InvoiceData.InvoicceNo ==undefined">
                                <div class="col-sm-3" *ngIf="InvoiceData.IsRepeating===true">
                                    <label style="font-weight: 600;">Repeating</label>
                                    <label class="switch">
                                        <input class="switch-input" type="checkbox" [(ngModel)]="InvoiceData.IsRepeating">
                                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                </div>
                                <div class="col-sm-7 ml-4" *ngIf="InvoiceData.IsRepeating===true">
                                    <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types</label>
                                    <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id" [(ngModel)]="InvoiceData.RepeatingTypeId"></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 p-0 d-block">
                            <div class="d-flex w-100">
                                <div class="col-md-8">
                                    <label>Subtotal</label>
                                </div>
                                <div class="col-md-4 float-right">
                                    <p class="float-right pr-3">{{twoDecimalPlace(itemFullAmount) | currency:'':''}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100" *ngIf="InvoiceData?.DeliveryPartnerItems?.length > 0">
                                <div class="col-md-8">
                                    <label class="TableHeading">Delivery Charge</label>
                                </div>
                                <div class="col-md-4">
                                    <p class=" float-right pr-3">{{twoDecimalPlace(InvoiceData?.DeliveryPartnerItems[0]?.UnitPrice) | currency:'':''}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 pb-1" *ngIf="InvoiceData?.TaxAmount != 0 && InvoiceData?.TaxAmount != null">
                                <div class="col-md-8">
                                    <label>Total VAT</label>
                                </div>
                                <div class="col-md-4">
                                    <p class="float-right pr-3">{{InvoiceData.TaxAmount}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 pb-1" *ngIf="InvoiceData?.AdvanceTaxAmount != 0 && InvoiceData?.AdvanceTaxAmount != null">
                                <div class="col-md-8">
                                    <label>Total AIT</label>
                                </div>
                                <div class="col-md-4">
                                    <p class="float-right pr-3">{{InvoiceData.AdvanceTaxAmount}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 singleLine">
                                <span></span>
                            </div>
                            <div class="d-flex w-100 pt-2">
                                <div class="col-md-8">
                                    <label class="totalTextValue">TOTAL</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(itemFullAmount + totalVat)| currency:'':''}}</p>
                                </div>
                            </div>
                            <div *ngIf="totalDiscountParcent" class="d-flex w-100">
                                <div class="col-md-8">
                                    <label class="TableHeading">Discount</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(totalItemDiscountAmount)| currency:'':''}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 pt-2" *ngIf="InvoiceData?.DiscountAmount>0">
                                <div class="col-md-8">
                                    <label class="TableHeading">Additional Discount</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(InvoiceData?.DiscountAmount)| currency:'':''}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 pt-2" *ngIf="InvoiceData.DeliveryBillNo != null">
                                <div class="col-md-8">
                                    <label class="text-blue cursor-pointer" (click)="navigate()">Delivery Bill No : {{InvoiceData.DeliveryBillNo}}</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{InvoiceData.DeliveryCharge}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 pt-2" *ngFor="let item of acceptPayment">
                                <div class="col-md-8">
                                    <label *ngIf="!item.IsAdjustAdvance && InvoiceData.IsReverseTransaction != true">Accept Payment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                    <label *ngIf="!item.IsAdjustAdvance && InvoiceData.IsReverseTransaction == true">Return Payment- {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                    <label *ngIf="item.IsAdjustAdvance">Accept Prepayment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(item.Amount)| currency:'':''}}</p>
                                </div>
                            </div>
                            <div class="d-flex w-100 singleLine">
                                <span></span>
                            </div>
                            <div class="d-flex w-100 pt-2">
                                <div class="col-md-8">
                                    <label>Amount due</label>
                                </div>
                                <div class="col-md-4 font-weight-bold">
                                    <p class="float-right pr-3 totalTextValue">{{ getTotalDueCurrencyFormatter() }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-5 f-ss-14px" [hidden]="!acceptPayment.length">
                    <div class="col-md-12 invoice-grid">
                        <h3 *ngIf="InvoiceData.IsReverseTransaction != true">Accept Payment Statements</h3>
                        <h3 *ngIf="InvoiceData.IsReverseTransaction == true">Return Payment Statements</h3>
                        <table class="w-100" border="1|0">
                            <thead>
                                <tr>
                                    <td *ngIf="InvoiceData.IsReverseTransaction != true"> Date Paid</td>
                                    <td *ngIf="InvoiceData.IsReverseTransaction == true"> Date Return</td>
                                    <td *ngIf="InvoiceData.IsReverseTransaction != true">Received at</td>
                                    <td *ngIf="InvoiceData.IsReverseTransaction == true">Returned at</td>
                                    <td>Payment Mode</td>
                                    <td>Reference No</td>
                                    <td>Account Name</td>
                                    <td>Account No.</td>
                                    <td>Bank Name</td>
                                    <td class="amount">Amount</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of deliveryPartnerPaymentList;let i= index">
                                    <td>{{convertDateIntoDisplay(item.PaidDate)}}</td>
                                    <td *ngIf="item.Account==null">{{InvoiceData.DeliveryPartnerName != null && i==0 ? InvoiceData.DeliveryPartnerName : '-'}}</td>
                                    <td>{{item.PaymentModeName}}</td>
                                    <td>{{item.ReferenceNo}}</td>
                                    <td>{{item.SourceAccountName}}</td>
                                    <td>{{item.SourceAccountNo}}</td>
                                    <td>{{item.SourceBankName}}</td>
                                    <td>{{twoDecimalPlace(item.Amount)| currency:'':''}}</td>
                                </tr>
                                <tr *ngFor="let item of acceptPayment;let i= index">
                                    <td>{{convertDateIntoDisplay(item.PaidDate)}}</td>
                                    <td *ngIf="item.Account ==null">Adjustment</td>
                                    <td *ngIf="item.Account!=null && item.Account.AccountTypeId==18">Cash: {{item.Account.AccountName}}</td>
                                    <td *ngIf="item.Account!=null && item.Account.AccountTypeId==16">Bank: {{item.Account.AccountName}}</td>
                                    <td *ngIf="item.Account!=null && item.Account.AccountTypeId==19">Digital Cash: {{item.Account.AccountName}}</td>
                                    <td>{{item.PaymentModeName}}</td>
                                    <td>{{item.ReferenceNo}}</td>
                                    <td>{{item.SourceAccountName}}</td>
                                    <td>{{item.SourceAccountNo}}</td>
                                    <td>{{item.SourceBankName}}</td>
                                    <td>{{twoDecimalPlace(item.Amount)| currency:'':''}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-smart-modal #MakePayment identifier="MakePayment">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" style="font-size: 17px; font-weight: 600;" *ngIf="!InvoiceData.IsReverseTransaction">Accept Payment</span>
            <span class="modalHeaderText pl-2" style="font-size: 17px; font-weight: 600;" *ngIf="InvoiceData.IsReverseTransaction">Make Payment</span>
            <hr>
            <div class="row pr-2 pl-2">
                <div class="col-md-12">
                    <div>
                        <form [formGroup]="AcceptPaymentForm" (ngSubmit)="submitMakePayment()" [ngClass]="{'submitted':ifSubmit===true}">
                            <div class="form-group">
                                <span class="formTitle">Date Paid</span>
                                <input class="form-control" type="date" formControlName="PaidDate" id="idtest1" data-date="" data-date-format="D MMM YYYY" (change)="show($event)">
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Amount Paid</span>
                                <input type="number" formControlName="Amount" class="form-control mt-1" placeholder="Amount Paid" required>
                            </div>
                            <div class="form-group">
                                <span class="formTitle" *ngIf="!InvoiceData.IsReverseTransaction">Received at</span>
                                <span class="formTitle" *ngIf="InvoiceData.IsReverseTransaction">Paid From</span>
                                <ng-select [items]="bankAccounts" formControlName="AccountId" (change)="getPaymentModeList(AcceptPaymentForm.value.AccountId)" bindLabel="BankAccount" bindValue="GLAccountId" required></ng-select>
                            </div>
                            <div class="form-group" *ngIf="accountTypeId == 0">
                                <span class="formTitle">Payment Mode</span>
                                <ng-select [items]="PaymentModeList" formControlName="PaymentModeId" bindLabel="Name" bindValue="Id"></ng-select>
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Reference No</span>
                                <input type="text" formControlName="ReferenceNo" class="form-control mt-1" placeholder="Reference No :  eg #reference">
                            </div>
                            <div class="form-group" *ngIf="accountTypeId == 0">
                                <span class="formTitle"> Account Name</span>
                                <input type="text" formControlName="SourceAccountName" class="form-control mt-1" placeholder="">
                            </div>
                            <div class="form-group" *ngIf="accountTypeId == 0">
                                <span class="formTitle"> Account No.</span>
                                <input type="text" formControlName="SourceAccountNo" class="form-control mt-1" placeholder="">
                            </div>
                            <div class="form-group" *ngIf="accountTypeId == 0">
                                <span class="formTitle"> Bank Name</span>
                                <input type="text" formControlName="SourceBankName" class="form-control mt-1" placeholder="">
                            </div>
                            <div class="form-group pt-4" style="float: right;">
                                <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Payment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #AddDiscount identifier="AddDiscount">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" style="font-size: 17px; font-weight: 600;">Add Discount</span>
            <hr>
            <div class="row pr-2 pl-2">
                <div class="col-md-12">
                    <div>
                        <form [formGroup]="AddDiscountFrom" (ngSubmit)="submitDiscountAmount()">
                            <div class="form-group">
                                <span class="formTitle">Amount Due</span>
                                <input type="number" formControlName="AmountDue" class="form-control mt-1" placeholder="Amount Due" disabled>
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Discount Amount</span>
                                <input type="number" formControlName="AdditionalDiscountAmount" (change)="DecreaseTotalAmount()" class="form-control mt-1" placeholder="Discount Amount" appDecimaNumericValidator>
                            </div>
                            <div class="form-group" *ngIf="InvoiceData.DiscountAmount!==null">
                                <span class="formTitle">Additional Discount Amount</span>
                                <input type="number" formControlName="DiscountAmount" class="form-control mt-1" (change)="DecreaseAddTotalAmount()" placeholder="Discount Amount" appDecimaNumericValidator>
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Total Amount After Discount</span>
                                <input type="text" formControlName="TotalAmount" class="form-control mt-1" placeholder="" appDecimaNumericValidator disabled>
                            </div>
                            <div class="form-group pt-4" style="float: right;">
                                <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #MakeDPPayment identifier="MakeDPPayment">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" style="font-size: 17px; font-weight: 600;">Accept Payment</span>
            <hr>
            <div class="row pr-2 pl-2">
                <div class="col-md-12">
                    <div>
                        <form [formGroup]="AcceptDPPaymentForm" (ngSubmit)="submitDPMakePayment()" [ngClass]="{'submitted':ifSubmit===true}">
                            <div class="form-group">
                                <span class="formTitle">Date Paid</span>
                                <input class="form-control" type="date" formControlName="PaidDate" id="idtest2" data-date="" data-date-format="D MMM YYYY" (change)="showDP($event)">
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Amount Paid</span>
                                <input type="number" formControlName="Amount" class="form-control mt-1" placeholder="Amount Paid" required readonly>
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Received at</span>
                                <ng-select [items]="allDeliveryPartner" formControlName="ContactId" bindLabel="CompanyName" bindValue="Id" [disabled]="true" required></ng-select>
                            </div>
                            <div class="form-group">
                                <span class="formTitle">Reference No</span>
                                <input type="text" formControlName="ReferenceNo" class="form-control mt-1" placeholder="Reference No :  eg #reference">
                            </div>
                            <div class="form-group pt-4" style="float: right;">
                                <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Payment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
</app-dashboard>