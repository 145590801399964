import { Component, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { IncomeServiceService } from "../../../service/Income/income-service.service";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { createInvoice, InvoiceItem } from "../../../models/income.model";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from "ngx-smart-modal";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { NgSelectComponent } from "@ng-select/ng-select";
import { DataService } from "../../../service/EventEmitter/data.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { ItemCreateComponent } from "../../Products/item-create/item-create.component";
import { CreateItem } from "../../../models/product.model";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { CreatePopupItemComponent } from "../../Products/create-popup-item/create-popup-item.component";
import { ItemType } from "src/app/enum/item-type";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";
@Component({
  selector: "app-edit-invoice-page",
  templateUrl: "./edit-invoice-page.component.html",
  styleUrls: ["./edit-invoice-page.component.css"],
})
export class EditInvoicePageComponent implements OnInit {
  public aModel: CreateItem = new CreateItem();
  baseurl = environment.baseUrl;
  baseImageUrl = environment.fileBaseUrl;
  allCustomer: any = [];
  taxTypeList: any = [];
  taxRateList: any = [];
  BillNotes: string;
  StatusId = 0;
  allSaleableItem: any = [];
  allChartOfAccount: any = [];
  isItemListUpdate = false;
  InvoiceId: string;
  subTotalAmount: number;
  ReferenceNo: string = "";
  percentDone: number;
  deliveryCharge = 0;
  isUploaded: boolean = false;
  canApprove: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  oldTypeId: number;
  uploadSuccess: boolean;
  Isconversion: boolean;
  loading = false;
  InvoiceData: any;
  allDeliveryPartner: any;
  invoiceFound: any;
  itemsTotalAmount: any;
  isPreView: boolean = false;
  acceptPayment: any;
  InvoiceTo: any;
  PostingModuleId: number = null;
  ExternalTransactionId: number = null;
  totalFile: number = 0;
  ifSubmit: boolean;
  InvoicceNo: string;
  ShowInvoiceNo: any;
  post: any;
  StatusName: string;
  actionType: number;
  public invoiceForm: FormGroup;
  public InvoiceItems: FormArray;
  public DeliveryPartnerItems: FormArray;
  repeatingTypeList: any = [
    { Id: 1, Name: "Daily" },
    { Id: 2, Name: "Weekly" },
    { Id: 3, Name: "Monthly" },
    { Id: 4, Name: "Yearly" },
  ];
  buttonActionType: number;
  constructor(
    private mainService: MainServiceService,
    private dataService: DataService,
    public ngxSmartModalService: NgxSmartModalService,
    private incomeService: IncomeServiceService,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private activeroute: ActivatedRoute,
    private IncomeService: IncomeServiceService,
    private settingService: SettingsServiceService,
    private fileEmitterService: FileEmitterService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.InvoiceId = this.activeroute.snapshot.paramMap.get("invoiceId");
    this.actionType = Number(this.activeroute.snapshot.paramMap.get("actionType"));
    this.initializeForm();
    this.getBillData(this.InvoiceId);
    this.getFormData();
    this.activeroute.queryParams.subscribe((params) => {
      this.Isconversion = params.Isconversion == undefined ? false : true;
    });
    this.dataService.change.subscribe((x) => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });
  }

  public addItem(fullData:any, x: any, i: number): void {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.push(this.createItem(fullData, x, i));
  }
  public addDeliveryItemItem(x: any): void {
    this.DeliveryPartnerItems = this.invoiceForm.get(
      "DeliveryPartnerItems"
    ) as FormArray;
    this.DeliveryPartnerItems.push(this.createDeliveryItem(x));
  }
  public addBlankItem(): void {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.push(this.createBlankItem());
  }
  initializeForm() {
    this.invoiceForm = this.fb.group({
      Id: [0],
      InvoicceNo: [""],
      CustomerId: [null, Validators.required],
      ReferenceNo: [this.ReferenceNo],
      InvoiceDate: [""],
      DueDate: [""],
      InvoiceAmount: [0],
      IsConversion: [this.Isconversion],
      TaxTypeId: [3],
      PostingModuleId: [this.PostingModuleId],
      TaxAmount: [""],
      AdvanceTaxAmount: [""],
      ExternalTransactionId: [this.ExternalTransactionId],
      Notes: [""],
      CreatedAt: [""],
      StatusId: [""],
      CreatedById: [""],
      IsActiveDeliveryPartner: [],
      IsRepeating: [""],
      RepeatingTypeId: [""],
      PaidAmount: [""],
      IsResetted: [],
      InvoiceItems: this.fb.array([]),
      DeliveryPartnerItems: this.fb.array([]),
    });
  }
  public createItem(fullData: any, x: any, i: number): FormGroup {
      return this.fb.group({
        Id: [x.Id],
        InvoiceId: [x.InvoiceId],
        ItemId: [x.ItemId],
        ItemTypeId: [x.ItemTypeId],
        IsDescriptiveOrServiceItem: [x.IsDescriptiveOrServiceItem],
        Description: [x.Description],
        Balance: [x.IsDescriptiveOrServiceItem == true ? "-" : x.Balance],
        Quantity: [x.Quantity],
        ShippedItems: [x.IsDescriptiveOrServiceItem == true ? "-" : fullData.data.ShippedItems[i]],
        UnitPrice: [x.UnitPrice],
        LineTotal: [x.LineTotal],
        DiscountPercent: [x.DiscountPercent],
        DiscountAmount: [x.DiscountAmount],
        AccountId: [x.AccountId],
        TaxRateId: [x.TaxRateId],
        AdvanceTaxRateId: [x.AdvanceTaxRateId]
      });
  }
  public createDeliveryItem(x): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      InvoiceId: [x.InvoiceId],
      DeliveryPartnerId: [x.DeliveryPartnerId],
      Description: ["Delivery Charge"],
      UnitPrice: [x.UnitPrice],
      LineTotal: [x.LineTotal],
    });
  }
  public createBlankDeliveryItem(): FormGroup {
    return this.fb.group({
      DeliveryPartnerId: [null],
      Description: ["Delivery Charge", Validators.required],
      UnitPrice: ["", Validators.required],
      LineTotal: [0, Validators.required],
    });
  }
  public createBlankItem(): FormGroup {
    return this.fb.group({
      ItemId: [null],
      ItemTypeId: [null],
      IsDescriptiveOrServiceItem: [null],
      Description: ["", Validators.required],
      Quantity: [0, Validators.required],
      ShippedItems: [0, Validators.required],
      UnitPrice: [0, Validators.required],
      LineTotal: [0, Validators.required],
      AccountId: [this.allChartOfAccount?.find((x) => x.AccountCode === "200")?.Id, Validators.required],
      TaxRateId: [null],
      DiscountPercent: [0],
      DiscountAmount: [null],
      AdvanceTaxRateId: [null],
    });
  }
  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }
  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }
  getBillData = (id) => {
    this.incomeService.getInvoicePermissionByInvoiceNo(id).subscribe((x) => {
      if (x) {
        this.InvoiceData = x.data;
        this.canApprove = x.canApprove;
        this.invoiceFound = true;
        this.StatusId = x.data.StatusId;
        this.StatusName = x.data.StatusName;
        this.oldTypeId = x.data.RepeatingTypeId;
        this.subTotalAmount = x.data.InvoiceAmount - x.data.TaxAmount - x.data.AdvanceTaxAmount;
        this.InvoicceNo = x.data.InvoicceNo;

        this.fileEmitterService.emitFileRefIdUpdate({id: this.InvoiceData.Id});
        this.generateSalesNo();
        this.GetInvoicePaymentList(this.InvoiceData.Id);
        this.setParentData(x.data);

        this.InvoiceData.InvoiceItems.forEach((element, i) => {
          let item = x.itemListInfo.find(x => x.Id == element.ItemId);
          element.Balance = item != null ? item.Balance : 0;
          element.ItemTypeId = item != null ? item.ItemTypeId : null;
          element.IsDescriptiveOrServiceItem = this.IsDescriptiveOrServiceTypeItem(element);
          this.addItem(x, element, i);
        });

        x.data.DeliveryPartnerItems.forEach((item) => {
          this.deliveryCharge += item.LineTotal;
          this.invoiceForm.get("IsActiveDeliveryPartner").patchValue(true);
          this.addDeliveryItemItem(item);
        });
        this.IsAllItemsDescriptiveOrService();
      }
    });
  };

  isDescriptiveInvoice() {
    return this.InvoiceData?.InvoiceItems?.every( x => x.ItemId == null );
  }

  generateSalesNo = () => {
    let val = this.InvoicceNo.toString();
    let pre = "", post = "";
    for (let i = 0; i < 6; i++) pre += val[i].toString();
    for (let i = 6; i < 10; i++) post += val[i].toString();
    this.invoiceForm.get("InvoicceNo").patchValue(post);
    this.InvoicceNo = post;
    this.ShowInvoiceNo = "INV-" + pre;
  };

  setParentData(x) {
    this.invoiceForm.controls["Id"].patchValue(x.Id);
    this.invoiceForm.controls["InvoiceDate"].patchValue(x.InvoiceDate);
    this.invoiceForm.controls["DueDate"].patchValue(x.DueDate);
    this.invoiceForm.controls["ReferenceNo"].patchValue(x.ReferenceNo);
    this.invoiceForm.controls["CustomerId"].patchValue(x.CustomerId);
    this.ShowInvoiceNo = "";
    let data = x.InvoicceNo.toString();
    this.post = "";
    for (let i = 0; i < 6; i++) this.ShowInvoiceNo += data[i];
    for (let i = 6; i < data.length; i++) this.post += data[i];
    this.invoiceForm.controls["InvoicceNo"].patchValue(this.post);
    this.invoiceForm.controls["TaxTypeId"].patchValue(x.TaxTypeId);
    this.invoiceForm.controls["Notes"].patchValue(x.Notes);
    this.invoiceForm.controls["TaxAmount"].patchValue(x.TaxAmount);
    this.invoiceForm.controls["AdvanceTaxAmount"].patchValue(x.AdvanceTaxAmount);
    this.invoiceForm.controls["InvoiceAmount"].patchValue(x.InvoiceAmount);
    this.invoiceForm.controls["IsRepeating"].patchValue(x.IsRepeating);
    this.invoiceForm.controls["RepeatingTypeId"].patchValue(x.RepeatingTypeId);
    this.invoiceForm.controls["IsConversion"].patchValue(x.IsConversion);
    this.invoiceForm.controls["PostingModuleId"].patchValue(x.PostingModuleId);
    this.invoiceForm.controls["ExternalTransactionId"].patchValue(x.ExternalTransactionId);
    this.invoiceForm.controls["CreatedAt"].patchValue(x.CreatedAt);
    this.invoiceForm.controls["CreatedById"].patchValue(x.CreatedById);
    this.invoiceForm.controls["StatusId"].patchValue(x.StatusId);
    this.invoiceForm.controls["PaidAmount"].patchValue(x.PaidAmount);
    this.invoiceForm.controls["IsResetted"].patchValue(x.IsResetted);
    this.show(x.InvoiceDate);
    this.showSelectedDate(x.DueDate);
    this.InvoicceNo = x.InvoicceNo;
  }

  GetInvoicePaymentList = (id) => {
    this.itemsTotalAmount = 0;
    this.spinner.show();
    this.IncomeService.GetInvoicePaymentList(id).subscribe((x) => {
      this.acceptPayment = x;
      this.spinner.hide();
      for (let j = 0; j < this.acceptPayment.length; j++) {
        this.itemsTotalAmount += this.acceptPayment[j].Amount;
      }
      if (this.InvoiceData.InvoiceAmount == undefined) {
        this.spinner.show();
      }
    });
  };

  getFormData = () => {
    this.getAllCustomer();
    this.getAllSaleableItem();
    this.getAllChartOfAccount();
    this.getTaxTypeList();
    this.getTaxRateList();
  };

  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
    });
  };
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ Id: "", Name: "Select" });
    });
  };

  changesellableItems = (index) => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    for (let i = 0; i < this.allSaleableItem.length; i++) {
      if (this.allSaleableItem[i].Id == this.InvoiceItems.controls[index].value.ItemId) {
        this.InvoiceItems.controls[index].get("ItemId").patchValue(this.allSaleableItem[i]["Id"]);
        this.InvoiceItems.controls[index].get("ItemTypeId").patchValue(this.allSaleableItem[i]["ItemTypeId"]);
        this.InvoiceItems.controls[index].get("IsDescriptiveOrServiceItem").patchValue(this.IsDescriptiveOrServiceTypeItem(this.allSaleableItem[i]));
        this.InvoiceItems.controls[index].get("ShippedItems").patchValue( this.IsDescriptiveOrServiceTypeItem(this.allSaleableItem[i])  == true ? '-' : 0 );
        this.InvoiceItems.controls[index].get("AccountId").patchValue(this.allSaleableItem[i]["SaleAccountId"]);
        this.InvoiceItems.controls[index].get("Quantity").patchValue(1);
        this.InvoiceItems.controls[index].get("UnitPrice").patchValue(this.allSaleableItem[i]["SaleUnitPrice"]);
        this.InvoiceItems.controls[index].get("Description").patchValue(this.allSaleableItem[i]["SalesDescription"]);
        this.updateDataInvoiceItem(this.InvoiceItems.controls[index].value.ItemId, index);
      }
    }
  };

  openItemModal(selectItem: NgSelectComponent) {
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.refId = 2;
    modalRef.result.then(
      (result) => {
        this.getAllSaleableItem();
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }
  show(e) {
    const value = this.invoiceForm.value.InvoiceDate;
    $("#eitest1").attr("min", value.split("T")[0]);
    $("#eitest")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  showSelectedDate(e) {
    const value = this.invoiceForm.value.DueDate;
    $("#eitest1")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  getAllCustomer = () => {
    this.incomeService.GetAllContactWithDeliveryPartner().subscribe((x: any) => {
        this.allCustomer = x.filter((x) => x.IsCustomer == true);
        this.allCustomer.forEach((element) => {
          if (element.ContactNo != null) {
            element.CompanyName = element.CompanyName + " " + element.ContactNo;
          }
        });
        this.allDeliveryPartner = x.filter((x) => x.IsDeliveryPartner == true);
      });
  };

  getAllSaleableItem = () => {
    this.incomeService.GetAllSaleableItem().subscribe((x: []) => {
      this.allSaleableItem = x;
      if (this.allSaleableItem.length == 0) {
        this.allSaleableItem.push({
          ItemId: 0,
          Description: "",
          Quantity: 1,
          UnitPrice: "",
          LineTotal: "",
          AccountId: "",
          selected: true,
        });
      }
    });
  };

  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.RevenueList;
    });
  };

  changeSelectRowInItem = (itemId, index) => {};

  updateTotalPrice = () => {
    this.subTotalAmount = 0.0;
    let TaxAmount: number = 0.0;
    let AdvanceTaxAmount: number = 0.0;
    let totalPrice = 0.0;
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      this.subTotalAmount = this.subTotalAmount + Number(x.LineTotal);
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(x.LineTotal,x.AdvanceTaxRateId);
    });
    switch (this.invoiceForm.value.TaxTypeId) {
      case 1: //tax exclusive
        totalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        totalPrice = this.subTotalAmount;
        break;
    }
    //this.subTotalAmount += this.deliveryCharge;
    this.invoiceForm.get("TaxAmount").patchValue(TaxAmount.toFixed(2));
    this.invoiceForm.get("AdvanceTaxAmount").patchValue(AdvanceTaxAmount.toFixed(2));
    this.invoiceForm.get("InvoiceAmount").patchValue(totalPrice);
    if (this.invoiceForm.value.TaxTypeId === 3) {
      this.checkTaxType();
    }
  };

  checkTaxType = () => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      const myForm = (<FormArray>this.invoiceForm.get("InvoiceItems")).at(i);
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null,
      });
    });
  };
  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.0;
    if (taxRateId === "" || taxRateId === null) {
      return taxPrice;
    }
    var taxPercent = this.getTaxRatePercent(taxRateId);
    switch (this.invoiceForm.value.TaxTypeId) {
      case 1: //tax exclusive
        taxPrice = (lineTotal * taxPercent) / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(
          ((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2)
        );
        break;
      default:
        //no tax
        taxPrice = 0;
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter((x) => x.Id == taxRateId);
    console.log(taxRateObj);
    return taxRateObj[0].Parcent;
  }
  updateDataInvoiceItem = (itemId, index) => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      if(this.IsDescriptiveOrServiceTypeItem(x) == true) {
        this.InvoiceItems.controls[i].get("ShippedItems").patchValue('-');
        x.LineTotal = x.Quantity * x.UnitPrice;
        this.InvoiceItems.controls[i].get("LineTotal").patchValue(x.LineTotal);
      }
      else if (x.ItemId == itemId && i == index) {
        this.InvoiceItems.controls[i].get("ShippedItems").patchValue(x.ShippedItems);
        x.LineTotal = x.Quantity * x.UnitPrice;
        this.InvoiceItems.controls[i].get("LineTotal").patchValue(x.LineTotal);
        this.updateDiscountInvoiceItem(itemId, i);
      }
      this.updateTotalPrice();
    });
  };
  updateDiscountInvoiceItem = (itemId, index) => {
    let amount = 0.0;
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      if (x.ItemId == itemId && i == index) {
        x.LineTotal = x.UnitPrice * x.Quantity;
        amount = x.LineTotal - (x.DiscountPercent * x.LineTotal) / 100;
        this.InvoiceItems.controls[i].get("LineTotal").patchValue(amount);
        this.InvoiceItems.controls[i].get("DiscountAmount").patchValue(((x.DiscountPercent * x.LineTotal) / 100).toFixed(2));
        this.updateTotalPrice();
      }
    });
  };
  defaultAccountSettings = (item) => {
    const account = this.allChartOfAccount.filter((x) => x.AccountCode === "200");
    item.map((x) => {
      if (x.AccountId == null) {
        x.AccountId = account[0].Id;
      }
    });
  };

  removeInvoiceItem = (itemId, i) => {
    let data = [];
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, index) => {
      if (index === i) {
        this.InvoiceItems.removeAt(index);
      }
    });
    this.updateTotalPrice();
  };
  CreateNewCustomer = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: true,
      IsSupplier: false,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Contact has been successfully added !");
          this.loading = false;
          this.allCustomer.push(x["Data"]);
          this.getAllCustomer();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };
  checkValidation = (invoiceItem) => {
    var b = true;
    invoiceItem.forEach((element) => {
      if (element.Description == "" || element.AccountId == null || element.LineTotal <= 0 || (this.invoiceForm.value.TaxTypeId != 3 &&element.TaxRateId == null && element.AdvanceTaxRateId == null)) {
        b = false;
      }
    });
    return b;
  };
  private isValidData() {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    let check = this.checkValidation(this.InvoiceItems.value);
    if (this.invoiceForm.invalid) {
      this.toaster.warning("Required field can not be empty !", "Warning!");
      return false;
    } else if (this.InvoiceItems.value.length === 0) {
      this.toaster.warning("Please add at least one item to the invoice.", "No Item Added!");
      return false;
    } else if (!check) {
      this.toaster.warning("Required field can not be empty!");
      return false;
    }
    return true;
  }

  async checkInvoiceNo() {
    var val = this.ShowInvoiceNo + this.invoiceForm.get("InvoicceNo")?.value;
    if (val == "" || val == null) {
      this.toaster.error("Please Input Invoice No.!");
      return false;
    }
    let invoiceNo = this.InvoicceNo.toString();
    let concat = "";
    for (let i = 0; i < 6; i++) concat += invoiceNo[i];
    if (val.length != 10) {
      console.log(val.length);
      this.toaster.error("Invoice number needs to be four-digit numbers");
      return false;
    }

    for (let i = 0; i < concat.length; i++) {
      if (val[i] != concat[i]) {
        this.toaster.error("Invoice number must start with " + concat + " and ends with four-digit numbers"
        );
        return false;
      }
    }
    return true;
  }

  saveAsSubmitInvoice = async () => {
    this.buttonActionType = iQuidiActionType.Submit;
    if (!(await this.checkInvoiceNo())) return;
    //this.invoiceForm.value.IsActiveDeliveryPartner === true ? this.invoiceForm.get("StatusId").patchValue(3) : this.invoiceForm.get("StatusId").patchValue(2);
    
    this.updateDraftCreateInvoice();
  };
  updateDraftInvoice = async () => {
    this.buttonActionType = iQuidiActionType.Save;
    if (!(await this.checkInvoiceNo())) return;
    this.StatusId == 7 ? this.invoiceForm.get("StatusId").patchValue(7) : this.invoiceForm.get("StatusId").patchValue(1);
    this.updateDraftCreateInvoice();
  };
  saveCreateInvoice = async () => {
    if (!(await this.checkInvoiceNo())) return;
    this.ifSubmit = true;
    if (!this.isValidData()) return;
    this.Isconversion ? this.saveConverionInvoiceData() : this.saveDefaultInvoiceData();
  };

  saveDefaultInvoiceData() {
    this.ifSubmit = true;
    this.spinner.show();
    let preValue = "";
    for (let i = 0; i < this.ShowInvoiceNo.length; i++) preValue += this.ShowInvoiceNo[i].toString();
    this.invoiceForm.value.InvoicceNo = preValue + this.invoiceForm.value.InvoicceNo.toString();
    const data = this.invoiceForm.value;
    data.IQuidiActionType = iQuidiActionType.Approve
    this.incomeService.Update(data).subscribe((x) => {
        this.displaySuccessResponse(x, `Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully approved.`, "income/invoice-overview/All");
      });
  }
  updateDraftCreateInvoice = () => {
    this.ifSubmit = true;

    if (!this.isValidData()) return;
    this.Isconversion ? this.saveConverionInvoiceData() : this.updateAsaDraftInvoiceData();
  };

  private displaySuccessResponse(x: any, sMessage: string, rUrl: string) {
    if (x.Success) {
      this.toaster.success(sMessage, "Success!");
      this.router.navigate([rUrl]);
      this.spinner.hide();
    } else {
      this.toaster.warning(x.Message);
      this.spinner.hide();
    }
  }

  updateAsaDraftInvoiceData() {
    this.ifSubmit = true;
    this.spinner.show();
    let preValue = "";
    let temp = this.invoiceForm.value.InvoicceNo;
    for (let i = 0; i < this.ShowInvoiceNo.length; i++)
      preValue += this.ShowInvoiceNo[i].toString();
    this.invoiceForm.value.InvoicceNo = preValue + this.invoiceForm.value.InvoicceNo.toString();
    const data = this.invoiceForm.value;
    data.IQuidiActionType = this.buttonActionType;
    this.incomeService.Update(data).subscribe((x) => {
        this.displaySuccessResponse(x, `Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully updated.`, "income/invoice-overview/All");
      });
  }

  saveConverionInvoiceData() {
    this.ifSubmit = true;
    this.spinner.show();
    this.incomeService.saveConverionInvoiceData(this.invoiceForm.value).subscribe((x) => {
        this.displaySuccessResponse(x, `Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully send`, "accounting/historic-conversionbalancesInvoice");
      });
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    let open = document.getElementById("attachmentDropdown").classList.contains("show");
    open ? document.getElementById("custom_notch").classList.add("show") : document.getElementById("custom_notch").classList.remove("show");
  }

  fileName: string;
  fileType: string;

  previewOfInvoice() {
    this.isPreView = this.isPreView ? false : true;
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  };

  changeType = () => {
    if (this.invoiceForm.value.IsRepeating === false) {
      this.invoiceForm.get("RepeatingTypeId").patchValue(null);
    } else {
      this.invoiceForm.get("RepeatingTypeId").patchValue(this.oldTypeId);
    }
  };
  getContactNo = () => {
    const customer = this.allCustomer.filter((x) => x.Id == this.invoiceForm.value.CustomerId);
    if (customer.length > 0) {
      this.invoiceForm.get("ContactNo").patchValue(customer[0].ContactNo);
    }
  };
  updateTotalAfterDeliveryCharge = () => {
    //this.subTotalAmount -= this.deliveryCharge;
    this.invoiceForm.get("InvoiceAmount").patchValue(this.invoiceForm.value.InvoiceAmount);
    this.deliveryCharge = 0;
    this.DeliveryPartnerItems = this.invoiceForm.get("DeliveryPartnerItems") as FormArray;
    this.DeliveryPartnerItems.value.map((x, i) => {
      x.LineTotal = x.UnitPrice;
      this.deliveryCharge += x.UnitPrice;
      this.DeliveryPartnerItems.controls[i].get("LineTotal").patchValue(x.LineTotal);
    });
    //this.subTotalAmount += this.deliveryCharge;
    this.invoiceForm.get("InvoiceAmount").patchValue(this.invoiceForm.value.InvoiceAmount);
  };
  removeDeliveryItem = (i) => {
    this.DeliveryPartnerItems = this.invoiceForm.get("DeliveryPartnerItems") as FormArray;
    this.DeliveryPartnerItems.value.map((x, index) => {
      if (index === i) {
        this.DeliveryPartnerItems.removeAt(index);
      }
    });
    this.invoiceForm.get("IsActiveDeliveryPartner").patchValue(false);
    this.invoiceForm.get("InvoiceAmount").patchValue(this.invoiceForm.value.InvoiceAmount);
    //this.subTotalAmount -= this.deliveryCharge;
    this.deliveryCharge = 0;
  };
  toogleDeliveryPartner = () => {
    const value = this.invoiceForm.value.IsActiveDeliveryPartner;
    if (value == true) {
      this.DeliveryPartnerItems = this.invoiceForm.get("DeliveryPartnerItems") as FormArray;
      this.DeliveryPartnerItems.push(this.createBlankDeliveryItem());
    } else {
      this.removeDeliveryItem(0);
    }
  };

  async updateApprovedData() {
    if (!(await this.checkInvoiceNo())) return;
    this.spinner.show();
    let preValue = "";
    let temp = this.invoiceForm.get("InvoicceNo")?.value;
    for (let i = 0; i < this.ShowInvoiceNo.length; i++)
      preValue += this.ShowInvoiceNo[i].toString();
    this.invoiceForm.value.InvoicceNo = preValue + temp;
    const data = this.invoiceForm.value;
    if(this.actionType == iQuidiActionType.ResetToDraft)
      data.iQuidiActionType = iQuidiActionType.ResetToDraft;
    else
      data.iQuidiActionType = iQuidiActionType.Update;
    this.incomeService.Update(data).subscribe( (x) => {
      this.spinner.hide();
      if( x.Success )
      {
        this.toaster.success(x.Message);
        let rUrl = "income/invoice/INV-"+this.invoiceForm.value.InvoicceNo;
        this.router.navigate([rUrl]);
      }else{
        this.toaster.error(x.Message);
      }
    }); 
  }

  isShippedMaxOrMin(i){
    let item = this.InvoiceItems.controls[i];
    let Quantity = item.get("Quantity").value;
    let ShippedItem = item.get("ShippedItems").value
    let IsDescriptiveOrServiceItem = item.get("IsDescriptiveOrServiceItem").value
    if(Quantity != ShippedItem && ShippedItem != 0 && !IsDescriptiveOrServiceItem && IsDescriptiveOrServiceItem != null){
      return true;
    }
    else{
      return false;
    }
  }

  IsAllItemsDescriptiveOrService() {
    let invoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    let items = invoiceItems.value;
    let isAllAreDescriptiveAndServiceItems: boolean = items.filter( x => x.ItemId == null || x.ItemTypeId == ItemType.Service).length == items.length ? true : false;
    return isAllAreDescriptiveAndServiceItems;
  }

  IsDescriptiveOrServiceTypeItem(item) {
    let isDescriptiveItem: boolean = item.ItemId == null ? true : false;
    let isServiceItem: boolean = item.ItemTypeId == ItemType.Service ? true : false;
    return isDescriptiveItem || isServiceItem ? true : false;
  }

}
