import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ManualJournalService } from "src/app/service/Manual Journal/manual-journal.service";

@Component({
  selector: 'app-manual-journal-list',
  templateUrl: './manual-journal-list.component.html',
  styleUrls: ['./manual-journal-list.component.css']
})

export class ManualJournalListComponent implements OnInit {

  private gridApi;
  domLayout = "autoHeight";
  manualJournalList: any = [];
  manualJournalFilterList: any = [];
  postedManualJournalFilterList: any = [];
  draftCount: number = 0;
  postedCount: number = 0;
  currentMonthCount: number = 0;
  lastMonthCount: number = 0;
  lastTwoMonthCount: number = 0;
  isMonthTabOpen: Boolean = false;
  status: string;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private manualJournalService: ManualJournalService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get("status");
    this.getAllManualJournal();
  }

  columnDefs = [
    {
      headerName: "Date",
      field: "ManualJournalDate",
      valueFormatter: this.convertGridDateIntoDisplay
    },
    { 
      headerName: "Narration", 
      field: "Narration" 
    },
    {
      headerName: "Debit",
      field: "SubTotalAmount",
      valueFormatter: this.convertToLocaleString
    },
    {
      headerName: "Credit",
      field: "SubTotalAmount",
      valueFormatter: this.convertToLocaleString
    }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    cellStyle: function (params) {
      if (params.column.colId == "ManualJournalDate") {
        return { color: "blue", cursor: "pointer" };
      }
    },
  };

  convertToLocaleString(params) {
    const amount = params.value;
    return amount?.toLocaleString();
  }

  getAllManualJournal = () => {
    this.spinner.show();
    this.manualJournalList = [];
    this.manualJournalService.getManualJournalList().subscribe((x) => {
      this.spinner.hide();
      this.manualJournalList = [...x, ...this.manualJournalList];
      this.manualJournalFilterList = this.manualJournalList;
      this.draftCount = this.manualJournalList.filter((y) => y.IsDraft == true).length;
      this.postedCount = this.manualJournalList.filter((y) => y.IsDraft == false).length;
      this.currentMonthCount = this.currentMonthManualJournalList(this.manualJournalFilterList.filter((y) => y.IsDraft == false)).length;
      this.lastMonthCount = this.lastMonthManualJournalList(this.manualJournalFilterList.filter((y) => y.IsDraft == false)).length;
      this.lastTwoMonthCount = this.lastTwoMonthsManualJournalList(this.manualJournalFilterList.filter((y) => y.IsDraft == false)).length;
      this.reLoadRowData(this.status);
    });
  };

  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  onGridReady(event) {
    this.gridApi = event.api;
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  onRowClicked(event) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Date") {
      this.router.navigate([`/manual-journal/manual-journal-details/${event.data.Id}`]);
    }
  }

  convetToPDF = () => {
    var data = document.getElementById("head");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("ManualJournalList.pdf");
    });
  };

  reLoadRowData(data: any) {
    this.spinner.show();
    switch (data) {
      case "all":
        this.isMonthTabOpen = false;
        this.manualJournalFilterList = this.manualJournalList;
        break;
      case "Draft":
        this.isMonthTabOpen = false;
        this.manualJournalFilterList = this.manualJournalList.filter((y) => y.IsDraft == true);
        break;
      case "Posted":
        this.isMonthTabOpen = true;
        this.manualJournalFilterList = this.manualJournalList.filter((y) => y.IsDraft == false);
        break;
      case "Current_Month":
        this.isMonthTabOpen = true;
        this.manualJournalFilterList = this.currentMonthManualJournalList(this.manualJournalList.filter((y) => y.IsDraft == false));
        break;
      case "Last_Month":
        this.isMonthTabOpen = true;
        this.manualJournalFilterList = this.lastMonthManualJournalList(this.manualJournalList.filter((y) => y.IsDraft == false));
        break;
      case "Last_Two_Months":
        this.isMonthTabOpen = true;
        this.manualJournalFilterList = this.lastTwoMonthsManualJournalList(this.manualJournalList.filter((y) => y.IsDraft == false));
        break;
    }
    this.spinner.hide();
  }

  currentMonthManualJournalList(manualJournalList) {
    const filteredList = manualJournalList.filter((item) => {
      const isCurrentMonth = this.IsCurrentMonth(item.ManualJournalDate);
      return isCurrentMonth;
    });
    return filteredList;
  }

  IsCurrentMonth(manualJournalDate) {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const receivedMonth = new Date(manualJournalDate).getMonth();
    const receivedYear = new Date(manualJournalDate).getFullYear();

    return currentYear === receivedYear && currentMonth === receivedMonth;
  }

  lastMonthManualJournalList(manualJournalList) {
    const filteredList = manualJournalList.filter((item) => {
      const isLastMonth = this.IsLastMonth(item.ManualJournalDate);
      return isLastMonth;
    });
    return filteredList;
  }

  IsLastMonth(manualJournalDate) {
    const currentMonth = new Date().getMonth();
    const receivedMonth = new Date(manualJournalDate).getMonth();

    const monthDifference = currentMonth - receivedMonth;
    return (monthDifference != 0 && (monthDifference == 1 || monthDifference + 12 == 1));
  }

  lastTwoMonthsManualJournalList(manualJournalList) {
    const filteredList = manualJournalList.filter((item) => {
      const isLastTwoMonth = this.IsLastTwoMonth(item.ManualJournalDate);
      return isLastTwoMonth;
    });
    return filteredList;
  }

  IsLastTwoMonth(manualJournalDate) {
    const currentMonth = new Date().getMonth();
    const receivedMonth = new Date(manualJournalDate).getMonth();

    const monthDifference = currentMonth - receivedMonth;
    return (monthDifference != 0 && (monthDifference == 2 || monthDifference + 12 == 2));
  }
  
}

