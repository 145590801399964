import { Component, OnInit } from "@angular/core";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import * as moment from "moment";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ManualJournalService } from "src/app/service/Manual Journal/manual-journal.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-manual-journal",
  templateUrl: "./create-manual-journal.component.html",
  styleUrls: ["./create-manual-journal.component.css"],
  host: {'(document:click)': 'onWindowClick($event)'}
})

export class CreateManualJournalComponent implements OnInit {

  ManualJournalForm: FormGroup;
  ManualJournalItems: FormArray;
  allChartOfAccount: any[] = [];
  debitSubTotal: number = 0;
  creditSubTotal: number = 0;
  debitTotal: number = 0;
  creditTotal: number = 0;
  setDefaultDescription: boolean = false;
  totalFile: number = 0;

  constructor(
    private accountingService: AcountingServiceService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private manualJournalService: ManualJournalService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.getAllChartOfAccount();
  }

  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.ExpenseList;
    });
  };

  initializeForm() {
    this.ManualJournalForm = this.fb.group({
      Id: [0],
      Narration: ["", Validators.required],
      ManualJournalDate: ["", Validators.required],
      SubTotalAmount: [0],
      IsDraft: [true],
      DefaultDescriptionSettings: [false],
      DebitSubTotal: [0],
      CreditSubTotal: [0],
      DebitTotal: [0],
      CreditTotal: [0],
      ManualJournalItems: this.fb.array([this.createItem('')]),
    });
    this.ManualJournalForm.get("ManualJournalDate").patchValue(new Date());
    this.showSelectedDate(this.ManualJournalForm.value.ManualJournalDate);
  }

  createItem(itemDescription: string): FormGroup {
    return this.fb.group({
      Id: [0],
      ManualJournalId: [0],
      ItemDescription: [itemDescription, Validators.required],
      AccountId: [0],
      DebitAmount: [0],
      CreditAmount: [0],
    });
  }

  addItem(): void {
    this.ManualJournalItems = this.ManualJournalForm.get("ManualJournalItems") as FormArray;
    this.ManualJournalItems.push(this.createItem(this.ManualJournalForm.value.DefaultDescriptionSettings ? this.ManualJournalForm.value.Narration : ''));
  }

  removeItems(i: number) {
    this.ManualJournalItems = this.ManualJournalForm.get("ManualJournalItems") as FormArray;
    this.ManualJournalItems.value.map((x, index) => {
      if (index === i) {
        this.ManualJournalItems.removeAt(index);
      }
    });
  }

  changeItemsDescription(){
    if(this.ManualJournalForm.value.DefaultDescriptionSettings){
      this.setDefaultDescriptionValue(this.ManualJournalForm.value.Narration);
    }
  }

  changeDefaultDescriptionSettings() {
    if (this.setDefaultDescription == false || this.setDefaultDescription == undefined){
      this.setDefaultDescription = true;
      this.ManualJournalForm.controls['DefaultDescriptionSettings'].patchValue(true);
      this.setDefaultDescriptionValue(this.ManualJournalForm.value.Narration);
    }
    else{
      this.setDefaultDescription = false;
      this.ManualJournalForm.controls['DefaultDescriptionSettings'].patchValue(false);
    }
  }

  setDefaultDescriptionValue(description: string) {
    this.ManualJournalItems = this.ManualJournalForm.get("ManualJournalItems") as FormArray;
    this.ManualJournalItems.value.map((item, i) => {
      this.ManualJournalItems.controls[i].get('ItemDescription').patchValue(description);
    });
  }

  updateItems() {
    this.debitSubTotal = 0;
    this.creditSubTotal = 0;
    this.ManualJournalItems = this.ManualJournalForm.get("ManualJournalItems") as FormArray;
    this.ManualJournalItems.value.map((item, i) => {
      this.debitSubTotal += Number(item.DebitAmount);
      this.creditSubTotal += Number(item.CreditAmount);
    });
    this.debitTotal = this.debitSubTotal;
    this.creditTotal = this.creditSubTotal;
    this.ManualJournalForm.controls['DebitSubTotal'].patchValue(this.debitSubTotal);
    this.ManualJournalForm.controls['CreditSubTotal'].patchValue(this.creditSubTotal);
    this.ManualJournalForm.controls['DebitTotal'].patchValue(this.debitTotal);
    this.ManualJournalForm.controls['CreditTotal'].patchValue(this.creditTotal);
    if(this.debitTotal == this.creditTotal){
      this.ManualJournalForm.controls['SubTotalAmount'].patchValue(this.debitTotal);
    }
  }

    checkFormValidation() {
      const manualJournalItems = this.ManualJournalForm.get('ManualJournalItems').value;
      if(manualJournalItems.length != 0){
        const isItemDescriptionNull = manualJournalItems.some(item => item.ItemDescription == '' || item.ItemDescription == null || item.ItemDescription == undefined);
        if(isItemDescriptionNull) {
          this.toaster.warning('Please write description!');
          return false;
        }
        const isAccountIdZero = manualJournalItems.some(item => item.AccountId == 0);
        if(isAccountIdZero) {
          this.toaster.warning('Please select account!');
          return false;
        }
        const isAmountZero = manualJournalItems.some(item => item.DebitAmount == 0 && item.CreditAmount == 0);
        if(isAmountZero){
          this.toaster.warning('Please set debit/credit amount!');
          return false;
        }
      }
      if(this.ManualJournalForm.value.DebitTotal == 0 || this.ManualJournalForm.value.CreditTotal == 0) {
        this.toaster.warning('Total debits/credits can not zero!');
        return false;
      }
      if(this.ManualJournalForm.value.DebitTotal !=  this.ManualJournalForm.value.CreditTotal) {
        this.toaster.warning('Total debits must equal total credits!');
        return false;
      }
      if (!this.ManualJournalForm.valid) {
        this.toaster.warning('Please fill all the required fields!');
        return false;
      }
      return true;
    }

  saveManualJournal() {
    if(this.checkFormValidation()) {
      this.manualJournalService.saveManualJournal(this.ManualJournalForm.value).subscribe((x) => {
        if (x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manual-journal/manual-journal-list/all"]);
        } else {
          this.toaster.error("Manual Journal not saved!");
        }
        this.spinner.hide();
      });
    }
  }

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {}
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }

  showSelectedDate(e: Event) {
    const value = this.ManualJournalForm.value.ManualJournalDate;
    $("#smtest").on("change", function () {
        this.setAttribute("data-date", 
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          ));
      }).trigger("change");
  }
}
