<app-dashboard>
    <app-page-header-title mainTitle=" {{ setMainTitle() }}" subTitle="Manufacturing Orders List" backURL="/manufacture/manufacutring-order-list/All">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" style="background: #5cac00;" type="button" (click)="produceManufactureOrder()">Produce</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="ManufactureOrderForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-3">
                    <label for="">Product Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" readonly>
                </div>
                <div class="col-md-3">
                    <label for="">Formula Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FormulaName" readonly>
                </div>
                <div class="col-md-4">
                    <label for="">Quantity<span class="clr">*</span></label>
                    <span class="form-controlinputBox d-flex">
                        <input class="form-controlinputBox2 text-right pr-2" formControlName="Quantity" type="text" (change)="updateOrderItemsData()" appDecimaNumericValidator/>
                        <label style="font-size: 16px; padding-top: 0.5px;" ><span class="pr-2">/</span><span>{{ manufactureOrderProductionData?.ManufactureOrder?.ProductionQuantity }}</span></label>
                    </span>
                </div>
                <div class="col-md-2">
                    <label for="">Date<span class="clr">*</span></label>
                    <input class="form-control" id="createdDateId" type="date" formControlName="CreatedDate" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                </div>
                <div class="col-md-3 mt-3">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference">
                </div>
                <div class="col-md-3 mt-3">
                    <label for="">Batch No</label>
                    <input class="form-control" type="text" formControlName="BatchNo" readonly>
                </div>
                <div class="col-md-3 mt-3">
                    <label for="startDateId">Start Date<span class="clr">*</span></label>
                    <input class="form-control" id="startDateId" type="date" formControlName="StartDate" data-date="" data-date-format="D MMM YYYY" (change)="showStartSelectedDate($event)">
                </div>
                <div class="col-md-3 mt-3">
                    <label for="endDateId">End Date<span class="clr">*</span></label>
                    <input class="form-control" id="endDateId" type="date" formControlName="EndDate" data-date="" data-date-format="D MMM YYYY" (change)="showEndSelectedDate($event)">
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-1">
                        Item
                    </div>
                    <div class="col-md-3 text-center">
                        To Consume
                    </div>
                    <div class="col-md-1">
                        Reserved
                    </div>
                    <div class="col-md-2 text-center">
                        Used Items
                    </div>
                    <div class="col-md-1 text-center">
                        Broken Items
                    </div>
                    <div class="col-md-1">
                        Consumed
                    </div>
                    <div class="col-md-1">
                        Price/Unit
                    </div>
                    <div class="col-md-2 text-center">
                        Amount
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="OrderItems" *ngFor="let item of ManufactureOrderForm.get('OrderItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                             <div class="col-md-1">
                                <input class="form-control inputField" type="text" formControlName="RawMaterialId" disabled>
                            </div>
                            <div class="col-md-3 text-center">
                                <div class="d-inline-flex align-items-center ml-4">
                                    <span class="mt-2" style="font-size: 16px;">{{ item.value.ToConsume }}</span>
                                    <span class="mt-2" *ngIf="item.value.Consumed != 0">
                                        <img *ngIf="canShowOrderItemsStockIcon(item, i)" style="height: 20px;" src="../../assets/icons/successImg.jpg" />
                                        <img *ngIf="!canShowOrderItemsStockIcon(item, i)" style="height: 20px;" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <input class="form-control inputField" type="text" formControlName="Reserved" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2 inputFieldPadding">
                                <input class="form-control text-center" type="text" (change)="updateItemsData(item.ItemId)" formControlName="UsedItems" appDecimaNumericValidator>
                            </div>
                            <div class="col-md-1 inputFieldPadding">
                                <input class="form-control text-center" type="text" (change)="updateItemsData(item.ItemId)" formControlName="BrokenItems" appDecimaNumericValidator>
                            </div>
                            <div class="col-md-1">
                                <input class="form-control inputField" type="text" formControlName="Consumed" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-1">
                                <input class="form-control inputField" type="text" formControlName="UnitPrice" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2">
                                <input class="form-control text-center inputField" type="text" formControlName="LineTotal" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                        <hr class="pb-0 mb-0 pt-0 mt-0">
                    </div>
                    <hr class="pb-0 mb-0 pt-0 mt-0">
                    <div class="row d-flex">
                        <div class="col-md-10 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-2 text-left font-weight-bold pr-1" style="padding-left: 65px;">
                            {{ currencyFormatter(materialCost, '') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-block topSection f-ss-14px" *ngIf="canShowMiscellaneousItem()">
                <div class="font-weight-bold f-ss-20px">
                    Miscellaneous Item
                </div>
                <div class="d-flex">
                    <div class="col-md-2">
                        Item
                    </div>
                    <div class="col-md-2">
                        To Consume
                    </div>
                    <div class="col-md-2">
                        Reserved
                    </div>
                    <div class="col-md-2 text-center">
                        Consumed Items
                    </div>
                    <div class="col-md-2">
                        Price/Unit
                    </div>
                    <div class="col-md-2 text-center">
                        Amount
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="MiscellaneousItems" *ngFor="let item of ManufactureOrderForm.get('MiscellaneousItems')['controls']; let j = index;">
                        <div class="row f-ss-14px" [formGroupName]="j">
                            <div class="col-md-2">
                                <input class="form-control inputField" type="text" formControlName="MiscellaneousRawMaterialId" disabled>
                            </div>
                            <div class="col-md-2">
                                <div class="d-inline-flex align-items-center">
                                    <span class="mt-2" style="font-size: 16px;">{{ item.value.MiscellaneousConsumed }}</span>
                                    <span class="mt-2" *ngIf="item.value.MiscellaneousConsumed != 0">
                                        <img *ngIf="canShowMiscellaneousItemsStockIcon(item, j)" style="height: 33px;" src="../../assets/icons/successImg.jpg" />
                                        <img *ngIf="!canShowMiscellaneousItemsStockIcon(item, j)" style="height: 33px;" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <input class="form-control inputField" type="text" formControlName="MiscellaneousReserved" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2 inputFieldPadding">
                                <input class="form-control text-center" type="text" (change)="updateItemsData(item.ItemId)" formControlName="MiscellaneousConsumed" appDecimaNumericValidator>
                            </div>
                            <div class="col-md-2">
                                <input class="form-control inputField" type="text" formControlName="MiscellaneousUnitPrice" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2">
                                <input class="form-control inputField" type="text" formControlName="MiscellaneousLineTotal" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                        <hr class="pb-0 mb-0 pt-0 mt-0">
                    </div>
                    <hr class="pb-0 mb-0 pt-0 mt-0">
                    <div class="row d-flex">
                        <div class="col-md-10 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-2 font-weight-bold">
                            {{ currencyFormatter(miscellaneousItemCost, '') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-block topSection f-ss-14px">
                <div class="font-weight-bold">
                    Operating Costs
                </div>
                <div>
                    <div formArrayName="OperatingCostItems" *ngFor="let item of ManufactureOrderForm.get('OperatingCostItems')['controls']; let k = index;">
                        <hr class="pb-0 mb-0 pt-0 mt-0">
                        <div class="row f-ss-14px" [formGroupName]="k">
                            <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                                <ng-select [items]="operationCostTypeList" bindLabel="Name" bindValue="Id" [addTag]="onAddTag" (change)="addNewOperatingCost($event, k)" [loading]="loading" formControlName="OperatingCostId" required>
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Operating Cost Type</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                                <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="OperatingCostAmount" (change)="updateTotalPrice()">
                            </div>
                            <div class="col-md-1">
                                <div class="dropdown mr-4" style="padding-top: 13px;">
                                    <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                        <div class="productRow pb-3" (click)="removeOperatingCostItems(k)">
                                            <span class="lead1">Remove Item</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-0 mt-0">
                <div class="row addItem">
                    <div class="w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link inputFieldPadding" style="background-color:white;" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addOperatingCostItems()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" alt="Add Item">Add New Line
                            </a>
                        </div>
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-0 mt-0">
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div style="margin-bottom: -15px;">
                    <div class="font-weight-bold">
                        Finished Product Costs
                    </div>
                    <hr class="pb-0 mb-0">
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Operating Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedOperatingCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Total Operating Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedTotalOperatingCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Material Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedMaterialCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Total Material Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedTotalMaterialCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label> Miscellaneous Item Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedMiscellaneousItemCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Total Miscellaneous Item Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedTotalMiscellaneousItemCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Production Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedProductionCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 15px;">
                            <label>Total Production Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 7px;">
                            <input class="form-control text-center" style="color: black;" type="text" appDecimaNumericValidator formControlName="FormattedTotalProductionCost" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
