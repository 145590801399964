<form [formGroup]="documentForm">
    <div class="modal-header row" style="top: 20px;">
        <h4 class="modal-title col-md-3" style="font-size: 17px;" *ngIf="documentingType == 1 && actionType == 0">Add Document</h4>
        <h4 class="modal-title col-md-3" style="font-size: 17px;" *ngIf="documentingType == 2 && actionType == 0">Author Document</h4>
        <h4 class="modal-title col-md-3" style="font-size: 17px;" *ngIf="actionType == 3">Update Document</h4>
        <div class="col-md-4"></div>
        <div class="col-md-4 col-sm-12 ml-0 mt-2 d-flex mb-2 ">
            <label>Approval Flow <span style="color: red;">*</span>
            </label>
            <label class="switch ml-2">
                <input class="switch-input" type="checkbox" (change)="approvalFlowChange()" formControlName="HasApprovalFlow">
                <span class="switch-label" data-on="Yes" data-off="No"></span>
                <span class="switch-handle"></span>
            </label>
        </div>
        <button type="button" class="close col-md-1 ml-0 pl-0" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
    <hr>
    <div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
        <div class="pb-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="f-ss-14px">
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel1Name != undefined">{{ documentLabel1Name }}</span>
                                        <span *ngIf="documentLabel1Name == undefined">Employee Id </span>
                                        <span style="color: red;">*</span>
                                    </label>
                                    <ng-select [items]="allTags" [multiple]="true" bindLabel="TypeName" bindValue="Id" formControlName="selectedTags" [addTag]="CreateNewTags.bind(this)" [loading]="loading">
                                        <ng-template ng-tag-tmp let-search="searchTerm">
                                            <b>+ Add New Employee Id</b>: {{search}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel2Name != undefined">{{ documentLabel2Name }}</span>
                                        <span *ngIf="documentLabel2Name == undefined">Date </span>
                                        <span style="color: red;">*</span>
                                    </label>
                                    <input type="date" class="form-control" formControlName="ActionAt" max="{{today | date:'yyyy-MM-dd'}}" style="width: 99%;" id="dctest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel3Name != undefined">{{ documentLabel3Name }}</span>
                                        <span *ngIf="documentLabel3Name == undefined">Reference 1 </span>
                                    </label>
                                    <input type="text" class="form-control" formControlName="Reference1">
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel4Name != undefined">{{ documentLabel4Name }}</span>
                                        <span *ngIf="documentLabel4Name == undefined">Reference 2 </span>
                                    </label>
                                    <input type="text" class="form-control" formControlName="Reference2">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel5Name != undefined">{{ documentLabel5Name }}</span>
                                        <span *ngIf="documentLabel5Name == undefined">Document Type </span>
                                        <span style="color: red;">*</span>
                                    </label>
                                    <ng-select [items]="allDocumentTypes" bindLabel="Name" bindValue="Id" formControlName="DocumentTypeId"></ng-select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="TagName" style="font-weight: 600;">
                                        <span *ngIf="documentLabel6Name != undefined">{{ documentLabel6Name }}</span>
                                        <span *ngIf="documentLabel6Name == undefined">Tag </span>
                                    </label>
                                    <input type="text" class="form-control" formControlName="EmployeeId">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder" *ngIf="documentingType == 1">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <label style="font-weight: 600;" *ngIf="actionType == 0">Add Documents <span class="required-sign">*</span>
                                    </label>
                                    <label style="font-weight: 600;" *ngIf="actionType == 3">Update Documents</label>
                                    <div class="file-upload-wrapper">
                                        <input class="large dotted_border file-input" type="file" multiple (change)="handleFile($event)" />
                                        <div class="file-upload-text">
                                            <ng-container *ngIf="fileNames.length > 0; else placeholder">
                                                <div *ngFor="let fileName of fileNames">{{fileName}}</div>
                                            </ng-container>
                                            <ng-template #placeholder>
                                                <div class="placeholder-text">Drop your files here</div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <label class="mt-2" *ngIf="actionType == 3">
                                        <span style="font-weight: 600;">Previous File Name:</span> {{ parentData?.FileName }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder" *ngIf="documentingType == 2">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-12">
                                    <ckeditor (ready)="onReady($event)" [editor]="Editor" [(ngModel)]="model.data" [ngModelOptions]="{standalone: true}"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <button type="button" *ngIf="actionType == 0" style="float: right;margin-right: 14px;" class="eCount-def-btn  ml-4" (click)="save()">Save</button>
                    <button type="button" *ngIf="actionType == 3 && documentingType == 2 && tagsAttachmentVersionSetting?.IsActive" style="float: right;margin-right: 14px;" class="eCount-def-btn  ml-4" (click)="update(3, false)">Update With Version</button>
                    <button type="button" *ngIf="actionType == 3" style="float: right;margin-right: 14px;" class="eCount-def-btn  ml-4" (click)="update(3, true)">
                        <span *ngIf="documentingType == 2 && tagsAttachmentVersionSetting?.IsActive">Skip Version Update</span>
                        <span *ngIf="documentingType == 1 || (documentingType == 2 && !tagsAttachmentVersionSetting?.IsActive)">Update</span>
                    </button>
                    <button type="button" *ngIf="actionType == 3 && parentData?.ApprovalStatus == 4" style="float: right;margin-right: 14px;" class="eCount-def-btn  ml-4" (click)="update(1, true)">Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>